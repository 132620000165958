import React, { useRef, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import './ViewEditCategory.css';

import { createAnalyticsTrigger, pollAnalyticsTriggerStatus } from '../../utils/analyticsUtils';
import config from '../../config';
import { useAuth } from '../../auth';



import CloseIcon from '../../assets/icons/Close.svg';
import ArrowLineLeft from '../../assets/icons/ArrowLineLeft.svg';
import ArrowLineRight from '../../assets/icons/ArrowLineRight.svg';

import SimpleMetricsCard from '../SimpleMetricsCard/SimpleMetricsCard';

const ViewEditCategory = ({ 
    category, 
    onClose, 
    onPrevious, 
    onNext, 
    categoryType, 
    hasPrevious, 
    hasNext,
    onCategoryUpdated,
    updateCategory,
    organization,
    db,
    isNewCategory,
}) => {
    const [editableCategory, setEditableCategory] = useState({ ...category });
    const [isEditing, setIsEditing] = useState(isNewCategory || category.isNew);

    const [isSaving, setIsSaving] = useState(false);
    const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'success' });    
    const modalRef = useRef(null);
    const BACKEND_API_URL = config.BACKEND_API_URL;
    const { getIdToken } = useAuth();
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 768);
        };

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);


    useEffect(() => {
        setEditableCategory({ ...category });
        setIsEditing(isNewCategory || category.isNew);
    }, [category, isNewCategory]);
    
    

    const handleInputChange = (field, value) => {
        setEditableCategory(prev => ({ ...prev, [field]: value }));
    };


    const handleCancelEdit = () => {
        if (category.isNew) {
            onClose();
        } else {
            setIsEditing(false);
            setEditableCategory({ ...category });
        }
    };
    
    
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (modalRef.current && !modalRef.current.contains(event.target)) {
                onClose();
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [onClose]);

    const renderField = (label, field, value, type = 'text') => {
        let displayValue = value;
        if (type === 'date' && value) {
            // Parse the date string or timestamp
            const date = value.seconds ? new Date(value.seconds * 1000) : new Date(value);
            displayValue = date.toLocaleDateString('en-GB', {
                day: '2-digit',
                month: 'short',
                year: 'numeric'
            });
        } else if (type === 'currency') {
            displayValue = `€${parseFloat(value).toFixed(2)}`;
        }
        return (
            <div className="category-info">
                <label>{label}</label>
                {isEditing ? (
                    <input
                        type={type}
                        value={editableCategory[field] || ''}
                        onChange={(e) => handleInputChange(field, e.target.value)}
                    />
                ) : (
                    <p>{displayValue || 'N/A'}</p>
                )}
            </div>
        );
    };

    const handleSaveEdit = async () => {
        setIsSaving(true);
        try {
            const triggerId = await createAnalyticsTrigger(
                db, 
                organization, 
                'category', 
                1, 
                category.isNew ? 'create' : 'edit'
            );
            console.log("Analytics trigger created with ID:", triggerId);

            
            let updatedCategory;
            if (category.isNew) {
                // Create new category
                console.log("Sending request to create new category:", {
                    organizationId: organization.id,
                    categoryType: categoryType,
                    categoryData: editableCategory,
                    triggerId: triggerId,
                    changeType: 'category'
                });

                const token = await getIdToken();   
                const response = await fetch(`${BACKEND_API_URL}/add-category`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${token}`,
                    },
                    body: JSON.stringify({
                        organizationId: organization.id,
                        categoryType: categoryType,
                        categoryData: editableCategory,
                        triggerId: triggerId,
                        changeType: 'category'  // Add this explicit change type
                    }),
                });
    
                if (!response.ok) {
                    throw new Error('Failed to create category');
                }
    
                const result = await response.json();
                updatedCategory = { ...editableCategory, id: result.categoryId };
            } else {
                // Update existing category
                updatedCategory = await updateCategory(
                    organization.id,
                    editableCategory.id,
                    categoryType,
                    editableCategory,
                    triggerId,
                    'category'
                );
            }
    
            await pollAnalyticsTriggerStatus(
                triggerId,
                getIdToken,
                () => {
                    console.log("Category update/create action completed");
                },
                () => {
                    console.log("Analytics update completed");
                    onCategoryUpdated(updatedCategory);
                }
            );
    
            setSnackbar({ open: true, message: `Category ${category.isNew ? 'created' : 'updated'} successfully`, severity: 'success' });
            setIsEditing(false);
            onClose();
        } catch (error) {
            console.error(`Failed to ${category.isNew ? 'create' : 'update'} category:`, error);
            setSnackbar({ open: true, message: `Failed to ${category.isNew ? 'create' : 'update'} category`, severity: 'error' });
        } finally {
            setIsSaving(false);
        }
    };


    return (
        <div className={`view-edit-category-modal ${isEditing ? 'editing' : ''}`}>
            <div className="view-edit-category-content" ref={modalRef}>
                <div className="category-header">
                    <div className="category-header-left">
                        <div className="category-header-info">
                            <h2>{categoryType === 'supplier' ? 'Supplier' : 'Customer'} Category Details</h2>
                            {!isNewCategory && <p className="category-name">{category.name}</p>}

                        </div>
                    </div>

                    <div className="category-header-right">
                        {!isNewCategory && (
                            <>
                                <button 
                                    className={`prev-button ${!hasPrevious ? 'disabled' : ''}`}
                                    onClick={onPrevious} 
                                    disabled={!hasPrevious}
                                >
                                    <img src={ArrowLineLeft} alt="Arrow left" className="icon" />
                                    Previous
                                </button>
                                <button 
                                    className={`next-button ${!hasNext ? 'disabled' : ''}`}
                                    onClick={onNext}
                                    disabled={!hasNext}
                                >
                                    Next
                                    <img src={ArrowLineRight} alt="Arrow right" className="icon" />  
                                </button>
                            </>
                        )}
                        <button className="close-button" onClick={onClose}>
                            <img src={CloseIcon} alt="Close icon" className="icon" />
                        </button>
                    </div>
                </div>

                <div className="category-body">
                    <div className="category-details-section">
                <h3 className="section-title">Category Details</h3>
                        {renderField('Name', 'name', category.name)}
                        {renderField('Description', 'description', category.description)}
                        {!isNewCategory && (
                            <>
                                {renderField('Created At', 'createdAt', category.createdAt, 'date')}
                                {renderField('Updated At', 'updatedAt', category.updatedAt, 'date')}
                            </>
                        )}
                    </div>
                </div>

                <div className="category-metrics-section">
                    <SimpleMetricsCard 
                        title={categoryType === 'supplier' ? 'Total Spent' : 'Total Revenue'} 
                        type="monetaryValue" 
                        value={category.totalAmount} 
                        currency="€" 
                    />
                    <SimpleMetricsCard 
                        title="Total Invoices" 
                        type="amount" 
                        value={category.totalInvoices} 
                    />
                </div>
                <div className="category-footer">
                    {isEditing && (
                        <div className="edit-actions">
                            <button className="cancel-button" onClick={handleCancelEdit} disabled={isSaving}>
                                Cancel
                            </button>
                            <button className="save-button" onClick={handleSaveEdit} disabled={isSaving}>
                                {isSaving ? 'Saving...' : 'Save'}
                            </button>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

ViewEditCategory.propTypes = {
    category: PropTypes.object.isRequired,
    onClose: PropTypes.func.isRequired,
    onPrevious: PropTypes.func.isRequired,
    onNext: PropTypes.func.isRequired,
    categoryType: PropTypes.oneOf(['supplier', 'customer']).isRequired,
    hasPrevious: PropTypes.bool.isRequired,
    hasNext: PropTypes.bool.isRequired,
    onCategoryUpdated: PropTypes.func.isRequired,
    updateCategory: PropTypes.func.isRequired,
    organization: PropTypes.object.isRequired,
    db: PropTypes.object.isRequired,
    isNewCategory: PropTypes.bool,
};

export default ViewEditCategory;