import React, { useState, useRef, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import './ViewEditInvoice.css';
import { ref, getDownloadURL, getStorage } from 'firebase/storage';
import config from '../../config';  
import { createAnalyticsTrigger, pollAnalyticsTriggerStatus } from '../../utils/analyticsUtils';
import { currencyCodesSymbols, fetchBaseCurrency } from '../../utils/currencyUtils';

import { Typography } from '@mui/material';

import CopyLinkIcon from '../../assets/icons/LinkSimple.svg'; 
import DownloadIcon from '../../assets/icons/Download.svg';
import CloseIcon from '../../assets/icons/Close.svg';
import ArrowLineLeft from '../../assets/icons/ArrowLineLeft.svg';
import ArrowLineRight from '../../assets/icons/ArrowLineRight.svg';
import { useAuth } from "../../auth";

const ViewEditInvoice = ({ 
    invoice, 
    onClose, 
    onPrevious, 
    onNext, 
    invoiceType, 
    onUpdateInvoice, 
    organizationId, 
    db, 
    suppliers,
    customers,
    fetchAllData,
    allCategories,
    isEditing,
    entities,
    onEditModeChange = () => {}, // Add this default value

}) => {
    console.log('ViewEditInvoice - Component rendered');
    console.log("Suppliers:", suppliers);
    console.log("Customers:", customers);
    console.log("Entities:", entities);
    const { getIdToken } = useAuth();
    // console.log("ViewEditInvoice rendered. onEditModeChange:", onEditModeChange, "isEditing:", isEditing);

    // if the invoice is new, set the base currency to the base currency from the db

    const initializeNewInvoice = React.useCallback(() => ({
        processedData: {
            invoice_number: '',
            date_of_invoice: new Date().toISOString().split('T')[0],
            currency: '',
            currency_symbol: '',
            base_currency: '',
            base_currency_symbol: '',
            exchange_rate: 1,
            items: [],
            subtotal_subject_to_vat_tax: '0.00',
            total_vat_tax: '0.00',
            vat_tax_percentage: '0.00',
            total_amount: '0.00',
            [invoiceType === 'expense' ? 'supplier_id' : 'customer_id']: '',
            [invoiceType === 'expense' ? 'supplier_full_name' : 'customer_full_name']: ''
        },
        isNew: true
    }), [invoiceType]);

    console.log('initializeNewInvoice:', initializeNewInvoice());

    const [editedInvoice, setEditedInvoice] = useState(invoice?.isNew ? initializeNewInvoice() : invoice || {});
    const [isNewInvoice, setIsNewInvoice] = useState(false);

    const modalRef = useRef(null);
    const [isSubtotalOverridden, setIsSubtotalOverridden] = useState(false);
    const [tempSubtotal, setTempSubtotal] = useState('');
    const [isSaving, setIsSaving] = useState(false);
    const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'success' });
    const [categoryMap, setCategoryMap] = useState({});
       
    const [showConvertedCurrency, setShowConvertedCurrency] = useState(true);
    const [originalCurrency, setOriginalCurrency] = useState(editedInvoice.processedData?.currency);
    const [baseCurrency, setBaseCurrency] = useState(editedInvoice.processedData?.base_currency);
    const [originalCurrencySymbol, setOriginalCurrencySymbol] = useState(editedInvoice.processedData?.currency_symbol);
    const [baseCurrencySymbol, setBaseCurrencySymbol] = useState(editedInvoice.processedData?.base_currency_symbol);
    const { user, organization } = useAuth(); // Get the current user and organization
    const { BACKEND_API_URL } = config;
    const [isDownloading, setIsDownloading] = useState(false);
    const [isDownloadingOriginal, setIsDownloadingOriginal] = useState(false);

    console.log("Invoice:", invoice);

    const toggleCurrency = () => {
        setShowConvertedCurrency((prev) => {
            const newShowConverted = !prev;
            if (newShowConverted) {
                setOriginalCurrency(baseCurrency);
                setOriginalCurrencySymbol(baseCurrencySymbol);
            } else {
                setOriginalCurrency(invoice.processedData?.currency);
                setOriginalCurrencySymbol(invoice.processedData?.currency_symbol);
            }
            return newShowConverted;
        });
    };

    useEffect(() => {
        const initializeInvoice = async () => {
            if (invoice?.isNew) {
                try {
                    const baseCurrency = await fetchBaseCurrency(organization.id);
                    const baseCurrencySymbol = currencyCodesSymbols.find(c => c.code === baseCurrency)?.symbol || '$';
                    
                    setEditedInvoice({
                        processedData: {
                            ...initializeNewInvoice().processedData,
                            currency: baseCurrency,
                            currency_symbol: baseCurrencySymbol,
                            base_currency: baseCurrency,
                            base_currency_symbol: baseCurrencySymbol,
                        },
                        isNew: true
                    });
                    setOriginalCurrency(baseCurrency);
                    setBaseCurrency(baseCurrency);
                    setOriginalCurrencySymbol(baseCurrencySymbol);
                    setBaseCurrencySymbol(baseCurrencySymbol);
                    onEditModeChange(true);
                } catch (error) {
                    console.error("Failed to fetch base currency:", error);
                    // Handle the error appropriately
                }
            } else {
                setEditedInvoice(invoice || {});
                setOriginalCurrency(invoice?.processedData?.currency);
                setBaseCurrency(invoice?.processedData?.base_currency);
                setOriginalCurrencySymbol(invoice?.processedData?.currency_symbol);
                setBaseCurrencySymbol(invoice?.processedData?.base_currency_symbol);
            }
        };
    
        initializeInvoice();
    }, [invoice, onEditModeChange, initializeNewInvoice, organization.id]);


    // console log the currencties and symbols
    // console.log("originalCurrency:", originalCurrency);
    // console.log("originalCurrencySymbol:", originalCurrencySymbol);
    // console.log("baseCurrency:", baseCurrency);
    // console.log("baseCurrencySymbol:", baseCurrencySymbol);


    const calculateVAT = useCallback((subtotal, vatPercentage, totalVAT) => {
        if (subtotal && vatPercentage) {
            return (subtotal * (vatPercentage / 100)).toFixed(2);
        } else if (subtotal && totalVAT) {
            return ((totalVAT / subtotal) * 100).toFixed(2);
        }
        return '0.00';
    }, []);

    const handleCurrencyChange = async (e) => {
        const newCurrency = e.target.value;
        setEditedInvoice(prev => ({
            ...prev,
            processedData: {
                ...prev.processedData,
                currency: newCurrency
            }
        }));
        setOriginalCurrency(newCurrency);

        // Use the current date from editedInvoice for new invoices, or the original date for existing ones
        const currentDate = editedInvoice.isNew 
            ? editedInvoice.processedData.date_of_invoice 
            : invoice.processedData.date_of_invoice;

        // This would be similar to the date change logic in handleExchangeRateChange
        const exchangeRate = await fetchExchangeRate(
            currentDate, 
            newCurrency, 
            editedInvoice.processedData?.base_currency
        );

        setEditedInvoice(prev => ({
            ...prev,
            processedData: {
                ...prev.processedData,
                exchange_rate: exchangeRate
            }
        }));

        // update the currency symbol
        const currencySymbol = currencyCodesSymbols.find(c => c.code === newCurrency).symbol;
        setEditedInvoice(prev => ({
            ...prev,
            processedData: {
                ...prev.processedData,
                currency_symbol: currencySymbol
            }
        }));
    };


    useEffect(() => {
        if (showConvertedCurrency) {
            setOriginalCurrency(invoice.processedData?.base_currency);
            setOriginalCurrencySymbol(invoice.processedData?.base_currency_symbol);
        } else {
            setOriginalCurrency(invoice.processedData?.currency);
            setOriginalCurrencySymbol(invoice.processedData?.currency_symbol);
        }
    }, [showConvertedCurrency, invoice.processedData?.base_currency, invoice.processedData?.currency, invoice.processedData?.base_currency_symbol, invoice.processedData?.currency_symbol]);

    useEffect(() => {
        console.log("isEditing state updated:", isEditing);
    }, [isEditing]);

    useEffect(() => {
        if (allCategories && Array.isArray(allCategories)) {
            const map = {};
            allCategories.forEach(category => {
                map[category.id] = category.name;
            });
            setCategoryMap(map);
        }
    }, [allCategories]);


    useEffect(() => {
        console.log("isEditing changed in ViewEditInvoice:", isEditing);
        console.log("Calling onEditModeChange with:", isEditing);
        onEditModeChange(isEditing);
    }, [isEditing, onEditModeChange]);


    useEffect(() => {
        // Notify parent component when edit mode changes
        if (isEditing) {
            console.log("Notifying parent component of edit mode change:", isEditing);
            onEditModeChange(isEditing);
        }
    }, [isEditing, onEditModeChange]);

    

    useEffect(() => {
        console.log('ViewEditInvoice - invoice prop:', invoice);
        if (invoice?.isNew) {
            console.log('ViewEditInvoice - invoice is new');
            setIsNewInvoice(true);
        }
        
    }, [invoice]);
    
    const handleEditClick = (event) => {
        event.stopPropagation();
        console.log("Edit button clicked");
        setTempSubtotal('');
        
        // Reset to original currency and amounts
        setEditedInvoice(prev => ({
            ...prev,
            processedData: {
                ...prev.processedData,
                currency: invoice.processedData?.currency,
                items: prev.processedData?.items?.map(item => ({
                    ...item,
                    unit_price: item?.unit_price,
                    total_price: item?.total_price
                })),
                subtotal_subject_to_vat_tax: prev.processedData?.subtotal_subject_to_vat_tax,
                total_vat_tax: prev.processedData?.total_vat_tax,
                total_amount: prev.processedData?.total_amount
            }
        }));
    
        setOriginalCurrency(invoice.processedData?.currency);
        setOriginalCurrencySymbol(invoice.processedData?.currency_symbol);
    
        if (onEditModeChange) {
            console.log("Calling onEditModeChange(true)");
            onEditModeChange(true);
        } else {
            console.log("onEditModeChange is not defined");
        }
    };
    
    const handleCancelEdit = () => {
        if (editedInvoice.isNew) {
            // If it's a new invoice, close the modal completely
            onClose();
        } else {
            // For existing invoices, revert to view mode
            onEditModeChange(false);
            setEditedInvoice({ ...invoice });
            setTempSubtotal('');
            setIsSubtotalOverridden(false);
        }
    };


    const handleSaveEdit = async () => {
        setIsSaving(true);
        let triggerId;

        try {
            if (!organization) {
                throw new Error("Organization is not defined");
            }
            
            // Ensure subtotal and total values are correctly calculated before saving
            let updatedInvoice = { ...editedInvoice };
            const exchangeRate = parseFloat(updatedInvoice.processedData.exchange_rate);

            // Calculate converted figures
            updatedInvoice.processedData.subtotal_subject_to_vat_tax_converted = (parseFloat(updatedInvoice.processedData.subtotal_subject_to_vat_tax) * exchangeRate).toFixed(2);
            updatedInvoice.processedData.total_vat_tax_converted = (parseFloat(updatedInvoice.processedData.total_vat_tax) * exchangeRate).toFixed(2);
            updatedInvoice.processedData.total_amount_converted = (parseFloat(updatedInvoice.processedData.total_amount) * exchangeRate).toFixed(2);

            updatedInvoice.processedData.items = updatedInvoice.processedData.items.map(item => ({
                ...item,
                unit_price_converted: (parseFloat(item.unit_price) * exchangeRate).toFixed(2),
                total_price_converted: (parseFloat(item.total_price) * exchangeRate).toFixed(2)
            }));

            
            if (tempSubtotal !== '') {
                const value = parseFloat(tempSubtotal);
                const newTotalAmount = value + parseFloat(updatedInvoice?.total_vat_tax_converted || 0);
                updatedInvoice = {
                    ...updatedInvoice,
                    processedData: {
                        ...updatedInvoice.processedData,
                        subtotal_subject_to_vat_tax_converted: value.toFixed(2),
                        total_amount_converted: newTotalAmount.toFixed(2)
                    }
                };
                // Directly update state before saving
                setEditedInvoice(updatedInvoice);
            }
            // Ensure all entity fields are included
            const entityPrefix = invoiceType === 'expense' ? 'supplier' : 'customer';
            const entityFields = [
                'full_name', 'vat_number', 'address_line_1', 'address_line_2',
                'city', 'postal_code', 'country', 'email', 'phone'
            ];
            entityFields.forEach(field => {
                const key = `${entityPrefix}_${field}`;
                if (updatedInvoice.processedData[key] === undefined) {
                    updatedInvoice.processedData[key] = null;
                }
            });
        
            console.log("Current user:", user);
            console.log("Organization ID:", organizationId);
            console.log("Invoice Type:", invoiceType);
            console.log("Invoice ID:", invoice.id);
            console.log("Saving edited invoice:", JSON.stringify(updatedInvoice, null, 2));
    
            if (!organizationId) {
                throw new Error("Organization ID is missing");
            }

            // Create an analytics trigger
            triggerId = await createAnalyticsTrigger(
                db, 
                organization, 
                'invoice', 
                1, 
                editedInvoice.isNew ? 'manual-create' : 'edit'
            );

            setSnackbar({ open: true, message: 'Saving invoice...', severity: 'info' });


            if (editedInvoice.isNew) {
    
                // Prepare the minimal data needed for a new invoice
                const newInvoiceData = {
                    date_of_invoice: updatedInvoice.processedData.date_of_invoice,
                    invoice_number: updatedInvoice.processedData.invoice_number,
                    currency: updatedInvoice.processedData?.currency || 'EUR',
                    items: updatedInvoice.processedData.items,
                    total_vat_tax: updatedInvoice.processedData.total_vat_tax,
                    vat_tax_percentage: updatedInvoice.processedData.vat_tax_percentage || '0.00',
                    total_amount: updatedInvoice.processedData.total_amount,
                    subtotal_subject_to_vat_tax: updatedInvoice.processedData.subtotal_subject_to_vat_tax || '0.00',
                    subtotal_not_subject_to_vat_tax: updatedInvoice.processedData.subtotal_not_subject_to_vat_tax || '0.00'
                };

                // Add the supplier or customer ID based on invoice type
                if (invoiceType === 'expense') {
                    newInvoiceData.entityId = updatedInvoice.processedData.supplier_id;
                } else {
                    newInvoiceData.entityId = updatedInvoice.processedData.customer_id;
                }
                
                const token = await getIdToken();

                // Call the backend to create the new invoice
                const response = await fetch(`${BACKEND_API_URL}/submit-manual-invoice`, {
                  method: 'POST',
                  headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                  },
                  body: JSON.stringify({
                    organizationId: organizationId,
                    invoiceType: invoiceType,
                    invoiceData: newInvoiceData,
                    triggerId: triggerId
                  }),
                });
        
                if (!response.ok) {
                  throw new Error('Failed to create invoice');
                }
        
                const data = await response.json();
                console.log("Create response:", data);
              } else {

                 // Create an analytics trigger for the edit action
                // triggerId = await createAnalyticsTrigger(db, organization, invoiceType, 1, 'edit');
        
                const token = await getIdToken();
                // Call the backend to edit the invoice
                const response = await fetch(`${BACKEND_API_URL}/edit-invoice`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${token}`,
                    },
                    body: JSON.stringify({
                        organizationId: organizationId,
                        invoiceType: invoiceType,
                        invoiceId: invoice.id,
                        updatedData: updatedInvoice.processedData,
                        triggerId: triggerId
                    }),
                });

                if (!response.ok) {
                    throw new Error('Failed to edit invoice');
                }

                const data = await response.json();
                console.log("Edit response:", data);
            }

            // Start polling for analytics update completion
            try {
                await pollAnalyticsTriggerStatus(
                    BACKEND_API_URL,
                    triggerId,
                    () => {
                        console.log("Analytics trigger action completed");
                    },
                    () => {
                        console.log("Analytics trigger completed");
                    }
                );
                setSnackbar({ open: true, message: 'Analytics updated successfully', severity: 'success' });
            } catch (error) {
                console.error("Error while polling analytics trigger status:", error);
                // Consider how you want to handle this error. You might want to show a warning to the user.
                setSnackbar({ open: true, message: 'Analytics update failed, but invoice was saved', severity: 'warning' });
            }
            setTempSubtotal('');
            setIsSubtotalOverridden(false);
            // setIsEditing(false);
            onEditModeChange(false);
            // setSnackbar({ open: true, message: 'Invoice updated successfully', severity: 'success' });
    
            if (onUpdateInvoice) {
                onUpdateInvoice(updatedInvoice);
            }

            // Fetch updated invoices and analytics data
            if (fetchAllData) {
                await fetchAllData();
            }

        } catch (error) {
            console.error("Error updating invoice:", error);
            console.log("Error details:", error.code, error.message);
            setSnackbar({ open: true, message: 'Failed to update invoice', severity: 'error' });
        } finally {
            setIsSaving(false);
        }
    };

    const getSelectedEntity = () => {
        console.log('Entering getSelectedEntity');
        console.log('Invoice Type:', invoiceType);
        
        const entityList = invoiceType === 'expense' ? suppliers : customers;
        const entityIdField = invoiceType === 'expense' ? 'supplier_id' : 'customer_id';
        
        console.log('Entity List:', entityList);
        console.log('Entity ID Field:', entityIdField);
        
        if (!entityList || !Array.isArray(entityList)) {
            console.error(`Entity list is undefined or not an array. Invoice type: ${invoiceType}`);
            return {};
        }
        
        if (!invoice || !invoice.processedData) {
            console.error('Invoice or processedData is undefined', invoice);
            return {};
        }
        
        const entityId = invoice.processedData[entityIdField];
        console.log('Entity ID from invoice:', entityId);
        
        if (!entityId) {
            console.error(`Entity ID is missing. Field: ${entityIdField}`);
            return {};
        }
        
        const entity = entityList.find(e => e.id === entityId);
        console.log('Found entity:', entity);
        
        if (!entity) {
            console.warn(`Entity not found for ID: ${entityId}`);
            return {};
        }
        
        return entity;
    };
  

    const renderEntityDetails = () => {
        console.log('Rendering entity details');
        console.log('Selected entity:', getSelectedEntity());

        const selectedEntity = getSelectedEntity();
        const entityType = invoiceType === 'expense' ? 'Supplier' : 'Customer';
    
        return (
            <div className="supplier-details-section">
                <Typography variant="body1" className="entity-type-title">{entityType} Details</Typography>
                {renderField(`${entityType} Name`, 'name', selectedEntity?.name, 'text', null, 'entity-name-section', false)}
                {renderField('Tax Number', 'taxNumber', selectedEntity?.taxNumber, 'text', null, 'entity-details-section', false)}
                {renderField('Address Line 1', 'address_line_1', selectedEntity?.address_line_1, 'text', null, 'entity-details-section', false)}
                {renderField('Address Line 2', 'address_line_2', selectedEntity?.address_line_2, 'text', null, 'entity-details-section', false)}
                {renderField('City', 'city', selectedEntity?.city, 'text', null, 'entity-details-section', false)}
                {renderField('Postal Code', 'postalCode', selectedEntity?.postalCode, 'text', null, 'entity-details-section', false)}
                {renderField('Country', 'country', selectedEntity?.country, 'text', null, 'entity-details-section', false)}
                {renderField('Email', 'email', selectedEntity?.email, 'email', null, 'entity-details-section', false)}
                {renderField('Phone', 'phone', selectedEntity?.phone, 'tel', null, 'entity-details-section', false)}
                {renderField('Category', 'categoryId', categoryMap[selectedEntity?.categoryId] || 'Unknown', 'text', null, 'entity-category-section', false)}
            </div>
        );
    };

    
    const renderEntityDropdown = () => {
        const entityList = invoiceType === 'expense' ? suppliers : customers;
        const entityType = invoiceType === 'expense' ? 'Supplier' : 'Customer';
        const entityIdField = invoiceType === 'expense' ? 'supplier_id' : 'customer_id';
        const entityNameField = invoiceType === 'expense' ? 'supplier_full_name' : 'customer_full_name';

        return (
            <div className="invoice-info">
                <label>{entityType}:</label>
                {isEditing ? (
                    <select
                        name={entityIdField}
                        value={editedInvoice.processedData[entityIdField] || ''}
                        onChange={handleChange}
                    >
                        <option value="">Select a {entityType.toLowerCase()}</option>
                        {entityList.map((entity) => (
                            <option key={entity.id} value={entity.id}>
                                {entity.name}
                            </option>
                        ))}
                    </select>
                ) : (
                    <p>{invoice.processedData[entityNameField]}</p>
                )}
            </div>
        );
    };
    


    const handleChange = (e) => {
        const { name, value } = e.target;
        setEditedInvoice(prev => {
            const updatedProcessedData = {
                ...prev.processedData,
                [name]: value
            };

            const subtotalSubjectToVAT = parseFloat(updatedProcessedData.subtotal_subject_to_vat_tax) || 0;
            const totalVAT = parseFloat(updatedProcessedData.total_vat_tax) || 0;
            let vatPercentage = parseFloat(updatedProcessedData.vat_tax_percentage) || 0;

            if (name === 'subtotal_subject_to_vat_tax' || name === 'total_vat_tax' || name === 'vat_tax_percentage') {
                if (name === 'subtotal_subject_to_vat_tax' || name === 'total_vat_tax') {
                    vatPercentage = parseFloat(calculateVAT(subtotalSubjectToVAT, null, totalVAT));
                    updatedProcessedData.vat_tax_percentage = vatPercentage.toFixed(2);
                } else if (name === 'vat_tax_percentage') {
                    updatedProcessedData.total_vat_tax = calculateVAT(subtotalSubjectToVAT, vatPercentage, null);
                }
            }

            // Recalculate total amount
            const subtotalNotSubjectToVAT = parseFloat(updatedProcessedData.subtotal_not_subject_to_vat_tax) || 0;
            updatedProcessedData.total_amount = (subtotalSubjectToVAT + subtotalNotSubjectToVAT + parseFloat(updatedProcessedData.total_vat_tax)).toFixed(2);

            return {
                ...prev,
                processedData: updatedProcessedData
            };
        });
    };
        

    const handleAddItem = () => {
        setEditedInvoice(prev => {
            const newItems = [
                ...prev.processedData?.items,
                { description: '', quantity: 1, unit_price: 0, total_price: 0 }
            ];
            
            // Recalculate subtotal
            const newSubtotal = newItems?.reduce((sum, item) => sum + Number(item?.total_price), 0);
            
            // Recalculate total amount
            const newTotalAmount = newSubtotal + Number(prev.processedData?.total_vat_tax);
    
            return {
                ...prev,
                processedData: {
                    ...prev.processedData,
                    items: newItems,
                    subtotal_subject_to_vat: newSubtotal.toFixed(2),
                    total_amount: newTotalAmount.toFixed(2)
                }
            };
        });
        setIsSubtotalOverridden(false);
    };

    const handleRemoveItem = (index) => {
        setEditedInvoice(prev => {
            const newItems = prev.processedData?.items.filter((_, i) => i !== index);
            
            // Recalculate subtotal
            const newSubtotal = newItems?.reduce((sum, item) => sum + Number(item?.total_price), 0);
            
            // Recalculate total amount
            const newTotalAmount = newSubtotal + Number(prev.processedData?.total_vat_tax);
    
            return {
                ...prev,
                processedData: {
                    ...prev.processedData,
                    items: newItems,
                    subtotal_subject_to_vat: newSubtotal.toFixed(2),
                    total_amount: newTotalAmount.toFixed(2)
                }
            };
        });
        setIsSubtotalOverridden(false);
    };
    
    const handleItemChange = (index, field, value) => {
        setEditedInvoice(prev => {
            const newItems = [...prev.processedData.items];
            newItems[index] = { ...newItems[index], [field]: value };
            
            if (field === 'quantity' || field === 'unit_price') {
                newItems[index].quantity = Number(newItems[index].quantity) || 0;
                newItems[index].unit_price = Number(newItems[index].unit_price) || 0;
                newItems[index].total_price = (newItems[index].quantity * newItems[index].unit_price).toFixed(2);
            }
            
            const newSubtotal = newItems?.reduce((sum, item) => sum + Number(item?.total_price), 0);
            const newTotalAmount = newSubtotal + Number(prev.processedData?.total_vat_tax || 0);
            
            return {
                ...prev,
                processedData: {
                    ...prev.processedData,
                    items: newItems,
                    subtotal_subject_to_vat_tax: newSubtotal.toFixed(2),
                    total_amount: newTotalAmount.toFixed(2)
                }
            };
        });
    };


    const calculateSubtotal = () => {
        return editedInvoice.processedData?.items?.reduce((sum, item) => sum + Number(item.total_price), 0).toFixed(2);
    };

    
    const handleSubtotalChange = (e) => {
        const inputValue = e.target.value;
        setTempSubtotal(inputValue);
    
        if (!isNaN(inputValue) && inputValue !== '') {
            const value = parseFloat(inputValue);
            setEditedInvoice(prev => {
                const newTotalAmount = value + parseFloat(prev.processedData?.total_vat_tax || 0);
                return {
                    ...prev,
                    processedData: {
                        ...prev.processedData,
                        subtotal_subject_to_vat_tax: value.toFixed(2),
                        subtotal_subject_to_vat_tax_converted: (value * prev.processedData.exchange_rate).toFixed(2),
                        total_amount: newTotalAmount.toFixed(2),
                        total_amount_converted: (newTotalAmount * prev.processedData.exchange_rate).toFixed(2)
                    }
                };
            });
            setIsSubtotalOverridden(true);
        }
    };
    
    

    const renderField = (label, fieldName, value, type = 'text', handleCustomChange = null, className = '', showLabel = true) => {
        // If there's no value and we're not showing the label, return null (render nothing)
        if ((value === null || value === '') && !showLabel) {
            return null;
        }
    
        const displayValue = value === null || value === '' ? 'N/A' : value;
        const formattedValue = fieldName === 'lastInvoiceDate' && displayValue !== 'N/A' 
            ? new Date(displayValue).toLocaleDateString()
            : displayValue;
    
        return (
            <div className={`invoice-info ${className}`}>
                {showLabel && <label>{label}:</label>}
                {isEditing ? (
                    <input
                        type={type}
                        name={fieldName}
                        value={editedInvoice.processedData[fieldName] || ''}
                        onChange={handleCustomChange || handleChange}
                        step={type === 'number' ? '0.01' : undefined}
                        className={className}
                        placeholder={label}
                    />
                ) : (
                    <p className={className}>{formattedValue}</p>
                )}
            </div>
        );
    };
    


    const fetchExchangeRate = async (date, fromCurrency, toCurrency) => {
        try {
            const token = await getIdToken();
            const response = await fetch(`${BACKEND_API_URL}/get-exchange-rate`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                },
                body: JSON.stringify({
                    date,
                    from: fromCurrency,
                    to: toCurrency
                }),
            });
            const data = await response.json();
            const exchangeRate = data.exchange_rate;
            const correctExchangeRate = 1 / exchangeRate;

            return correctExchangeRate;
        } catch (error) {
            console.error('Failed to fetch exchange rate:', error);
            return null;
        }
    };

    


    const handleExchangeRateChange = async (e) => {
        const { name, value } = e.target;
        setEditedInvoice(prev => ({
            ...prev,
            processedData: {
                ...prev.processedData,
                [name]: value
            }
        }));

        if (name === 'date_of_invoice' || name === 'currency') {
            const date = name === 'date_of_invoice' ? value : editedInvoice.processedData?.date_of_invoice;
            const fromCurrency = name === 'currency' ? value : editedInvoice.processedData?.currency;
            const toCurrency = editedInvoice.processedData?.base_currency;

            const newExchangeRate = await fetchExchangeRate(date, fromCurrency, toCurrency);
            if (newExchangeRate) {
                setEditedInvoice(prev => ({
                    ...prev,
                    processedData: {
                        ...prev.processedData,
                        exchange_rate: newExchangeRate
                    }
                }));
            }
        }
    };

    const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const renderMobileHeader = () => (
    <div className="invoice-header">
      <div className="invoice-header-left">
        <h2>
          {editedInvoice.isNew ? 'New Invoice' : (
            <>
              Invoice <span className="invoice-number">#{editedInvoice.processedData?.invoice_number}</span>
            </>
          )}
        </h2>
        <p className="supplier-name">{invoice?.processedData?.supplier_full_name}</p>
      </div>
      <div className="invoice-header-right">
        {!isEditing && !editedInvoice.isNew && (
          <div className="mobile-nav-buttons">
            <button className="prev-button" onClick={onPrevious}>
              <img src={ArrowLineLeft} alt="Previous" className="icon" />
            </button>
            <button className="next-button" onClick={onNext}>
              <img src={ArrowLineRight} alt="Next" className="icon" />
            </button>
          </div>
        )}
        <button className="close-button" onClick={onClose}>
          <img src={CloseIcon} alt="Close" className="icon" />
        </button>
      </div>
    </div>
  );


    const renderItemsTable = () => {
        const calculatedSubtotal = calculateSubtotal();
        const displayedSubtotal = isSubtotalOverridden
            ? editedInvoice.processedData.subtotal_subject_to_vat_tax_converted
            : calculatedSubtotal;
            const getCurrencyValue = (original, converted) => {
                if (original === undefined || original === null || isNaN(original)) {
                    return '';  // Return empty string for undefined, null, or NaN values
                }
                
                const valueToUse = showConvertedCurrency && converted !== undefined && converted !== null && !isNaN(converted)
                    ? converted
                    : original;
                
                const currencySymbol = showConvertedCurrency ? baseCurrencySymbol : originalCurrencySymbol;
                
                // Format the number
                const formattedNumber = Number(valueToUse).toFixed(2);
                
                // If currency symbol is available, prepend it to the number
                if (currencySymbol && typeof currencySymbol === 'string') {
                    return `${currencySymbol}${formattedNumber}`;
                } else {
                    // If currency symbol is not available, just return the formatted number
                    return formattedNumber;
                }
            };
        
            const formatPercentage = (value) => {
                if (value === undefined || value === null || isNaN(value)) {
                    return '';  // Return empty string for undefined, null, or NaN values
                }
                return `${value}%`;
            };
    
        return (
            <div className="items-table-container">            
                <table className="items-table">
                    <thead>
                        <tr>
                            <th className="description-column">Description</th>
                            <th className="price-column">Price</th>
                            <th className="quantity-column">Quantity</th>
                            <th className="total-column">Total</th>
                            {isEditing && <th className="action-column">Action</th>}
                        </tr>
                    </thead>
                    <tbody>
                        {editedInvoice.processedData?.items?.map((item, index) => (
                            <tr key={index}>
                                <td className="description-column">
                                    {isEditing ? (
                                        <input
                                            type="text"
                                            value={item.description}
                                            onChange={(e) => handleItemChange(index, 'description', e.target.value)}
                                        />
                                    ) : (
                                        <>
                                        <div>{item.description}</div>
                                        </>
                                    )}
                                </td>
                                
                                <td className="price-column">
                                    {isEditing ? (
                                        <input
                                            type="number"
                                            value={item.unit_price || ''}
                                            onChange={(e) => handleItemChange(index, 'unit_price', parseFloat(e.target.value))}
                                            step="0.01"
                                        />
                                    ) : (
                                        getCurrencyValue(item.unit_price, item.unit_price_converted)
                                    )}
                                </td>
                                <td className="quantity-column">
                                    {isEditing ? (
                                        <input
                                            type="number"
                                            value={item.quantity}
                                            onChange={(e) => handleItemChange(index, 'quantity', parseInt(e.target.value))}
                                        />
                                    ) : (
                                        item.quantity

                                    )}
                                </td>
                                <td className="total-column">
                                    {isEditing ? 
                                    (item.total_price ? `${parseFloat(item.total_price).toFixed(2)}` : '') : 
                                    getCurrencyValue(item.total_price, item.total_price_converted)}
                                </td>
                                {isEditing && (
                                    <td className="action-column">
                                        <button 
                                            onClick={() => handleRemoveItem(index)} 
                                            className="remove-item-button" 
                                            aria-label="Remove item"
                                        >
                                            <i className="fas fa-trash-alt remove-icon"></i>
                                            <span>Remove</span>
                                        </button>
                                    </td>
                                )}
                            </tr>
                        ))}
                    </tbody>
                    <tfoot>
                        <tr>
                            <td colSpan="3" className="total-label">Subtotal subject to VAT</td>
                            <td className="total-column">
                                {isEditing ? (
                                    <input
                                        type="number"
                                        name="subtotal_subject_to_vat_tax"
                                        value={editedInvoice.processedData?.subtotal_subject_to_vat_tax || ''}
                                        onChange={handleSubtotalChange}
                                        step="0.01"
                                    />
                                ) : (
                                    getCurrencyValue(
                                        editedInvoice.processedData?.subtotal_subject_to_vat_tax,
                                        editedInvoice.processedData?.subtotal_subject_to_vat_tax_converted
                                    )
                                )}
                            </td>
                            {isEditing && <td></td>}
                        </tr>
                        <tr>
                            <td colSpan="3" className="total-label">Subtotal not subject to VAT</td>
                            <td className="total-column">
                                {isEditing ? (
                                    <input
                                        type="number"
                                        name="total_vat_tax"
                                        value={editedInvoice.processedData?.subtotal_not_subject_to_vat_tax || ''}
                                        onChange={handleChange}
                                        step="0.01"
                                    />
                                ) : (
                                    getCurrencyValue(
                                        editedInvoice.processedData?.subtotal_not_subject_to_vat_tax, 
                                        editedInvoice.processedData?.subtotal_not_subject_to_vat_tax_converted
                                    )
                                )}
                            </td>
                            {isEditing && <td></td>}
                        </tr>
                        <tr>
                            <td colSpan="3" className="total-label">Total VAT</td>
                            <td className="total-column">
                                {isEditing ? (
                                    <input
                                        type="number"
                                        name="total_vat_tax"
                                        value={editedInvoice.processedData?.total_vat_tax || ''}
                                        onChange={handleChange}
                                        step="0.01"
                                    />
                                ) : (
                                    getCurrencyValue(
                                        editedInvoice.processedData?.total_vat_tax, 
                                        editedInvoice.processedData?.total_vat_tax_converted
                                    )
                                )}
                            </td>
                            {isEditing && <td></td>}
                        </tr>
                        <tr>
                            <td colSpan="3" className="total-label">Total VAT Percentage</td>
                            <td className="total-column">
                                {isEditing ? (
                                    <input
                                        type="number"
                                        name="total_vat_tax"
                                        value={editedInvoice.processedData?.vat_tax_percentage || ''}
                                        onChange={handleChange}
                                        step="0.01"
                                    />
                                ) : (
                                    formatPercentage(editedInvoice.processedData?.vat_tax_percentage)
                                )}
                            </td>
                            {isEditing && <td></td>}
                        </tr>
                        <tr>
                            <td colSpan="3" className="total-label grand-total">Total</td>
                            <td className="total-column grand-total">
                                {isEditing ? (
                                    `${editedInvoice.processedData?.total_amount}`
                                ) : (
                                    getCurrencyValue(
                                        editedInvoice.processedData?.total_amount,
                                        editedInvoice.processedData?.total_amount_converted
                                    )
                                )}  
                            </td>
                            {isEditing && <td></td>}
                        </tr>
                    </tfoot>
                </table>
            </div>
        );
    };


    useEffect(() => {
        const handleClickOutside = (event) => {
            if (modalRef.current && !modalRef.current.contains(event.target)) {
                console.log("Click was outside the modal");
                if (typeof onClose === 'function') {
                    console.log("Calling onClose function");
                    onClose();
                } else {
                    console.error('onClose is not a function', onClose);
                }
            } else {
                console.log("Click was inside the modal");
            }
        };
    
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [onClose]);

    const handleDownloadOriginal = async () => {
        if (invoice.gcsUri && invoice.fileName) {
            setIsDownloadingOriginal(true);
            try {
                const token = await getIdToken();
                const response = await fetch(`${BACKEND_API_URL}/download-original-invoice`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${token}`,
                    },
                    body: JSON.stringify({
                        gcsUri: invoice.gcsUri,
                        fileName: invoice.fileName
                    }),
                });
    
                if (!response.ok) {
                    throw new Error('Failed to download file');
                }
    
                const blob = await response.blob();
                const url = window.URL.createObjectURL(blob);
                const a = document.createElement('a');
                a.style.display = 'none';
                a.href = url;
                a.download = invoice.fileName;
                document.body.appendChild(a);
                a.click();
                window.URL.revokeObjectURL(url);
                document.body.removeChild(a);
    
                showSnackbar('Original file downloaded successfully', 'success');
            } catch (error) {
                console.error('Error downloading original file:', error);
                showSnackbar('Failed to download original file', 'error');
            } finally {
                setIsDownloadingOriginal(false);
            }
        } else {
            console.error('No GCS URI or file name available for download');
            showSnackbar('No original file available for download', 'error');
        }
    };



    const handleDownloadPdf = async () => {
        setIsDownloading(true);
        try {
            const token = await getIdToken();
            const response = await fetch(`${BACKEND_API_URL}/generate-invoice-pdf`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                },
                body: JSON.stringify({
                    organizationId,
                    invoiceId: invoice.id,
                    invoiceType,
                }),
            });

            if (!response.ok) {
                throw new Error('Failed to generate PDF');
            }

            const blob = await response.blob();
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.style.display = 'none';
            a.href = url;
            a.download = `invoice_${invoice.processedData.invoice_number}.pdf`;
            document.body.appendChild(a);
            a.click();
            window.URL.revokeObjectURL(url);
        } catch (error) {
            console.error('Error downloading PDF:', error);
            showSnackbar('Failed to download PDF', 'error');
        } finally {
            setIsDownloading(false);
        }
    };


    useEffect(() => {
        const handleClickOutside = (event) => {
            console.log("Click outside detected");
            console.log("modalRef.current:", modalRef.current);
            console.log("event.target:", event.target);
            console.log("onClose type:", typeof onClose);
            
            if (modalRef.current && !modalRef.current.contains(event.target)) {
                console.log("Click was outside the modal");
                if (typeof onClose === 'function') {
                    console.log("Calling onClose function");
                    onClose();
                } else {
                    console.error('onClose is not a function', onClose);
                }
            } else {
                console.log("Click was inside the modal or modal ref is null");
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [onClose]);

    const handleCopyLink = async () => {
        try {
            const currentUrl = window.location.href;    
            await navigator.clipboard.writeText(currentUrl);
            showSnackbar('Link copied to clipboard', 'success');
        } catch (error) {
            console.error('Failed to copy link:', error);
            showSnackbar('Failed to copy link', 'error');
        }
    };

    const Snackbar = ({ open, message, severity }) => {
        useEffect(() => {
            let timer;
            if (open) {
                console.log("Snackbar opened with message:", message);
                timer = setTimeout(() => {
                    console.log("Closing snackbar after timeout");
                    setSnackbar({ ...snackbar, open: false });
                }, 3000);
            }

            return () => clearTimeout(timer);
        }, [open, message]);

        if (!open) return null;

        return (
            <div className={`snackbar ${severity}`}>
                {message}
                <button onClick={() => setSnackbar({ ...snackbar, open: false })}>×</button>
            </div>
        );
    };


    const showSnackbar = (message, severity) => {
        console.log("Showing snackbar:", message, severity);
        setSnackbar({ open: true, message, severity });
    };
    





    return (
        <div className={`view-edit-invoice-modal ${isNewInvoice ? 'new-invoice' : ''} ${isEditing ? 'editing' : ''}`}>
            <div className="view-edit-invoice-content" ref={modalRef}>
                <div className="invoice-header">
                    <div className="invoice-header-left">
                        <div className="invoice-header-info">
                            <h2>
                                {editedInvoice.isNew ? 'New Invoice' : (
                                    <>
                                        Invoice <span className="invoice-number">#{editedInvoice.processedData?.invoice_number}</span>
                                    </>
                                )}
                            </h2>
                            <p className="supplier-name">
                                {isEditing
                                    ? (getSelectedEntity().name || 'No name')
                                    : (getSelectedEntity().name || 'No name')
                                }
                            </p>
                        </div>
                    </div>
                    
                    <div className="invoice-header-right">
                        {!isEditing && !editedInvoice.isNew && (
                            <>
                                <button className="prev-button" onClick={onPrevious}>
                                    <img src={ArrowLineLeft} alt="Arrow left" className="icon" />
                                    Previous
                                </button>
                                <button className="next-button" onClick={onNext}>
                                    Next
                                    <img src={ArrowLineRight} alt="Arrow right" className="icon" />  
                                </button>
                            </>
                        )}
                        <button className="close-button" onClick={onClose}>
                            <img src={CloseIcon} alt="Close icon" className="icon" />
                        </button>
                    </div>            
                </div>

                <div className="invoice-body">
                    {/* Supplier and Invoice Details Section */}
                    <div className={`invoice-details-section ${isEditing ? 'editing' : ''}`}>
                            <div className="supplier-details">
                                {isEditing ? renderEntityDropdown() : renderEntityDetails()}
                                {isEditing && (
                                    <div className="edit-currency-exchange">
                                        <div className="edit-currency-exchang-editing">
                                            <label>Currency:</label>
                                            <select
                                                name="currency"
                                                value={editedInvoice.processedData?.currency || ''}
                                                onChange={handleCurrencyChange}
                                            >
                                                {currencyCodesSymbols.map(currency => (
                                                    <option key={currency.code} value={currency.code}>
                                                        {currency.code} ({currency.symbol})
                                                    </option>
                                                ))}
                                            </select>
                                        </div>
                                        <div className="edit-currency-exchange">
                                            {renderField('Exchange Rate', 'exchange_rate', editedInvoice.processedData?.exchange_rate, 'number', handleExchangeRateChange)}
                                        </div>
                                    </div>
                                )}
                            </div>

                            <div className="spacer">
                        </div>
                        <div className="issue-date-invoice-number-wrapper">
                            <div className="issue-date">
                                <div className="invoice-info invoice-info-bottom">
                                {renderField('Issue on', 'date_of_invoice', editedInvoice.processedData?.date_of_invoice, 'date', handleExchangeRateChange)}
                                </div>
                            </div>
                            <div className="invoice-number">
                                <div className="invoice-info invoice-info-bottom">
                                    {renderField('Invoice Number', 'invoice_number', invoice.processedData?.invoice_number)}
                                </div>
                            </div>                      
                        </div>
                        <div className="invoice-actions">
                                {!isEditing && (
                                <button className="edit-button" onClick={(e) => handleEditClick(e)}>Edit invoice</button>
                                )}
                                <div className="invoice-info invoice-info-bottom">
                                </div>
                            </div>
                    </div>
                    
                    {/* Invoice Items Section */}
                    <div className="invoice-items">
                    <div className="invoice-items-header">
                        <div className="invoice-items-header-content">
                            <label className="invoice-items-title">Invoice details</label>
                            {!isEditing && (
                                <div className="currency-toggle">
                                    <span className="toggle-label">Base currency</span>
                                    <div className="currency-switch-wrapper">
                                        <label className="switch">
                                            <input
                                                type="checkbox"
                                                checked={showConvertedCurrency}
                                                onChange={toggleCurrency}
                                            />
                                            <span className="slider round"></span>
                                        </label>
                                    </div>
                                </div>
                            )}
                        </div>
                        {isEditing && (
                            <button onClick={handleAddItem} className="add-item-button">Add more items</button>
                        )}
                    </div>
                        {renderItemsTable()}
                    </div>
                    
                </div>

                <div className="invoice-footer">
                    {isEditing ? (
                        <>
                            <button 
                                className="cancel-button" 
                                onClick={handleCancelEdit}
                                disabled={isSaving} 
                            >   
                                Cancel
                            </button>
                            <button 
                                className="save-button" 
                                onClick={handleSaveEdit} 
                                disabled={isSaving}
                            >
                                {isSaving ? 'Saving...' : 'Save'}
                            </button>
                        </>
                    ) : (
                        <>
                            <button className="copy-link-button" onClick={handleCopyLink}>
                                <img src={CopyLinkIcon} alt="Copy icon" className="icon" /> Copy link
                            </button>
                            <button 
                                className="download-original-button" 
                                onClick={handleDownloadOriginal}
                                disabled={isDownloadingOriginal}
                            >
                                <img src={DownloadIcon} alt="Download original icon" className="original-icon" />
                                {isDownloadingOriginal ? 'Downloading...' : 'Download as Original'}
                            </button>
                            <button 
                                className="download-pdf-button" 
                                onClick={handleDownloadPdf}
                                disabled={isDownloading}
                            >
                                <img src={DownloadIcon} alt="Download PDF icon" className="pdf-icon" />
                                {isDownloading ? 'Downloading...' : 'Download as PDF'}
                            </button>
                        </>
                    )}
                </div>

                <Snackbar
                    open={snackbar.open}
                    message={snackbar.message}
                    severity={snackbar.severity}
                />
            </div>
        </div>
    );
};

ViewEditInvoice.propTypes = {
    item: PropTypes.shape({
        processedData: PropTypes.object.isRequired,
        // other shape definitions
    }),
    onClose: PropTypes.func.isRequired,
    onPrevious: PropTypes.func.isRequired,
    onNext: PropTypes.func.isRequired,
    invoiceType: PropTypes.oneOf(['expense', 'revenue']).isRequired,
    onUpdateInvoice: PropTypes.func,
    organizationId: PropTypes.string.isRequired,
    db: PropTypes.object.isRequired,
    suppliers: PropTypes.array.isRequired,
    customers: PropTypes.array.isRequired,
    fetchAllData: PropTypes.func,
    allCategories: PropTypes.array,
    onEditModeChange: PropTypes.func,

};


export default ViewEditInvoice;

