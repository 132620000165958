import PropTypes from "prop-types";
import "./MetricsCard.css";
import arrowrise from '../../assets/icons/arrowrise.svg';
import arrowfall from '../../assets/icons/arrowfall.svg';

const MetricsCard = ({ className = "", title, subtitle, value, percentage, trend, updatingData }) => {
  return (
    <div className={`metrics-card ${className}`}>
      <div className="card-header">
        <div className="header-left">
          {updatingData && <div className="loading-indicator"></div>}
          <div className="title-container">
            <div className="title">{title}</div>
            <div className="subtitle">{subtitle}</div>
          </div>
        </div>
        <div className="header-right">
          <div className="view-text">View</div>
        </div>
      </div>
      <div className="card-body">
        <div className="value">{value}</div>
        <div className="trend">
          <div className={`percentage ${trend === 'up' ? 'up' : 'down'}`}>{percentage}</div>
          <img className="trend-icon" alt="" src={trend === 'up' ? arrowrise : arrowfall} />
        </div>
      </div>
    </div>
  );
};

MetricsCard.propTypes = {
    className: PropTypes.string,
    title: PropTypes.string.isRequired,
    subtitle: PropTypes.string,
    value: PropTypes.string.isRequired,
    percentage: PropTypes.string,
    trend: PropTypes.string,
    updatingData: PropTypes.bool,
};

export default MetricsCard;
