import React from 'react';
import { Box, Typography, Container, styled } from '@mui/material';
import { Check } from '@phosphor-icons/react';
import chatCircle from '../../../assets/icons/message-chat-circle.svg';
import zap from '../../../assets/icons/zap-fast.svg';
import chart from '../../../assets/icons/chart-breakout-square.svg';
import image_1 from '../../../assets/images/features_image_1.png';
import image_2 from '../../../assets/images/features_image_2.png';
import image_3 from '../../../assets/images/features_image_3.png';

const SectionContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  padding: '96px 0',
  flexDirection: 'column',
  alignItems: 'center',
  gap: '96px',
  alignSelf: 'stretch',
  background: '#FFFFFF',

  [theme.breakpoints.down('md')]: {
    padding: '64px 0',
    gap: '64px',
  },
}));

const HeaderContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  gap: '20px',
  alignSelf: 'stretch',
  marginBottom: '48px',
  padding: '0 24px',

  [theme.breakpoints.down('md')]: {
    gap: '16px',
    marginBottom: '32px',
  },
}));

const FeatureTitle = styled(Typography)({
  color: '#009A6B',
  textAlign: 'center',
  fontFamily: 'Inter',
  fontSize: '16px',
  fontWeight: 600,
  lineHeight: '24px',
});

const MainTitle = styled(Typography)(({ theme }) => ({
  color: '#1C1C1C',
  textAlign: 'center',
  fontFamily: 'Inter',
  fontSize: '36px',
  fontWeight: 600,
  lineHeight: '44px',
  letterSpacing: '-0.72px',
  maxWidth: '768px',
  margin: '0 auto',

  [theme.breakpoints.down('md')]: {
    fontSize: '28px',
    lineHeight: '36px',
    letterSpacing: '-0.56px',
  },
}));

const SubTitle = styled(Typography)(({ theme }) => ({
  color: 'rgba(28, 28, 28, 0.80)',
  textAlign: 'center',
  fontFamily: 'Inter',
  fontSize: '20px',
  fontWeight: 400,
  lineHeight: '30px',
  maxWidth: '768px',
  margin: '0 auto',

  [theme.breakpoints.down('md')]: {
    fontSize: '16px',
    lineHeight: '24px',
  },
}));

const FeatureBlock = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: '80px',
  alignSelf: 'stretch',
  paddingBottom: '48px',

  [theme.breakpoints.down('md')]: {
    flexDirection: 'column',
    alignItems: 'center',
    gap: '32px',
    paddingBottom: '32px',
    padding: '0 24px',
  },
}));

const FeatureContent = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  gap: '32px',
  flex: '1 0 0',

  [theme.breakpoints.down('md')]: {
    alignItems: 'center',
    textAlign: 'center',
    gap: '24px',
    width: '100%',
  },
}));

const IconContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  width: '48px',
  height: '48px',
  padding: '12px',
  justifyContent: 'center',
  alignItems: 'center',
  borderRadius: '9999px',
  background: '#BEE7C6',

  [theme.breakpoints.down('md')]: {
    width: '40px',
    height: '40px',
    padding: '10px',
    marginTop: '16px',
  },
}));

const ChatIcon = styled('img')(({ theme }) => ({
  width: '24px',
  height: '24px',

  [theme.breakpoints.down('md')]: {
    width: '20px',
    height: '20px',
  },
}));

const FeatureHeading = styled(Typography)(({ theme }) => ({
  color: '#1C1C1C',
  fontFamily: 'Inter',
  fontSize: '36px',
  fontWeight: 600,
  lineHeight: '44px',
  fontFeatureSettings: '"ss01" on, "cv01" on, "cv11" on',
  marginBottom: '4px',

  [theme.breakpoints.down('md')]: {
    fontSize: '24px',
    lineHeight: '32px',
    textAlign: 'center',
  },
}));

const FeatureDescription = styled(Typography)(({ theme }) => ({
  color: 'rgba(28, 28, 28, 0.80)',
  fontFamily: 'Inter',
  fontSize: '18px',
  fontWeight: 400,
  lineHeight: '24px',
  fontFeatureSettings: '"ss01" on, "cv01" on, "cv11" on',

  [theme.breakpoints.down('md')]: {
    fontSize: '16px',
    textAlign: 'center',
  },
}));


const CheckIconContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  width: '24px',
  height: '24px',
  flexShrink: 0,
  justifyContent: 'center',
  alignItems: 'center',
  borderRadius: '50%',
  border: '1.5px solid #009A6B',
  background: '#FFFFFF',

  [theme.breakpoints.down('md')]: {
    width: '20px',
    height: '20px',
  },
}));

const BulletPoint = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: '12px',
  alignSelf: 'stretch',

  [theme.breakpoints.down('md')]: {
    textAlign: 'left',
    width: '100%',
    maxWidth: '300px',
  },
}));

const BulletText = styled(Typography)(({ theme }) => ({
  color: 'rgba(28, 28, 28, 0.80)',
  fontFamily: 'Inter',
  fontSize: '16px',
  fontWeight: 400,
  lineHeight: '24px',

  [theme.breakpoints.down('md')]: {
    fontSize: '14px',
    lineHeight: '20px',
  },
}));

const FeatureImage = styled('img')(({ theme }) => ({
  width: '560px',
  height: 'auto',
  flexShrink: 0,
  borderRadius: '12px',
  border: '6px solid #1C1C1C',
  objectFit: 'cover',
  filter: 'drop-shadow(0px 32px 64px rgba(0, 0, 0, 0.08))',

  [theme.breakpoints.down('lg')]: {
    width: '100%',
    maxWidth: '560px',
  },

  [theme.breakpoints.down('md')]: {
    maxWidth: '100%',
    border: '4px solid #1C1C1C',
    filter: 'drop-shadow(0px 16px 32px rgba(0, 0, 0, 0.08))',
  },
}));

const FeatureBlockReverse = styled(FeatureBlock)(({ theme }) => ({
  flexDirection: 'row-reverse',

  [theme.breakpoints.down('md')]: {
    flexDirection: 'column',
  },
}));

const FeaturesSection = () => {
  return (
    <SectionContainer>
      <Container maxWidth="lg">
        <HeaderContainer>
          <FeatureTitle>Features</FeatureTitle>
          <MainTitle>Analytics that feels like it's from the future</MainTitle>
          <SubTitle>
            Powerful, self-serve product and growth analytics to help you convert, engage, 
            and retain more users. Trusted by over 4,000 startups.
          </SubTitle>
        </HeaderContainer>

        {/* First Feature Block */}
        <FeatureBlock>
          <FeatureContent>
            <IconContainer>
              <ChatIcon 
                src={chatCircle} 
                alt="Chat Circle Icon"
              />
            </IconContainer>
            <Box sx={{ 
              display: 'flex', 
              flexDirection: 'column', 
              gap: '24px',
              width: '100%',
            }}>
              <FeatureHeading>Invoice without complications</FeatureHeading>
              <FeatureDescription>
                Automated Data Extraction, Upload invoices and let our AI handle the rest.
              </FeatureDescription>
            </Box>
            <Box sx={{ 
              display: 'flex', 
              flexDirection: 'column', 
              gap: '16px',
              width: '100%',
              alignItems: { xs: 'center', md: 'flex-start' },
            }}>
              <BulletPoint>
                <CheckIconContainer>
                  <Check size={14} weight="bold" color="#009A6B" />
                </CheckIconContainer>
                <BulletText>Automate invoice processing to free up valuable time</BulletText>
              </BulletPoint>
              <BulletPoint>
                <CheckIconContainer>
                  <Check size={14} weight="bold" color="#009A6B" />
                </CheckIconContainer>
                <BulletText>Minimize manual entry errors with AI-driven accuracy</BulletText>
              </BulletPoint>
              <BulletPoint>
                <CheckIconContainer>
                  <Check size={14} weight="bold" color="#009A6B" />
                </CheckIconContainer>
                <BulletText>Easily track and manage invoices without the complexity</BulletText>
              </BulletPoint>
            </Box>
          </FeatureContent>
          <FeatureImage 
            src={image_1} 
            alt="Invoice Features Preview"
          />
        </FeatureBlock>

        {/* Second Feature Block */}
        <FeatureBlockReverse>
          <FeatureContent>
            <IconContainer>
              <ChatIcon 
                src={zap} 
                alt="Zap Icon"
              />
            </IconContainer>
            <Box sx={{ 
              display: 'flex', 
              flexDirection: 'column', 
              gap: '24px',
              width: '100%',
            }}>
              <FeatureHeading>Detailed Financial Insights</FeatureHeading>
              <FeatureDescription>
                Monitor your cash flow in real-time to make informed decisions and avoid financial pitfalls.
              </FeatureDescription>
            </Box>
            <Box sx={{ 
              display: 'flex', 
              flexDirection: 'column', 
              gap: '16px',
              width: '100%',
              alignItems: { xs: 'center', md: 'flex-start' },
            }}>
              {/* Same bullet points structure as first block */}
              <BulletPoint>
                <CheckIconContainer>
                  <Check size={14} weight="bold" color="#009A6B" />
                </CheckIconContainer>
                <BulletText>Stay on top of your cash flow with up-to-the-minute insights</BulletText>
              </BulletPoint>
              <BulletPoint>
                <CheckIconContainer>
                  <Check size={14} weight="bold" color="#009A6B" />
                </CheckIconContainer>
                <BulletText>Make better financial choices by leveraging detailed analytics</BulletText>
              </BulletPoint>
              <BulletPoint>
                <CheckIconContainer>
                  <Check size={14} weight="bold" color="#009A6B" />
                </CheckIconContainer>
                <BulletText>Avoid financial pitfalls with proactive cash flow management</BulletText>
              </BulletPoint>
            </Box>
          </FeatureContent>
          <FeatureImage 
            src={image_2}
            alt="Financial Insights Preview"
          />
        </FeatureBlockReverse>

        {/* Third Feature Block */}
        <FeatureBlock>
          <FeatureContent>
            <IconContainer>
              <ChatIcon 
                src={chart} 
                alt="Chart Icon"
              />
            </IconContainer>
            <Box sx={{ 
              display: 'flex', 
              flexDirection: 'column', 
              gap: '24px',
              width: '100%',
            }}>
              <FeatureHeading>Tailored Insights</FeatureHeading>
              <FeatureDescription>
                Receive personalized financial insights and alerts that align with your business goals.
              </FeatureDescription>
            </Box>
            <Box sx={{ 
              display: 'flex', 
              flexDirection: 'column', 
              gap: '16px',
              width: '100%',
              alignItems: { xs: 'center', md: 'flex-start' },
            }}>
              {/* Same bullet points structure as previous blocks */}
              <BulletPoint>
                <CheckIconContainer>
                  <Check size={14} weight="bold" color="#009A6B" />
                </CheckIconContainer>
                <BulletText>Get financial insights tailored to your specific business needs</BulletText>
              </BulletPoint>
              <BulletPoint>
                <CheckIconContainer>
                  <Check size={14} weight="bold" color="#009A6B" />
                </CheckIconContainer>
                <BulletText>Ensure your financial strategies align with your business objectives</BulletText>
              </BulletPoint>
              <BulletPoint>
                <CheckIconContainer>
                  <Check size={14} weight="bold" color="#009A6B" />
                </CheckIconContainer>
                <BulletText>Stay informed with alerts customized to your business goals</BulletText>
              </BulletPoint>
            </Box>
          </FeatureContent>
          <FeatureImage 
            src={image_3}
            alt="Tailored Insights Preview"
          />
        </FeatureBlock>
      </Container>
    </SectionContainer>
  );
};

export default FeaturesSection;
