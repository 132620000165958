import React from 'react';
import { Box, Typography, Container, Button, styled } from '@mui/material';
import { ArrowLeft, ArrowRight } from '@phosphor-icons/react';

// Import images
import alisaImage from '../../../assets/images/alisa-hester.jpg';
import richImage from '../../../assets/images/rich-wilson.png';
import annieImage from '../../../assets/images/annie-stanley.png';
import ammarImage from '../../../assets/images/ammar-foley.png';

const SectionContainer = styled(Box)(({ theme }) => ({
  background: '#FFFFFF',
  padding: '96px 0',
  '@media (max-width: 768px)': {
    padding: '64px 0',
  },
}));

const MainTestimonial = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  gap: '32px',
  marginBottom: '96px',
  '@media (max-width: 768px)': {
    marginBottom: '64px',
    gap: '24px',
  },
}));

const MainQuote = styled(Typography)(({ theme }) => ({
  color: '#1C1C1C',
  textAlign: 'center',
  fontFamily: 'Inter',
  fontSize: '36px',
  fontWeight: 500,
  lineHeight: '44px',
  letterSpacing: '-0.72px',
  maxWidth: '900px',
  margin: '0 auto',
  '@media (max-width: 768px)': {
    fontSize: '32px',
    lineHeight: '40px',
    letterSpacing: '-0.64px',
  },
}));

const ProfileContainer = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  gap: '8px',
});

const ProfileImage = styled('img')({
  width: '64px',
  height: '64px',
  borderRadius: '9999px',
  objectFit: 'cover',
});

const ProfileName = styled(Typography)({
  color: '#1C1C1C',
  fontFamily: 'Inter',
  fontSize: '16px',
  fontWeight: 600,
});

const ProfileTitle = styled(Typography)({
  color: 'rgba(28, 28, 28, 0.60)',
  fontFamily: 'Inter',
  fontSize: '14px',
  textAlign: 'center',
});

const TestimonialsHeader = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '16px',
  marginBottom: '64px',
  '@media (max-width: 768px)': {
    marginBottom: '40px',
    gap: '12px',
  },
}));

const TestimonialsTitle = styled(Typography)(({ theme }) => ({
  color: '#101828',
  fontFamily: 'Inter',
  fontSize: '36px',
  fontWeight: 600,
  lineHeight: '44px',
  fontFeatureSettings: '"ss01" on, "cv01" on, "cv11" on',
  '@media (max-width: 768px)': {
    fontSize: '32px',
    lineHeight: '40px',
  },
}));

const TestimonialsSubtitle = styled(Typography)(({ theme }) => ({
  color: 'rgba(28, 28, 28, 0.60)',
  fontFamily: 'Inter',
  fontSize: '18px',
  fontWeight: 400,
  lineHeight: '24px',
  fontFeatureSettings: '"ss01" on, "cv01" on, "cv11" on',
  '@media (max-width: 768px)': {
    fontSize: '16px',
  },
}));

const CarouselContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  gap: '24px',
  position: 'relative',
  overflow: 'hidden',
  marginBottom: '32px',
  '@media (max-width: 768px)': {
    gap: '16px',
  },
}));

const TestimonialCard = styled(Box)(({ theme }) => ({
  width: '360px',
  aspectRatio: '3 / 4',
  flexShrink: 0,
  borderRadius: '8px',
  overflow: 'hidden',
  '@media (max-width: 768px)': {
    width: '280px',
  },
}));

const TestimonialImage = styled('img')({
  width: '100%',
  height: '100%',
  objectFit: 'cover',
  objectPosition: 'center top',
  display: 'block',
});

const NavigationContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  gap: '12px',
  marginTop: '32px',
  '@media (max-width: 768px)': {
    marginTop: '24px',
  },
}));

const NavButton = styled(Button)({
  display: 'flex',
  width: '56px',
  height: '56px',
  justifyContent: 'center',
  alignItems: 'center',
  gap: '12px',
  minWidth: 'unset',
  padding: 0,
  borderRadius: '9999px',
  border: '1px solid rgba(28, 28, 28, 0.10)',
  color: '#1C1C1C',
  '&:hover': {
    background: '#F9FAFB',
  },
});

const TestimonialsSection = () => {
  return (
    <SectionContainer>
      <Container maxWidth="lg">
        <MainTestimonial>
          <MainQuote>
            Suma has saved us thousands of hours of work and has unlock data insights we never thought possible.
          </MainQuote>
          <ProfileContainer>
            <ProfileImage src={ammarImage} alt="Ammar Foley" />
            <ProfileName>Ammar Foley</ProfileName>
            <ProfileTitle>CFO at somecompany.com</ProfileTitle>
          </ProfileContainer>
        </MainTestimonial>

        <TestimonialsHeader>
          <TestimonialsTitle>Don't just take our word for it</TestimonialsTitle>
          <TestimonialsSubtitle>
            Hear from some of our amazing customers who are automating their finances.
          </TestimonialsSubtitle>
        </TestimonialsHeader>

        <CarouselContainer>
          {[
            { image: alisaImage, alt: "Alisa Hester's Testimonial" },
            { image: richImage, alt: "Rich Wilson's Testimonial" },
            { image: annieImage, alt: "Annie Stanley's Testimonial" },
          ].map((testimonial, index) => (
            <TestimonialCard key={index}>
              <TestimonialImage 
                src={testimonial.image} 
                alt={testimonial.alt}
              />
            </TestimonialCard>
          ))}
        </CarouselContainer>

        <NavigationContainer>
          <NavButton>
            <ArrowLeft size={24} weight="bold" />
          </NavButton>
          <NavButton>
            <ArrowRight size={24} weight="bold" />
          </NavButton>
        </NavigationContainer>
      </Container>
    </SectionContainer>
  );
};

export default TestimonialsSection;
