// src/firebase.js
import { initializeApp } from "firebase/app";
import { getAuth, GoogleAuthProvider } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";

const firebaseConfig = {
    apiKey: "AIzaSyDxeDmov8E1iz4aZGReJdYdWNCBfypUS_Y",
    authDomain: "magna-01-b67f7.firebaseapp.com",
    projectId: "magna-01-b67f7",
    storageBucket: "magna-01-b67f7.appspot.com",
    messagingSenderId: "938122813200",
    appId: "1:938122813200:web:d6babf4de7e7a160880f95",
    measurementId: "G-4J6MQCH07Y"
  };

console.log("Initializing Firebase with config:", firebaseConfig);


const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const db = getFirestore(app);
const storage = getStorage(app);
const googleProvider = new GoogleAuthProvider();


export { auth, db, storage, googleProvider };
