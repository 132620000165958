import React, { useState, useEffect, useCallback } from 'react';
import { useAuth } from '../../auth';
import { loadStripe } from '@stripe/stripe-js';
import { useLocation, useNavigate } from 'react-router-dom';  // Add this import


import './PlanPage.css';

import basicPlanIcon from '../../assets/icons/layers-two-01.svg';
import businessPlanIcon from '../../assets/icons/layers-three-01.svg';
import enterprisePlanIcon from '../../assets/icons/zap.svg';
import checkboxChecked from '../../assets/icons/Checkbox.svg';
import checkboxUnchecked from '../../assets/icons/CheckboxOff.svg';

console.log('Stripe publishable key:', process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);

const PlanPage = () => {
  const { user, organization, getIdToken } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();
  const [currentPlan, setCurrentPlan] = useState(null);
  const [subscription, setSubscription] = useState(null);
  const [selectedPlan, setSelectedPlan] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const BACKEND_API_URL = process.env.REACT_APP_BACKEND_API_URL;

  const plans = [
    {
        id: 'free_plan',
        name: 'Free Plan',
        price: 0,
        description: 'Includes 1 user, up to 20 AI processed invoices per month, up to 50 customers/suppliers.',
        limited: true,
        icon: basicPlanIcon,
        stripePriceId: null
    },
    {
      id: 'basic_plan',
      name: 'Basic Plan',
      price: 29.99,
      description: 'Includes up to 2 users, 500 AI processed invoices per month, up to 500 customers/suppliers.',
      limited: true,
      icon: basicPlanIcon,
      stripePriceId: 'price_1QEIP8IRcIMoS3btujMX2lrS'
    },
    {
      id: 'business_plan',
      name: 'Business Plan',
      price: 49.99,
      description: 'Includes up to 10 users, 1000 AI processed invoices per month, up to 1000 customers/suppliers.',
      icon: businessPlanIcon,
      stripePriceId: 'price_1QEIPRIRcIMoS3btaPIkQ1I6'
    },
    {
      id: 'enterprise_plan',
      name: 'Enterprise Plan',
      price: 499,
      description: 'Unlimited users, unlimited individual data and access to all features.',
      icon: enterprisePlanIcon,
      stripePriceId: 'price_1QEIR1IRcIMoS3btor7XShb0'
    }
];

const fetchOrganizationSubscription = useCallback(async () => {
  try {
    setLoading(true);
    const response = await fetch(
      `${BACKEND_API_URL}/api/organizations/${organization.id}/subscription`,
      {
        headers: {
          'Authorization': `Bearer ${await getIdToken()}`
        }
      }
    );
    
    if (!response.ok) throw new Error('Failed to fetch subscription');
    
    const data = await response.json();
    console.log('Fetched subscription data:', data);
    
    setCurrentPlan(data.planId);
    setSelectedPlan(data.planId);
    setSubscription(data);
  } catch (err) {
    setError(err.message);
    console.error('Error fetching subscription:', err);
  } finally {
    setLoading(false);
    }
  }, [organization, getIdToken, BACKEND_API_URL]);




useEffect(() => {
  if (organization) {
    const queryParams = new URLSearchParams(location.search);
    const success = queryParams.get('success');
    const canceled = queryParams.get('canceled');

    if (success === 'true') {
      // Clear the URL parameters
      navigate('/settings?tab=Plan', { replace: true });
      // Refetch subscription details
      fetchOrganizationSubscription();
    } else if (canceled === 'true') {
      navigate('/settings?tab=Plan', { replace: true });
    } else {
      fetchOrganizationSubscription();
    }
  }
}, [organization, location.search, navigate, fetchOrganizationSubscription]);




  const handlePlanSelection = (plan) => {
    console.log('handlePlanSelection', plan.id);
    setSelectedPlan(plan.id); // Change this to use plan.id instead of plan.name
  };

  const handleSave = async () => {
    console.log('handleSave');
    if (selectedPlan === currentPlan) return;
    
    try {
        setLoading(true);
        const selectedPlanData = plans.find(plan => plan.id === selectedPlan);
        console.log(selectedPlanData);
        
        if (!selectedPlanData) {
            throw new Error('Selected plan not found');
        }

        // Handle downgrade to free plan
        if (selectedPlan === 'free_plan') {
            await cancelSubscription();
            return;
        }

        // Create checkout session for paid plans
        const response = await fetch(
            `${BACKEND_API_URL}/api/organizations/${organization.id}/create-checkout-session`,
            {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${await getIdToken()}`
                },
                body: JSON.stringify({
                    priceId: selectedPlanData.stripePriceId,
                    currentPlanId: currentPlan
                })
            }
        );

        if (!response.ok) {
            const errorData = await response.json();
            throw new Error(errorData.error || 'Failed to create checkout session');
        }

        const { sessionId } = await response.json();

        console.log('Checkout session created:', sessionId);


        const stripe = await stripePromise;
        
        const { error } = await stripe.redirectToCheckout({ sessionId });
        if (error) throw error;

    } catch (err) {
        setError(err.message);
        console.error('Error in handleSave:', err);
    } finally {
        setLoading(false);
    }
};




  const getCurrentPlanDetails = () => {
    if (!subscription) return null;
    
    // Find the current plan details from our plans array
    const currentPlanDetails = plans.find(plan => plan.id === subscription.planId);
    
    return (
      <div className="current-plan">
        <h3>Current plan: {currentPlanDetails?.name}</h3>
        {subscription.status === 'active' ? (
          <>
            <p>Your subscription will renew on {new Date(subscription.currentPeriodEnd).toLocaleDateString()}</p>
            <div className="plan-limits">
              <h4>Your current limits:</h4>
              <ul>
                <li>Users: {subscription.planDetails?.limits.maxUsers}</li>
                <li>AI Processed Invoices: {subscription.planDetails?.limits.maxMonthlyAIInvoiceUploads}/month</li>
                <li>Customers/Suppliers: {subscription.planDetails?.limits.maxSuppliersCustomers}</li>
                {/* Add other limits as needed */}
              </ul>
            </div>
          </>
        ) : (
          <p>We'll renew your account if you need to change/upgrade during the billing cycle.</p>
        )}
      </div>
    );
  };



  const cancelSubscription = async () => {
    try {
      const token = await user.getIdToken();
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/api/organizations/${organization.id}/cancel-subscription`,
        {
          method: 'POST',
          headers: {
            'Authorization': `Bearer ${token}`
          }
        }
      );

      if (!response.ok) throw new Error('Failed to cancel subscription');

      await fetchOrganizationSubscription();
    } catch (err) {
      setError(err.message);
    }
  };

  if (loading) return <div className="plan-page">Loading...</div>;
  if (error) return <div className="plan-page">Error: {error}</div>;



  return (
    <div className="plan-page">
      <div className="plan-header">
        <div>
          <h2>Account plans</h2>
          <p className="plan-subtitle">Pick an account plan that fits your workflow.</p>
        </div>
        <div className="button-group">
          <button 
            className="btn-cancel" 
            onClick={() => setSelectedPlan(currentPlan)}
            disabled={loading}
          >
            Cancel
          </button>
          <button 
            className="btn-save" 
            onClick={handleSave}
            disabled={loading || selectedPlan === currentPlan}
          >
            {loading ? 'Processing...' : 'Save'}
          </button>
        </div>
      </div>

      <div className="divider"></div>
      <div className="plan-content">
        {getCurrentPlanDetails()}

        <div className="plan-options">
          {plans.map((plan) => (
            <div
              key={plan.id}
              className={`plan-option ${selectedPlan === plan.id ? 'selected' : ''}`}
              onClick={() => handlePlanSelection(plan)}
            >
              <div className="plan-option-content">
                <div className="plan-icon-name">
                  <div className={`plan-icon ${selectedPlan === plan.id ? 'selected' : ''}`}>
                    <img src={plan.icon} alt={`${plan.name} icon`} />
                  </div>
                  <h3>{plan.name}</h3>
                  {plan.limited && <span className="limited-tag">Limited time only</span>}
                </div>
                <div className="plan-price">
                  ${plan.price} <span>per month</span>
                </div>
                <p className="plan-description">{plan.description}</p>
              </div>
              <div className="plan-checkbox">
                <img 
                  src={selectedPlan === plan.id ? checkboxChecked : checkboxUnchecked}
                  alt={selectedPlan === plan.id ? "Selected" : "Not selected"}
                />
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};




//   return (
//     <div className="plan-page">
//       <div className="plan-header">
//         <div>
//           <h2>Account plans</h2>
//           <p className="plan-subtitle">Pick an account plan that fits your workflow.</p>
//         </div>
//         <div className="button-group">
//           <button className="btn-cancel">Cancel</button>
//           <button className="btn-save" onClick={handleSave}>Save</button>
//         </div>
//       </div>

//       <div className="divider"></div>

//       <div className="plan-content">
//         <div className="current-plan">
//           <h3>Current plan</h3>
//           <p>We'll renew your account if you need to change/upgrade during the billing cycle.</p>
//         </div>

//         <div className="plan-options">
//           {plans.map((plan) => (
//             <div
//               key={plan.name}
//               className={`plan-option ${selectedPlan === plan.name ? 'selected' : ''}`}
//               onClick={() => handlePlanSelection(plan.name)}
//             >
//               <div className="plan-option-content">
//                 <div className="plan-icon-name">
//                   <div className={`plan-icon ${selectedPlan === plan.name ? 'selected' : ''}`}>
//                     <img src={plan.icon} alt={`${plan.name} icon`} />
//                   </div>
//                   <h3>{plan.name}</h3>
//                   {plan.limited && <span className="limited-tag">Limited time only</span>}
//                 </div>
//                 <div className="plan-price">${plan.price} <span>per month</span></div>
//                 <p className="plan-description">{plan.description}</p>
//               </div>
//               <div className="plan-checkbox">
//                 <img 
//                   src={selectedPlan === plan.name ? checkboxChecked : checkboxUnchecked} 
//                   alt={selectedPlan === plan.name ? "Selected" : "Not selected"} 
//                 />
//               </div>
//             </div>
//           ))}
//         </div>
//       </div>
//     </div>
//   );
// };

export default PlanPage;