import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../auth';
import TableComponent from '../TableComponent/TableComponent';
import HeaderComponent from '../HeaderComponent/HeaderComponent';
import MetricsCardsContainer from '../MetricsCardsContainer/MetricsCardsContainer';
import { format, formatDistanceToNow, isToday, isYesterday, parseISO, differenceInDays, isFuture } from 'date-fns';
import LoadingSpinner from '../LoadingSpinner/LoadingSpinner';
import config from '../../config';
import './AccountantDashboard.css';

const AccountantDashboard = () => {
    const [organizations, setOrganizations] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [perPage, setPerPage] = useState(20);
    const navigate = useNavigate();
    const { user, setAccountantOrg, fetchOrganizationsDetails, getIdToken } = useAuth();
    const [error, setError] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [analytics, setAnalytics] = useState(null);
    const BACKEND_API_URL = config.BACKEND_API_URL;


    const fetchOrganizations = async (user, fetchOrganizationsDetails, setOrganizations, setTotalPages, perPage, setAnalytics, setError, setIsLoading) => {
      console.log("Fetching organizations in AccountantDashboard.js");

      console.log("User in fetchOrganizations:", user);
      
      if (user && user.type === 'Accountant' && user.organizations) {

        setIsLoading(true);
        setError(null);
        console.log('User:', user);
        
        const orgIds = Object.keys(user.organizations);
        console.log('Org IDs:', orgIds);
    
        try {
            const orgDetails = await fetchOrganizationsDetails(orgIds);
            console.log('Fetched organization details:', orgDetails);
      
            const orgData = Object.entries(orgDetails.organizations).map(([orgId, companyDetails]) => {

              const processDate = (dateValue) => {
                if (dateValue === 'N/A' || !dateValue) {
                  return null;
                }
                const date = new Date(dateValue);
                return isNaN(date.getTime()) ? null : date.toISOString();
              };

              return {
                  id: orgId,
                  clientName: companyDetails.name,
                  lastUpdate: processDate(companyDetails.updatedAt),
                  added: processDate(companyDetails.createdAt),
                  totalRevenue: companyDetails.totalRevenue || 0,
                  totalExpenses: companyDetails.totalExpenses || 0,
                  taxNumber: companyDetails.taxNumber || 'N/A',
                  accessType: user.organizations[orgId].role
                };
              });
      
            console.log('Final org data:', orgData);
            setOrganizations(orgData.length > 0 ? orgData : []); // Ensure we always set an array
            setTotalPages(Math.ceil(orgData.length / perPage));
            setAnalytics(orgDetails.analytics);
  
          } catch (error) {
            console.error("Error fetching organizations:", error);
            setError("Failed to fetch organization details. Please try again later.");
            setOrganizations([]); // Set an empty array in case of error
          } finally {
            setIsLoading(false);
          }
    } else {
      console.log("No user or user is not an accountant");
      setIsLoading(false);
      setOrganizations([]); // Set an empty array in case of error

    }
  };


  useEffect(() => {
    console.log("Fetching organizations");
    fetchOrganizations(user, fetchOrganizationsDetails, setOrganizations, setTotalPages, perPage, setAnalytics, setError, setIsLoading);
    console.log("Organizations fetched");
  }, [user, perPage]);


  const formatDate = useCallback((dateString) => {
    if (!dateString) return 'N/A';

    const date = typeof dateString === 'string' ? parseISO(dateString) : dateString;
    const now = new Date();
  
    if (isFuture(date)) {
      return format(date, 'MMM d, yyyy');
    }
  
    const daysDifference = differenceInDays(now, date);
  
    if (isToday(date)) {
      return formatDistanceToNow(date, { addSuffix: true });
    } else if (isYesterday(date)) {
      return 'Yesterday';
    } else if (daysDifference < 7) {
      return formatDistanceToNow(date, { addSuffix: true });
    } else {
      return format(date, 'MMM d, yyyy');
    }
  }, []);

  const formatCurrency = useCallback((value) => {
    return new Intl.NumberFormat('en-IE', { style: 'currency', currency: 'EUR' }).format(value);
  }, []);

  const columns = [
    { id: 'clientName', label: 'Client Name', className: 'client-name', fixed: true, defaultVisible: true, sortable: true },
    { id: 'lastUpdate', label: 'Last Update', className: 'last-update', defaultVisible: true, sortable: true },
    { id: 'added', label: 'Added', className: 'added-date', defaultVisible: true, sortable: true },
    { id: 'totalRevenue', label: 'Total Revenue', className: 'total-revenue', defaultVisible: true, sortable: true },
    { id: 'totalExpenses', label: 'Total Expenses', className: 'total-expenses', defaultVisible: true, sortable: true },
    { id: 'taxNumber', label: 'Tax ID Number', className: 'tax-id', defaultVisible: true, sortable: true },
    { id: 'accessType', label: 'Access Type', className: 'access-type', defaultVisible: true, sortable: true },
  ];


  const formattedOrganizations = useMemo(() => {

    if (!organizations) {
      return [];
    }


    return organizations.map(org => ({
      ...org,
      lastUpdate: org.lastUpdate ? formatDate(org.lastUpdate) : 'N/A',
      added: org.added ? formatDate(org.added) : 'N/A',
      totalRevenue: formatCurrency(org.totalRevenue),
      totalExpenses: formatCurrency(org.totalExpenses),
      taxNumber: org.taxNumber?.toUpperCase()
    }));
  }, [organizations, formatDate, formatCurrency]);

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
    // In a real scenario, you'd fetch data for the new page here
  };

  const handlePerPageChange = (newPerPage) => {
    setPerPage(newPerPage);
    setCurrentPage(1);
    setTotalPages(Math.ceil(organizations.length / newPerPage));
  };

  const handleRowClick = (organization) => {
    setAccountantOrg(organization);
    navigate('/dashboard');
  };

  const removeOrganizationFromAccountant = async (accountantId, organizationIds) => {
    console.log("Removing organizations from accountant:", accountantId, organizationIds);

    console.log("BACKEND_API_URL:", BACKEND_API_URL);

    const token = await getIdToken();
    const response = await fetch(`${BACKEND_API_URL}/api/remove-organizations-from-accountant`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      },  
      body: JSON.stringify({ accountantId, organizationIds })
    });
  
    if (!response.ok) {
      const errorData = await response.json();
      throw new Error(errorData.error || 'Failed to remove organizations');
    }
  
    return response.json();
  };



  const handleRemoveOrganizations = async (organizationIds) => {
    setIsLoading(true);
  
    console.log("User object:", user);
    console.log("Organization IDs:", organizationIds);
  
    if (!user || !user.uid) {
      console.error("User ID is missing or invalid");
      setError("User information is not available. Please try logging in again.");
      setIsLoading(false);
      return;
    }
  
    try {
      // Call the backend API to remove organizations
      await removeOrganizationFromAccountant(user.uid, organizationIds);
      console.log("Organizations removed successfully");

      // Update the user object to reflect the removed organizations
      const updatedUser = {...user};
      organizationIds.forEach(orgId => {
        delete updatedUser.organizations[orgId];
      });

      // Fetch organizations again to update the list
      console.log("Fetching organizations again");
      await fetchOrganizations(updatedUser, fetchOrganizationsDetails, setOrganizations, setTotalPages, perPage, setAnalytics, setError, setIsLoading);
      console.log("Organizations fetched successfully");
    } catch (error) {
      console.error("Error removing organizations:", error);
      setError("Failed to remove organizations. Please try again later.");
    } finally {
      setIsLoading(false);
    }
  };

  const handleAddClick = () => {
    navigate('/settings?tab=Team');
  };


  return (
    <div className="accountant-dashboard">
      <HeaderComponent 
        className="header-component" 
        onAddClick={() => {}}
        pageType="accountant-dashboard"
        entityType="organization"
      />
      {error && <div className="error-message">{error}</div>}
      <MetricsCardsContainer 
        className="metrics-cards-container"
        accountantAnalytics={analytics}

      />
      {isLoading ? (
        <LoadingSpinner />
      ) : (
        <TableComponent
          className="organizations-table"
          data={organizations}
          columns={columns}
          stickyColumnId="clientName"
          currentPage={currentPage}
          totalPages={totalPages}
          onPageChange={handlePageChange}
          onPerPageChange={handlePerPageChange}
          perPage={perPage}
          onRowClick={handleRowClick}
          showEntityFilters={false}
          showDateFilter={false}
          pageType="accountant-dashboard"
          invoiceType="expense"
          categoryField="category"
          entityField="clientName"
          db={{}}
          onRemoveOrganizations={handleRemoveOrganizations}
          onAddClick={handleAddClick}
        />
      )}
    </div>
  );
};

export default AccountantDashboard;