import React, { useState } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { useAuth } from "../../../auth";
import { signInWithEmailAndPassword } from "firebase/auth";
import { auth } from "../../../firebase";
import { handleGoogleSignIn as authHandleGoogleSignIn } from '../../../authUtils';
import { doc, updateDoc, serverTimestamp } from 'firebase/firestore';
import { db } from '../../../firebase';
import './SignIn.css';

import sumaLogo from '../../../assets/logo.svg';
import googleIcon from '../../../assets/icons/google-icon.svg';
import appPreviewImage from '../../../assets/images/mockup.png';

const UserTypeModal = ({ isOpen, onClose, onSelect, isLoading }) => {
  const [selectedType, setSelectedType] = useState('freelance');
  const [error, setError] = useState(null);

  if (!isOpen) return null;

  const handleSubmit = () => {
    if (!selectedType) {
      setError("Please select a user type");
      return;
    }
    setError(null);
    onSelect(selectedType);
  };

  return (
    <div className="modal-overlay" onClick={(e) => {
      // Only close if clicking the overlay, not the modal content
      if (e.target === e.currentTarget && !isLoading) onClose();
    }}>
      <div className="modal-content" onClick={e => e.stopPropagation()}>
        <h2>What type of user are you?</h2>
        <p>Please select your account type to continue</p>
        
        <div className="user-type-selector">
          <button 
            className={`type-btn ${selectedType === 'freelance' ? 'active' : ''}`}
            onClick={() => setSelectedType('freelance')}
            disabled={isLoading}
          >
            Freelance
          </button>
          <button 
            className={`type-btn ${selectedType === 'company' ? 'active' : ''}`}
            onClick={() => setSelectedType('company')}
            disabled={isLoading}
          >
            Company
          </button>
          <button 
            className={`type-btn ${selectedType === 'accountant' ? 'active' : ''}`}
            onClick={() => setSelectedType('accountant')}
            disabled={isLoading}
          >
            Accountant
          </button>
        </div>

        {error && <p className="modal-error">{error}</p>}

        <div className="modal-actions">
          {!isLoading && (
            <button 
              className="modal-cancel" 
              onClick={onClose}
              type="button"
            >
              Cancel
            </button>
          )}
          <button 
            className="modal-submit" 
            onClick={handleSubmit}
            disabled={isLoading}
          >
            {isLoading ? 'Processing...' : 'Continue'}
          </button>
        </div>
      </div>
    </div>
  );
};


const SignIn = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const navigate = useNavigate();
  const { loading, updateUserData } = useAuth();
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [showUserTypeModal, setShowUserTypeModal] = useState(false);
  const [tempUserData, setTempUserData] = useState(null);



  const handleSubmit = async (e) => {
    e.preventDefault();
    setError(null);
    setIsLoading(true);
    try {
      await signInWithEmailAndPassword(auth, email, password);
      const isNewUser = localStorage.getItem('isNewUser') === 'true';
      if (isNewUser) {
        navigate('/onboarding');
      } else {
        navigate('/dashboard');
      }
      localStorage.removeItem('isNewUser'); // Clear the flag


    } catch (error) {
      console.error("Error signing in:", error);
      if (error.code === 'auth/user-not-found') {
        setError("No account found with this email.");
      } else if (error.code === 'auth/wrong-password') {
        setError("Incorrect password. Please try again.");
      } else {
        setError("Failed to sign in. Please check your credentials and try again.");
      }
    } finally {
      setIsLoading(false);
    }
  };


  const handleGoogleSignIn = async () => {
    setError(null);
    setIsLoading(true);
    try {
      // Prevent any navigation during Google sign-in process
      const result = await authHandleGoogleSignIn();
      console.log("Google sign-in result:", result);

      if (result.isNewUser) {
        console.log("New user detected, showing modal");
        setTempUserData({
          uid: result.user.uid,
          email: result.user.email,
          name: result.user.displayName || ''
        });
        setShowUserTypeModal(true);
      } else if (!result.hasOrganization) {
        console.log("Existing user without organization, navigating to onboarding");
        navigate('/onboarding');
      } else {
        console.log("Existing user with organization, navigating to dashboard");
        navigate('/dashboard');
      }

    } catch (error) {
      console.error("Error signing in with Google:", error);
      setError("Failed to sign in with Google. Please try again.");
    } finally {
      setIsLoading(false);
    }
  };
  

  if (loading) {
    return <div>Loading...</div>;
  }

  const handleUserTypeSelection = async (selectedType) => {
    console.log("Handling user type selection:", selectedType);
    setIsLoading(true);
    try {
      if (!tempUserData?.uid) {
        throw new Error("No user data available");
      }

      console.log("Updating user document for:", tempUserData.uid);
      const userRef = doc(db, "users", tempUserData.uid);
      
      const userData = {
        type: selectedType === 'accountant' ? 'Accountant' : 'User',
        userType: selectedType,
        updatedAt: serverTimestamp(),
        name: tempUserData.name,
        email: tempUserData.email,
        role: "Owner"
      };

      await updateDoc(userRef, userData);

      // Update auth context with new user data
      await updateUserData(tempUserData.uid, userData);

      // Close modal and cleanup
      setShowUserTypeModal(false);
      setTempUserData(null);

      // Navigate to onboarding
      console.log("Navigating to onboarding...");
      navigate('/onboarding');

    } catch (error) {
      console.error("Error updating user type:", error);
      setError("Failed to set user type. Please try again.");
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="signin-container">
      <div className="signin-form">
        <img src={sumaLogo} alt="Suma Logo" className="suma-logo" />
        <div className="signin-header">
          <h1>Sign in</h1>
          <p>Welcome back! Please enter your details.</p>
        </div>

        <form onSubmit={handleSubmit}>
          <div className="form-group">
            <label htmlFor="email">Email</label>
            <input 
              type="email" 
              id="email" 
              placeholder="Enter your email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="password">Password</label>
            <input 
              type="password" 
              id="password" 
              placeholder="Enter your password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
            />
          </div>
          <div className="form-group">
            <Link to="/forgot-password" className="forgot-password-link">Forgot password?</Link>
          </div>
          <button type="submit" className="submit-btn" disabled={isLoading}>
            {isLoading ? 'Signing in...' : 'Sign in'}
          </button>
        </form>

        <div className="google-signin">
          <button className="google-btn" onClick={handleGoogleSignIn} disabled={isLoading}>
            <img src={googleIcon} alt="Google Icon" />
            Sign in with Google
          </button>
        </div>

        {error && <p className="error-message">{error}</p>}

        <p className="signup-link">
          Don't have an account? <Link to="/signup">Sign up</Link>
        </p>
      </div>
      <div className="signin-info">
        <div className="testimonial">
          <p className="testimonial-text">Suma's AI features are a game-changer for my accounting work. The automated categorization and smart invoicing save me countless hours each month. Continuously improving the accuracy and efficiency of my financial management.</p>
          <div className="testimonial-author">
            <div className="author-info">
              <span className="author-name">— Aliah Lane</span>
              <div className="rating">★★★★★</div>
            </div>
            <span className="author-title">Accountant at Gestoría Gesys</span>
          </div>
        </div>
        <div className="app-preview">
          <img src={appPreviewImage} alt="App Preview" className="app-preview-image" />
        </div>
      </div>
      {showUserTypeModal && ( // Add conditional rendering check
        <UserTypeModal 
          isOpen={showUserTypeModal}
          onClose={() => {
            setShowUserTypeModal(false);
            setTempUserData(null);
          }}
          onSelect={handleUserTypeSelection}
          isLoading={isLoading}
        />
      )}
    </div>
  );
};

export default SignIn;

