import React from 'react';
import { useNavigate } from 'react-router-dom';
import './Error404.css';
import arrowLeftIcon from '../../assets/icons/arrow-left.svg';

const Error404 = () => {
    const navigate = useNavigate();
    const handleGoBack = () => {
        navigate(-1); // This navigates to the previous page in the history
    };
    const handleTakeMeHome = () => {
        navigate('/'); // This navigates to the home page
    };

    return (
        <div className="error-404-container">
          <div className="error-404-content">
            <p className="error-404-label">404 error</p>
            <h1 className="error-404-title">We can't find that page</h1>
            <p className="error-404-description">
              Sorry, the page you are looking for doesn't exist or has been moved.
            </p>
            <div className="error-404-buttons">
              <button 
                className="error-404-button go-back-button"
                onClick={handleGoBack}
              >
                <img src={arrowLeftIcon} alt="Go back" className="go-back-icon" />
                Go back
              </button>
              <button 
                className="error-404-button take-home-button"
                onClick={handleTakeMeHome}
              >
                Take me home
              </button>
            </div>
          </div>
        </div>
      );
    };
    

export default Error404;
