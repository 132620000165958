// src/components/InvoicesPage/InvoicesPage.js
import React, { useState, useEffect, useCallback, useRef } from 'react';
import { useOutletContext, useNavigate, useParams, Routes, Route  } from 'react-router-dom';

import { storage, db } from "../../firebase";
import { useAuth } from "../../auth";
import { doc, getDoc } from "firebase/firestore";

import HeaderComponent from '../HeaderComponent/HeaderComponent';
import MetricsCardsContainer from '../MetricsCardsContainer/MetricsCardsContainer';
import TableComponent from '../TableComponent/TableComponent';
import UploadInvoicesModal from '../UploadInvoicesModal/UploadInvoicesModal';
import ViewEditInvoice from '../ViewEditInvoice/ViewEditInvoice';

import config from '../../config';
import { handleDelete } from '../../utils/invoiceUtils';
import { createAnalyticsTrigger, pollAnalyticsTriggerStatus } from '../../utils/analyticsUtils';
// add the css
import './InvoicesPage.css';

const InvoicesPage = ({ invoiceType, isNewInvoice }) => {
    const [invoices, setInvoices] = useState([]); // Add this line
    const { showUploadModal, setShowUploadModal, uploadInvoiceType, handleOpenUploader } = useOutletContext();

    const { user, organization } = useAuth(); // Get the current user and organization
    const [analyticsData, setAnalyticsData] = useState({});

    const [suppliers, setSuppliers] = useState([]);
    const [customers, setCustomers] = useState([]); // Add this line
    const [entitiesFetched, setEntitiesFetched] = useState(false);

    const [categories, setCategories] = useState([]);
    const [loading, setLoading] = useState(true);

    const [selectedInvoice, setSelectedInvoice] = useState(null);
    
    const tableRef = useRef(null);

    const navigate = useNavigate();
    const { invoiceId } = useParams();
    
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [perPage, setPerPage] = useState(20);

    const [allCategories, setAllCategories] = useState([]);
    const [allEntities, setAllEntities] = useState([]);

    const [showViewEditModal, setShowViewEditModal] = useState(false);
    const [isViewEditModalEditing, setIsViewEditModalEditing] = useState(false);
    const [isEditing, setIsEditing] = useState(false);
    const [isUpdatingAnalytics, setIsUpdatingAnalytics] = useState(false);

    const BACKEND_API_URL = config.BACKEND_API_URL;
    const { getIdToken } = useAuth();

    const getColumns = (invoiceType) => {
        const commonColumns = [
            { id: 'fileName', label: 'Invoice Name', className: 'invoice-id', fixed: true, defaultVisible: true, sortable: true },
            { id: 'createdAt', label: 'Added', className: 'date-cell', defaultVisible: true, sortable: true },
            { id: 'status', label: 'Status', className: 'status-column', defaultVisible: true, sortable: true },
            { id: 'processedData.invoice_number', label: 'Invoice Number', className: 'invoice-number-column', defaultVisible: true, sortable: true },
            { id: 'processedData.date_of_invoice', label: 'Invoice Date', className: 'date-cell', defaultVisible: true, sortable: true },
            { id: 'processedData.currency', label: 'Currency', className: 'currency-column', defaultVisible: true, sortable: true },
            { id: 'processedData.exchange_rate', label: 'Exchange Rate', className: 'exchange-rate-column', defaultVisible: true, sortable: true },
            { id: 'processedData.total_amount_converted', label: 'Total Invoiced', className: 'td-styled total-invoiced', defaultVisible: true, sortable: true },
            { id: 'processedData.subtotal_subject_to_vat_tax_converted', label: 'Subtotal Subject to VAT', className: 'subtotal-subject-to-vat-column', defaultVisible: true, sortable: true },
            { id: 'processedData.total_vat_tax_converted', label: 'VAT Tax', className: 'td-styled', defaultVisible: true, sortable: true },
            { id: 'processedData.total_vat_tax', label: 'Total VAT Tax', className: 'total-vat-tax-column', defaultVisible: true, sortable: true },
            { id: 'processedData.vat_tax_percentage', label: 'VAT Tax Percentage', className: 'vat-tax-percentage-column', defaultVisible: true, sortable: true },
            { id: 'processedData.subtotal_not_subject_to_vat_tax_converted', label: 'Subtotal Not Subject to VAT', className: 'subtotal-not-subject-to-vat-column', defaultVisible: false, sortable: true },
            { id: 'processedData.payment_method', label: 'Payment Method', className: 'payment-method-column', defaultVisible: false, sortable: true },
            { id: 'processedData.date_paid', label: 'Date Paid', className: 'date-paid-column', defaultVisible: false, sortable: true },
            { id: 'processedData.payment_account', label: 'Payment Account', className: 'payment-account-column', defaultVisible: false, sortable: true },
            { id: 'processedData.payment_terms', label: 'Payment Terms', className: 'payment-terms-column', defaultVisible: false, sortable: true },
            { id: 'processedData.payment_deadline', label: 'Payment Deadline', className: 'payment-deadline-column', defaultVisible: false, sortable: true },
            // { id: 'url', label: 'File URL', className: 'file-url-column', defaultVisible: false, sortable: true },
        ];
    
        if (invoiceType === 'expense') {
            return [
                ...commonColumns,
                // { id: 'processedData.supplier_id', label: 'Supplier ID', className: 'supplier-id-column', defaultVisible: false, sortable: true },
                { id: 'supplierName', label: 'Supplier Name', className: 'supplier-name-column', defaultVisible: true, sortable: true },
                { id: 'supplierCategory', label: 'Category', className: 'category-column', defaultVisible: true, sortable: true },
            ];
        } else if (invoiceType === 'revenue') {
            return [
                ...commonColumns,
                // { id: 'processedData.customer_id', label: 'Customer ID', className: 'customer-id-column', defaultVisible: false, sortable: true },
                { id: 'customerName', label: 'Customer Name', className: 'customer-name-column', defaultVisible: true, sortable: true },
                { id: 'customerCategory', label: 'Category', className: 'category-column', defaultVisible: true, sortable: true },
            ];
        }
    
        return commonColumns; // Fallback to common columns if invoiceType is not matched
    };

    const columns = getColumns(invoiceType);
    // Define the sticky column ID
    const stickyColumnId = 'fileName';

    // const categoryField = invoiceType === 'expense' ? 'supplier_category' : 'customer_category';
    // const entityField = invoiceType === 'expense' ? 'supplier_full_name' : 'customer_full_name';
    
    const [filters, setFilters] = useState({
        categoryFilters: allCategories.map(cat => cat.id),
        entityFilters: allEntities.map(ent => ent.id),
        dateRange: null,
    });

    

    
    const fetchAllCategories = useCallback(async () => {
        if (!organization) return [];
        try {
            const token = await getIdToken();
            const response = await fetch(`${BACKEND_API_URL}/api/organizations/${organization.id}/minimal-categories?type=${invoiceType === 'expense' ? 'supplier' : 'customer'}`, {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            });
            if (!response.ok) throw new Error('Failed to fetch categories');
            const categories = await response.json();
            return categories;
        } catch (error) {
            console.error('Error fetching categories:', error);
            return [];
        }
    }, [organization, invoiceType, user.token, BACKEND_API_URL]);
    
    const fetchAllEntities = useCallback(async () => {
        if (!organization) return [];
        try {
            const entityType = invoiceType === 'expense' ? 'suppliers' : 'customers';
            const token = await getIdToken();
            const response = await fetch(`${BACKEND_API_URL}/api/organizations/${organization.id}/minimal-entities?type=${entityType}`, {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            });
            if (!response.ok) throw new Error(`Failed to fetch ${entityType}`);
            const entities = await response.json();
            return entities;
        } catch (error) {
            console.error(`Error fetching ${invoiceType === 'expense' ? 'suppliers' : 'customers'}:`, error);
            return [];
        }
    }, [organization, invoiceType, user.token, BACKEND_API_URL]);

    const fetchAnalyticsSummary = useCallback(async (type) => {
        // console.log(`Fetching analytics summary of type: ${type}`);
        if (!organization) return {};
        try {
            const organizationId = organization.id;
            // console.log(`Fetching ${type} summary for organization: ${organizationId}`);
    
            const analyticsSummaryRef = doc(db, 'organizations', organizationId, 'analytics', 'overall', type, 'summary');
            const analyticsSummaryDoc = await getDoc(analyticsSummaryRef);
    
            if (!analyticsSummaryDoc.exists()) {
                console.log(`No ${type} summary data found for organization: ${organizationId}`);
                return {};
            }
    
            const analyticsSummaryData = analyticsSummaryDoc.data();
            return analyticsSummaryData;
        } catch (error) {
            console.error(`Error fetching ${type} summary:`, error);
            return {};
        }
    }, [organization]);

    const fetchEntities = useCallback(async () => {
        console.log("Fetching entities");
        if (entitiesFetched) return; // If already fetched, don't fetch again
    
        const entityType = invoiceType === 'expense' ? 'suppliers' : 'customers';
        try {
            const token = await getIdToken();
            const response = await fetch(`${BACKEND_API_URL}/api/organizations/${organization.id}/entities?type=${entityType}`, {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            });
            if (!response.ok) throw new Error(`Failed to fetch ${entityType}`);
            const entities = await response.json();
            console.log(`${entityType} fetched:`, entities);
            
            if (invoiceType === 'expense') {
                setSuppliers(entities);
                console.log("Suppliers:", suppliers);
            } else {
                setCustomers(entities);
                console.log("Customers:", customers);
            }
            setEntitiesFetched(true);
        } catch (error) {
            console.error(`Error fetching ${entityType}:`, error);
        }
    }, [BACKEND_API_URL, organization.id, user.token, invoiceType, entitiesFetched]);


    const fetchInvoices = useCallback(async (page = currentPage, itemsPerPage = perPage, currentFilters = filters) => {
        if (!organization) return { invoices: [], total_pages: 1 };
        setLoading(true);
    
        try {
            const params = new URLSearchParams({
                organization_id: organization.id,
                invoice_type: invoiceType,
                page: page.toString(),
                per_page: itemsPerPage.toString(),
            });
    
            const numberOfCategories = allCategories.length;
            const numberOfSuppliers = allEntities.length;
    
            // Add category filters if some (but not all) categories are selected
            if (currentFilters.categoryFilters && currentFilters.categoryFilters.length > 0 && currentFilters.categoryFilters.length < numberOfCategories) {
                params.append('categories', currentFilters.categoryFilters.join(','));
            }
    
            // Add supplier/customer filters if some (but not all) are selected
            if (currentFilters.entityFilters && currentFilters.entityFilters.length > 0 && currentFilters.entityFilters.length < numberOfSuppliers) {
                params.append('entities', currentFilters.entityFilters.join(','));
            }
            
            // Add date range if present
            if (currentFilters.dateRange && currentFilters.dateRange.startDate && currentFilters.dateRange.endDate) {
                params.append('date_from', currentFilters.dateRange.startDate.toISOString());
                params.append('date_to', currentFilters.dateRange.endDate.toISOString());
            }
    
            const token = await getIdToken();
            const response = await fetch(`${config.BACKEND_API_URL}/api/invoices?${params}`, {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json',
                },
            });
    
            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }
    
            const data = await response.json();
    
            return {
                invoices: data.invoices || [],
                total_pages: data.total_pages || 1
            };
    
        } catch (error) {
            console.error(`Error fetching ${invoiceType} invoices:`, error);
            return { invoices: [], total_pages: 1 };
        } finally {
            setLoading(false);
        }
    }, [organization, invoiceType, user.token, allCategories, allEntities, filters, currentPage, perPage]);


    // Handle page change
    const handlePageChange = useCallback(async (newPage) => {
        setCurrentPage(newPage);
        const data = await fetchInvoices(newPage, perPage, filters);
        setInvoices(data.invoices || []);
        setTotalPages(data.total_pages || 1);
    }, [fetchInvoices, perPage, filters]);


    // Handle per page change
    const handlePerPageChange = useCallback(async (newPerPage) => {
        setPerPage(newPerPage);
        const data = await fetchInvoices(1, newPerPage, filters);
        setInvoices(data.invoices || []);
        setTotalPages(data.total_pages || 1);
        setCurrentPage(1);
    }, [fetchInvoices, filters]);


    const handleFilterChange = useCallback(async (newFilters) => {
        console.log("handleFilterChange called with:", newFilters);
        setFilters(prevFilters => {
            const updatedFilters = { ...prevFilters, ...newFilters };
            console.log("Updated filters:", updatedFilters);
            return updatedFilters;
        });
        const data = await fetchInvoices(1, perPage, { ...filters, ...newFilters });
        setInvoices(data.invoices || []);
        setTotalPages(data.total_pages || 1);
        setCurrentPage(1);
    }, [fetchInvoices, perPage, filters]);
    

    const fetchAllData = useCallback(async () => {
        if (!organization) return;
        setLoading(true);
        try {
            const [categoriesData, entitiesData, analyticsData, invoicesData] = await Promise.all([
                fetchAllCategories(),
                fetchAllEntities(),
                fetchAnalyticsSummary(invoiceType),
                fetchInvoices(1, perPage, filters),
                fetchEntities()         
            ]);
            setAllCategories(categoriesData || []);
            setAllEntities(entitiesData || []);
            setAnalyticsData(analyticsData || {});

            // Add this line to update the categories state
            setCategories(categoriesData || []);

            // Safely handle invoicesData
            if (invoicesData && typeof invoicesData === 'object') {
                setInvoices(invoicesData.invoices || []);
                setTotalPages(invoicesData.total_pages || 1);
            } else {
                setInvoices([]);
                setTotalPages(1);
                console.error("Unexpected invoicesData structure:", invoicesData);
            }

            setFilters(prev => ({
                ...prev,
                categoryFilters: categoriesData?.map(cat => cat.id) || [],
                entityFilters: entitiesData?.map(ent => ent.id) || [],
            }));
        } catch (error) {
            console.error("Error fetching data:", error);
            setAllCategories([]);
            setAllEntities([]);
            setAnalyticsData({});
            setInvoices([]);
            setTotalPages(1);
        } finally {
            setLoading(false);
        }
    }, [organization, invoiceType, perPage, fetchAllCategories, fetchAllEntities, fetchAnalyticsSummary, fetchInvoices, filters, fetchEntities]);

    const dataFetchedRef = useRef(false);

    // Handler for entity filter changes
    const handleEntityFilterChange = useCallback((entities) => {
        setFilters(prevFilters => ({
            ...prevFilters,
            entityFilters: entities
        }));
    }, []);

    // Handler for category filter changes
    const handleCategoryFilterChange = useCallback((categories) => {
        setFilters(prevFilters => ({
            ...prevFilters,
            categoryFilters: categories
        }));
    }, []);

   
    const handleDateRangeChange = useCallback((startDate, endDate) => {
        const updatedFilters = {
            ...filters,
            dateRange: { startDate, endDate }
        };
        setFilters(updatedFilters);
        fetchInvoices(1, perPage, updatedFilters);  // Reset to first page when changing date range
    }, [filters, fetchInvoices, perPage]);

    const handleFinalizeFilters = useCallback((finalFilters) => {
        setFilters(prevFilters => ({
            ...prevFilters,
            ...finalFilters
        }));
        fetchInvoices(1, perPage, { ...filters, ...finalFilters });
    }, [fetchInvoices, perPage, filters]);


    const handleInvoicesUpdate = useCallback((newInvoices) => {
        console.log(`Updating ${invoiceType} invoices...`);
        console.log("Current invoices count:", invoices.length);
        console.log("New invoices count:", newInvoices.length);

        setInvoices(prevInvoices => {
            const mergedInvoices = [...prevInvoices, ...newInvoices];
            const uniqueInvoices = Array.from(new Set(mergedInvoices.map(inv => inv.id)))
                                        .map(id => mergedInvoices.find(inv => inv.id === id));
            console.log(`Updated ${invoiceType} invoices count after merge:`, uniqueInvoices.length);

            return uniqueInvoices;
        });
    }, [invoiceType, invoices.length]);

    

    
    const triggerCSVDownload = async (downloadParams) => {
        console.log("Downloading CSV with params:", downloadParams);
        const organizationId = organization.id;
    
        const payload = {
            organizationId,
            type: invoiceType,
            columns: downloadParams.columns,
            itemIds: downloadParams.itemIds,
            filters: {
                categoryFilters: downloadParams.filters.categoryFilters,
                entityFilters: downloadParams.filters.entityFilters,
                dateRange: downloadParams.filters.dateRange
            },
            invoiceIds: downloadParams.itemIds,

        };
    
        try {
            const token = await getIdToken();
            const response = await fetch(`${BACKEND_API_URL}/download-csv`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    'Authorization': `Bearer ${token}`,
                },
                body: JSON.stringify(payload),
            });
    
            if (response.ok) {
                const blob = await response.blob();
                const url = window.URL.createObjectURL(blob);
                const a = document.createElement("a");
                a.href = url;
                a.download = `${invoiceType}_invoices.csv`;
                document.body.appendChild(a);
                a.click();
                a.remove();
            } else {
                console.error("Failed to download CSV");
                // You might want to show an error message to the user here
            }
        } catch (error) {
            console.error("Failed to download CSV", error);
            // You might want to show an error message to the user here
        }
    };

   

    const deleteInvoices = async (selectedItems, triggerId) => {
        try {
            await handleDelete(
                db, 
                organization, 
                invoiceType, 
                selectedItems, 
                BACKEND_API_URL, 
                setInvoices,
                triggerId,
                getIdToken
            );
        } catch (error) {
            console.error("Error deleting invoices:", error);
            throw error;
        }
    };

    

    const handleMove = (selectedItems) => {
        // Implement move logic here if needed
        console.log("Moving items:", selectedItems);
    };

   

    const handleCloseModal = useCallback(() => {
        console.log("Closing modal");
        setSelectedInvoice(null);
        setShowViewEditModal(false);
        navigate(`/${invoiceType}-invoices`, { replace: true });
    }, [navigate, invoiceType]);


    const sortedInvoicesRef = useRef([]);

    const handleSortedDataChange = useCallback((newSortedData) => {
        sortedInvoicesRef.current = newSortedData;
    }, []);


    const handlePrevNext = useCallback((direction) => {
        if (!selectedInvoice || sortedInvoicesRef.current.length === 0) return;

        // if (isEditing) return;  
    
        const currentIndex = sortedInvoicesRef.current.findIndex(inv => inv.id === selectedInvoice.id);
        let newIndex;
        if (direction === 'prev') {
            newIndex = (currentIndex - 1 + sortedInvoicesRef.current.length) % sortedInvoicesRef.current.length;
        } else {
            newIndex = (currentIndex + 1) % sortedInvoicesRef.current.length;
        }
        const newInvoice = sortedInvoicesRef.current[newIndex];
        setSelectedInvoice(newInvoice);
        navigate(`/${invoiceType}-invoices/${newInvoice.id}`, { replace: true });
    
        // Scroll to the new row
        if (tableRef.current) {
            tableRef.current.scrollToRow(newInvoice.id);
        }
    }, [selectedInvoice, invoiceType, navigate]);


    const handleRowClick = async (item) => {
        console.log("Row clicked:", item);
        // if (!entitiesFetched) {
        //     await fetchEntities();
        // }
        setSelectedInvoice(item);
        navigate(`/${invoiceType}-invoices/${item.id}`, { replace: true });
    };

    const handleEditModeChange = useCallback((editingMode) => {
        console.log("handleEditModeChange called in InvoicesPage:", editingMode);
        setIsEditing(editingMode);
        setIsViewEditModalEditing(editingMode);  // Add this line

        console.log("isEditing set to:", editingMode);
    }, []);
    
    




    useEffect(() => {
        setEntitiesFetched(false);
    }, [invoiceType]);

    const handleUpdateInvoice = (updatedInvoice) => {
        console.log("Updating invoice:", updatedInvoice);
        setInvoices(prevInvoices => 
            prevInvoices.map(invoice => 
                invoice.id === updatedInvoice.id ? updatedInvoice : invoice
            )
        );
        console.log("Updated invoices:", invoices);
    };

    const handleCreateInvoice = async (newInvoiceData) => {
        try {
            // const triggerId = await createAnalyticsTrigger(invoiceType, 1, 'manual-create');
            const triggerId = await createAnalyticsTrigger(db, organization, 'invoice', 1, 'manual-create');
            
            // Call your API to create the new invoice
            const token = await getIdToken();
            const response = await fetch(`${BACKEND_API_URL}/create-invoice`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
                body: JSON.stringify({
                    organizationId: organization.id,
                    invoiceType: invoiceType,
                    invoiceData: newInvoiceData,
                    triggerId: triggerId
                }),
            });
    
            if (!response.ok) {
                throw new Error('Failed to create invoice');
            }
    
            const createdInvoice = await response.json();
            
            // Update local state
            setInvoices(prev => [...prev, createdInvoice]);
            
            // Poll for analytics update
            await pollAnalyticsTriggerStatus(triggerId, getIdToken, () => {}, () => {});
            
            // Fetch updated data
            await fetchAllData();
            
            // Close the modal and navigate to the list view
            handleCloseModal();
        } catch (error) {
            console.error("Error creating invoice:", error);
            // Handle error (e.g., show error message to user)
        }
    };


    // Initial data fetch
    useEffect(() => {
        if (organization && !dataFetchedRef.current) {
            dataFetchedRef.current = true;
            fetchAllData();
        }
    }, [organization, fetchAllData]);


    useEffect(() => {
        const handleKeyDown = (event) => {
            if (selectedInvoice && !isViewEditModalEditing) {
                if (event.key === "ArrowLeft") {
                    event.preventDefault();
                    handlePrevNext('prev');
                } else if (event.key === "ArrowRight") {
                    event.preventDefault();
                    handlePrevNext('next');
                }
            }
        };
        window.addEventListener("keydown", handleKeyDown);
        return () => {
            window.removeEventListener("keydown", handleKeyDown);
        };
    }, [selectedInvoice, isViewEditModalEditing, handlePrevNext]);

    useEffect(() => {
        if (isNewInvoice) {
            setSelectedInvoice({
                isNew: true,
                processedData: {
                    items: [],
                    subtotal_subject_to_vat_tax: '0.00',
                    total_vat_tax: '0.00',
                    total_amount: '0.00',
                    subtotal_subject_to_vat_tax_converted: '0.00',
                    total_vat_tax_converted: '0.00',
                    total_amount_converted: '0.00',
                    base_currency: organization.currency,
                }
            });
            setIsEditing(true);
        } else if (invoiceId) {
            const invoice = invoices.find(inv => inv.id === invoiceId);
            if (invoice) {
                setSelectedInvoice(invoice);
            } else {
                navigate(`/${invoiceType}-invoices`, { replace: true });
            }
        } else {
            setSelectedInvoice(null);
        }
    }, [isNewInvoice, invoiceId, invoices, navigate, invoiceType, organization.currency]);

    const handleAnalyticsUpdateStart = useCallback(() => {
        console.log("Analytics update started");
        setIsUpdatingAnalytics(true);
    }, []);

    const handleAnalyticsUpdateEnd = useCallback(() => {
        console.log("Analytics update ended");
        setIsUpdatingAnalytics(false);
    }, []);



    return (
        <Routes>
            <Route path="/" element={
                <div className="invoices-page">
                    <div className="invoices-page__content">
                        <HeaderComponent 
                            className="header-component" 
                            onAddClick={() => handleOpenUploader(invoiceType)}
                            pageType="invoice"
                            entityType={invoiceType}
                        />
                        {loading ? (
                            <div className="loading-container">
                                <div className="spinner"></div>
                            </div>
                        ) : (
                            <>
                                <MetricsCardsContainer 
                                    className="metrics-cards-container" 
                                    analyticsData={analyticsData} 
                                    entities={invoiceType === 'expense' ? suppliers : customers} 
                                    categories={categories} 
                                    entityType={invoiceType} 
                                    isEntitiesPage={false}
                                    updatingData={isUpdatingAnalytics}
                                />

                                <TableComponent 
                                    ref={tableRef}
                                    className="table-component" 
                                    data={invoices}
                                    setData={setInvoices}
                                    db={db}
                                    columns={columns}
                                    stickyColumnId={stickyColumnId}
                                    onCSVDownload={triggerCSVDownload}
                                    ViewEditComponent={ViewEditInvoice}
                                    onEditModeChange={handleEditModeChange}
                                    onDelete={deleteInvoices}
                                    onMove={handleMove}
                                    categoryField={invoiceType === 'expense' ? 'supplier_category' : 'customer_category'}
                                    entityField={invoiceType === 'expense' ? 'supplier_full_name' : 'customer_full_name'}
                                    onRowClick={handleRowClick}
                                    selectedItem={selectedInvoice}
                                    onSortedDataChange={handleSortedDataChange}
                                    isInvoicePage={true}
                                    currentPage={currentPage}
                                    totalPages={totalPages}
                                    perPage={perPage} // Pass the current perPage value
                                    onPageChange={handlePageChange}
                                    onPerPageChange={handlePerPageChange}
                                    onDateRangeChange={handleDateRangeChange}
                                    onEntityFilterChange={handleEntityFilterChange}
                                    onCategoryFilterChange={handleCategoryFilterChange}
                                    entityFilters={filters.entityFilters}
                                    categoryFilters={filters.categoryFilters}
                                    initialCategoryFilters={filters.categoryFilters}
                                    initialEntityFilters={filters.entityFilters}
                                    allCategories={allCategories}
                                    allEntities={allEntities}
                                    suppliers={suppliers}
                                    customers={customers}
                                    filters={filters}
                                    onFilterChange={handleFilterChange}
                                    onFinalizeFilters={handleFinalizeFilters}
                                    onAddClick={() => handleOpenUploader(invoiceType)}
                                    onClose={handleCloseModal}
                                    isEditing={isEditing}
                                    onPrevious={() => handlePrevNext('prev')}
                                    onNext={() => handlePrevNext('next')}
                                    onUpdateInvoice={handleUpdateInvoice}
                                    organizationId={organization.id}
                                    fetchAllData={fetchAllData}
                                    pageType="invoice"
                                    invoiceType={invoiceType}
                                    createAnalyticsTrigger={createAnalyticsTrigger}
                                    pollAnalyticsTriggerStatus={pollAnalyticsTriggerStatus}
                                    onAnalyticsUpdateStart={handleAnalyticsUpdateStart}
                                    onAnalyticsUpdateEnd={handleAnalyticsUpdateEnd}
                                    organization={organization}
                                />
                            
                                {showViewEditModal && (
                                    console.log("Rendering ViewEditInvoice"),
                                    console.log("ViewEditInvoice props", {
                                        isNewInvoice,
                                        invoiceType,
                                        organizationId: organization?.id,
                                        suppliersLength: suppliers?.length,
                                        customersLength: customers?.length,
                                        allCategoriesLength: allCategories?.length,
                                        hasInvoice: !!selectedInvoice,
                                        hasOnClose: typeof handleCloseModal === 'function',
                                        hasOnPrevious: typeof handlePrevNext === 'function',
                                        hasOnNext: typeof handlePrevNext === 'function',
                                        hasOnUpdateInvoice: typeof handleUpdateInvoice === 'function' || typeof handleCreateInvoice === 'function',
                                        hasDb: !!db,
                                        hasFetchAllData: typeof fetchAllData === 'function'
                                    }),
                                    console.log("Rendering ViewEditInvoice with props:", {
                                        isEditing,
                                        onEditModeChange: handleEditModeChange
                                    }),

                                    <ViewEditInvoice
                                        invoice={isNewInvoice ? null : selectedInvoice}
                                        onClose={handleCloseModal}
                                        onPrevious={isNewInvoice ? () => {} : () => handlePrevNext('prev')}
                                        onNext={isNewInvoice ? () => {} : () => handlePrevNext('next')}
                                        invoiceType={invoiceType}
                                        onUpdateInvoice={isNewInvoice ? handleCreateInvoice : handleUpdateInvoice}
                                        organizationId={organization.id}
                                        db={db}
                                        suppliers={suppliers}
                                        customers={customers}
                                        allCategories={allCategories}
                                        isNewInvoice={isNewInvoice}
                                        fetchAllData={fetchAllData}
                                        isEditing={isEditing}
                                        onEditModeChange={handleEditModeChange}
                                    />
                                )}
                            
                            </>
                        )}
                    </div>
                    <UploadInvoicesModal
                        show={showUploadModal && uploadInvoiceType === invoiceType}
                        onClose={() => setShowUploadModal(false)}
                        user={user}
                        organization={organization}
                        storage={storage}
                        db={db}
                        onInvoicesUpdated={handleInvoicesUpdate}
                        invoiceType={invoiceType}
                        fetchAllData={fetchAllData}
                        onAnalyticsUpdateStart={handleAnalyticsUpdateStart}
                        onAnalyticsUpdateEnd={handleAnalyticsUpdateEnd}
                      
                    />
                </div>
            } />
            <Route path=":invoiceId" element={
                <div className="invoices-page">
                    <div className="invoices-page__content">
                        <HeaderComponent 
                            className="header-component" 
                            onAddClick={() => handleOpenUploader(invoiceType)} // Pass the correct invoice type
                            pageType="invoice"
                            entityType={invoiceType}
                        />
                        {loading ? (
                            <div className="loading-container">
                                <div className="spinner"></div>
                            </div>
                        ) : (
                            <>
                                <MetricsCardsContainer 
                                    className="metrics-cards-container" 
                                    analyticsData={analyticsData} 
                                    entities={invoiceType === 'expense' ? suppliers : customers} 
                                    categories={categories} 
                                    entityType={invoiceType} 
                                    isEntitiesPage={false}
                                    updatingData={isUpdatingAnalytics}
                                />

                                <TableComponent 
                                    ref={tableRef}
                                    className="table-component" 
                                    data={invoices}
                                    setData={setInvoices}
                                    db={db}
                                    columns={columns}
                                    stickyColumnId={stickyColumnId}
                                    onCSVDownload={triggerCSVDownload}
                                    ViewEditComponent={ViewEditInvoice}
                                    onDelete={deleteInvoices}
                                    onMove={handleMove}
                                    categoryField={invoiceType === 'expense' ? 'supplier_category' : 'customer_category'}
                                    entityField={invoiceType === 'expense' ? 'supplier_full_name' : 'customer_full_name'}
                                    onRowClick={handleRowClick}
                                    selectedInvoice={selectedInvoice}
                                    onSortedDataChange={handleSortedDataChange}
                                    isInvoicePage={true}
                                    currentPage={currentPage}
                                    totalPages={totalPages}
                                    perPage={perPage} // Pass the current perPage value
                                    onPageChange={handlePageChange}
                                    onPerPageChange={handlePerPageChange}
                                    allCategories={allCategories}
                                    allEntities={allEntities}
                                    suppliers={suppliers}
                                    customers={customers}
                                    filters={filters}
                                    onFilterChange={handleFilterChange}
                                    onFinalizeFilters={handleFinalizeFilters}
                                    onAddClick={() => handleOpenUploader(invoiceType)}
                                    pageType="invoice"
                                    invoiceType={invoiceType}
                                    organization={organization}
                                />
                                {selectedInvoice && (
                                    <ViewEditInvoice
                                        invoice={selectedInvoice}
                                        onClose={handleCloseModal}
                                        onPrevious={() => handlePrevNext('prev')}
                                        onNext={() => handlePrevNext('next')}
                                        invoiceType={invoiceType}
                                        onUpdateInvoice={handleUpdateInvoice}
                                        organizationId={organization.id}
                                        db={db}
                                        suppliers={suppliers}
                                        customers={customers}
                                        allCategories={allCategories}
                                        isEditing={isEditing}
                                        onEditModeChange={handleEditModeChange}
                                    />
                                )}
                            </>
                        )}
                    </div>
                    <UploadInvoicesModal
                        show={showUploadModal && uploadInvoiceType === invoiceType}
                        onClose={() => setShowUploadModal(false)}
                        user={user}
                        organization={organization}
                        storage={storage}
                        db={db}
                        onInvoicesUpdated={handleInvoicesUpdate}
                        invoiceType={invoiceType}
                        fetchAllData={fetchAllData}
                        onAnalyticsUpdateStart={handleAnalyticsUpdateStart}
                        onAnalyticsUpdateEnd={handleAnalyticsUpdateEnd}
                      
                    />
                </div>
            } />

        </Routes>
    );
};

export default InvoicesPage;