import React from 'react';
import './SimpleMetricsCard.css';


const SimpleMetricsCard = ({ title, type, value, currency }) => {
// types accepted: 'monetaryValue', 'date', 'amount'
  
  let formattedValue = value;

  if (type === 'monetaryValue' && currency) {
    formattedValue = value !== null && value !== undefined ? `${currency}${Number(value).toFixed(2)}` : 'N/A';
  
  } else if (type === 'amount') {
    formattedValue = value !== null && value !== undefined ? Number(value).toLocaleString() : 'N/A';
  }
  
  else if (type === 'date') {
    const options = { year: 'numeric', month: 'short', day: 'numeric' };
    formattedValue = new Date(value).toLocaleDateString(undefined, options);
  }

  return (
    <div className="simple-metrics-card">
      {/* <a href="#" className="metrics-view-link">View</a> */}
      <div className="metrics-content">
        <div className="metrics-title">{title}</div>
        <div className="metrics-amount">{formattedValue}</div>
      </div>
    </div>
  );
};

export default SimpleMetricsCard;

