import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from "../../../auth";
import { handleGoogleSignIn } from '../../../authUtils';
import { createUserWithEmailAndPassword } from "firebase/auth";
import { auth } from "../../../firebase";
import { db } from "../../../firebase";
import { doc, setDoc, serverTimestamp } from "firebase/firestore";
import { sendWelcomeEmail } from '../../../utils/emailUtils';
import './Signup.css';

import sumaLogo from '../../../assets/logo.svg'; // Adjust the path as needed
import googleIcon from '../../../assets/icons/google-icon.svg'; // Adjust the path as needed
import appPreviewImage from '../../../assets/images/mockup.png';


const SignUp = () => {
  const [userType, setUserType] = useState('freelance');
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const navigate = useNavigate();
  const { user, organization } = useAuth();
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);


  const handleSubmit = async (e) => {
    e.preventDefault();
    setError(null);
    setIsLoading(true);
    
    try {
      const userCredential = await createUserWithEmailAndPassword(auth, email, password);
      const newUser = userCredential.user;

      // Create user document in Firestore
      const userData = {
        createdAt: serverTimestamp(),
        updatedAt: serverTimestamp(),
        email: newUser.email,
        name: name,
        type: userType === 'accountant' ? 'Accountant' : 'User', 
        userType: userType,
        role: "Owner"
      };

      // Create user document in Firestore
      await setDoc(doc(db, "users", newUser.uid), userData);

      // Send welcome email
      await sendWelcomeEmail({
        ...userData,
        uid: newUser.uid
      });

      console.log("Welcome email sent successfully");


      navigate("/onboarding"); // Redirect to onboarding instead of create-organization
      
    } catch (error) {
      console.error("Error creating user:", error);
      if (error.code === 'auth/weak-password') {
        setError("Password should be at least 6 characters long.");
      } else if (error.code === 'auth/email-already-in-use') {
        setError("This email is already in use. Please use a different email or try logging in.");
      } else {
        setError("An error occurred while creating your account. Please try again.");
      }
      setError(error.message);
    } finally {
      setIsLoading(false);
    }
  };


  useEffect(() => {
    if (user) {
      if (!organization) {
        navigate("/create-organization");
      } else {
        navigate("/dashboard");
      }
    }
  }, [user, organization, navigate]);

  const handleGoogleSignup = async () => {
    console.log("handleGoogleSignup called");
    console.log("userType:", userType);
    setError(null);
    setIsLoading(true);
    try {
      await handleGoogleSignIn(userType);
      
      navigate("/onboarding"); // Redirect to onboarding instead of create-organization

    } catch (error) {
      console.error("Error signing in with Google:", error);
      setError("Failed to sign in with Google. Please try again.");
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="signup-container">
      <div className="signup-form">
        <img src={sumaLogo} alt="Suma Logo" className="suma-logo" />
        <div className="signup-header">
          <h1>Sign up</h1>
          <p>Start your 30-day free trial.</p>
        </div>
        <div className="user-type-selector">
          <button 
            className={userType === 'freelance' ? 'active' : ''}
            onClick={() => setUserType('freelance')}
          >
            Freelance
          </button>
          <button 
            className={userType === 'company' ? 'active' : ''}
            onClick={() => setUserType('company')}
          >
            Company
          </button>
          <button 
            className={userType === 'accountant' ? 'active' : ''}
            onClick={() => setUserType('accountant')}
          >
            Accountant
          </button>
        </div>

        <form onSubmit={handleSubmit}>
          <div className="form-group">
            <label htmlFor="name">Name</label>
            <input 
              type="text" 
              id="name" 
              placeholder="Enter your name"
              value={name}
              onChange={(e) => setName(e.target.value)}
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="email">Email</label>
            <input 
              type="email" 
              id="email" 
              placeholder="Enter your email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="password">Password</label>
            <input 
              type="password" 
              id="password" 
              placeholder="Create a password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
            />
            <p className="password-requirement">Must be at least 8 characters.</p>
          </div>
          {/* <button type="submit" className="submit-btn">Get started</button> */}
          <button type="submit" className="submit-btn" disabled={isLoading}>
            {isLoading ? 'Creating account...' : 'Get started'}
          </button>
        </form>
        {error && <p className="error-message">{error}</p>}

        <div className="google-signup">
            <button className="google-btn" onClick={handleGoogleSignup}>
            <img src={googleIcon} alt="Google Icon" />
                Sign up with Google
            </button>
        </div>

        <p className="login-link">
          Already have an account? <a href="/login">Log in</a>
        </p>
      </div>
      <div className="signup-info">
        <div className="testimonial">
            <p className="testimonial-text">Suma's AI features are a game-changer for my accounting work. The automated categorization and smart invoicing save me countless hours each month. Continuously improving the accuracy and efficiency of my financial management.</p>
            <div className="testimonial-author">
            <div className="author-info">
                <span className="author-name">— Aliah Lane</span>
                <div className="rating">★★★★★</div>
            </div>
            <span className="author-title">Accountant at Gestoría Gesys</span>
            </div>
        </div>
        <div className="app-preview">
            <img src={appPreviewImage} alt="App Preview" className="app-preview-image" />
        </div>
        </div>
    </div>
  );
};

export default SignUp;