import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { writeBatch } from 'firebase/firestore';
import { storage, db } from "../../firebase";
import { useAuth } from "../../auth";
// import { v4 as uuidv4 } from 'uuid';


import { collection, addDoc, serverTimestamp, doc, updateDoc, getDoc, setDoc } from "firebase/firestore";
import './Onboarding.css';

// Import icons as React components
import { User, Buildings, UsersThree, UploadSimple } from 'phosphor-react';

// import currencies
import { currencyCodesSymbols } from '../../utils/currencyUtils';
import { companySizeOptions, industryOptions } from '../../utils/settings_utils';

// Import the logo (adjust the path as needed)
import sumaLogo from '../../assets/logo.svg';

import { Country, State } from 'country-state-city';
import UploadInvoicesModal from '../UploadInvoicesModal/UploadInvoicesModal';

import { getDefaultPlan } from '../../utils/planUtils';

  const supplierCategories = [
    { name: "Office Supplies", slug: "office-supplies" },
    { name: "IT Services", slug: "it-services" },
    { name: "Marketing Services", slug: "marketing-services" },
    { name: "Manufacturing", slug: "manufacturing" },
    { name: "Logistics", slug: "logistics" },
    { name: "Professional Services", slug: "professional-services" },
    { name: "Utilities", slug: "utilities" },
    { name: "Maintenance", slug: "maintenance" },
    { name: "Raw Materials", slug: "raw-materials" },
    { name: "Software and Licenses", slug: "software-and-licenses" }
  ];
  
  const customerCategories = [
    { name: "Retail", slug: "retail" },
    { name: "Wholesale", slug: "wholesale" },
    { name: "E-commerce", slug: "e-commerce" },
    { name: "B2B Services", slug: "b2b-services" },
    { name: "Government", slug: "government" },
    { name: "Education", slug: "education" },
    { name: "Healthcare", slug: "healthcare" },
    { name: "Non-profit", slug: "non-profit" },
    { name: "Technology", slug: "technology" },
    { name: "Manufacturing", slug: "manufacturing" }
  ];


const Onboarding = () => {
    const [step, setStep] = useState(1);
    const [userDetails, setUserDetails] = useState({ name: '', email: '', type: '', userType: '' });
    const [organizationId, setOrganizationId] = useState(null);
    const [companyDetails, setCompanyDetails] = useState({
        name: '', address: '', country: '', state: '', city: '', zipCode: '',
        industry: '', size: '', taxNumber: '', email: '', currency: '', url: ''
    });
    const [teamMembers, setTeamMembers] = useState([{ name: '', email: '' }]);
    const navigate = useNavigate();
    const { user, setOrganization, setAuthState, getIdToken } = useAuth();
    const [errors, setErrors] = useState({});
    const [isLoading, setIsLoading] = useState(false);
    const BACKEND_API_URL = process.env.REACT_APP_BACKEND_API_URL;
    const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'success' });

    // In your component:
    const [countries, setCountries] = useState([]);
    const [states, setStates] = useState([]);

    useEffect(() => {
      const fetchOrganizationId = async () => {
        if (user) {
          const userDoc = await getDoc(doc(db, "users", user.uid));
          if (userDoc.exists() && userDoc.data().organizationId) {
            setOrganizationId(userDoc.data().organizationId);
          }
        }
      };
      fetchOrganizationId();
    }, [user]);
  
  
    useEffect(() => {
        setCountries(Country.getAllCountries());
    }, []);
    
    useEffect(() => {
    if (companyDetails.country) {
        setStates(State.getStatesOfCountry(companyDetails.country));
    }
    }, [companyDetails.country]);

    const handleCountryChange = (e) => {
        const countryCode = e.target.value;
        setCompanyDetails({
          ...companyDetails,
          country: countryCode,
          state: '' // Reset state when country changes
        });
    };
    
    const handleStateChange = (e) => {
        setCompanyDetails({
          ...companyDetails,
          state: e.target.value
        });
    };

  
  // Email validation function
  const isValidEmail = (email) => {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  };

  // URL validation function
  const isValidURL = (url) => {
    const re = /^(https?:\/\/)?([\da-z.-]+)\.([a-z.]{2,6})([/\w .-]*)*\/?$/;
    return re.test(url);
  };

  useEffect(() => {
      if (step === 2 && user && user.email) {
          setCompanyDetails(prevDetails => ({
              ...prevDetails,
              email: user.email
          }));
      }
  }, [step, user]);

  const validateStep2 = () => {
      const newErrors = {};
      if (!isValidURL(companyDetails.url)) {
          newErrors.url = 'Please enter a valid URL';
      }
      if (!isValidEmail(companyDetails.email)) {
          newErrors.email = 'Please enter a valid email address';
      }
      setErrors(newErrors);
      return Object.keys(newErrors).length === 0;
  };

  const validateStep3 = () => {
      const newErrors = {};
      teamMembers.forEach((member, index) => {
          if (member.email && !isValidEmail(member.email)) {
              newErrors[`email-${index}`] = 'Please enter a valid email address';
          }
      });
      setErrors(newErrors);
      return Object.keys(newErrors).length === 0;
  };

  
  const handleContinue = async () => {
      setIsLoading(true);
      let isValid = true;

      if (step === 2) {
        isValid = validateStep2();
      } else if (step === 3) {
          isValid = validateStep3();
      }

      if (!isValid) {
          console.log("Form is not valid");
          setIsLoading(false);
          return;
      }

      try {
        if (step === 1) {
          // Update user name in Firestore
          const userRef = doc(db, "users", user.uid);
          await updateDoc(userRef, {
            name: userDetails.name
          });
          console.log("User name updated successfully");
        }

        if (step === 2) {
          await handleOrganizationCreation();
        }
    
        if (step === 3) {
          await handleTeamInvitations();
        }
    
        if (step === 4 && userDetails.type !== 'Accountant') {
          // Handle uploading invoices (only for non-accountant users)
          // This step doesn't exist for accountants
        }
    
        if (step === steps.length) {
          // This is the last step
          await handleFinishOnboarding();
          if (userDetails.type === 'Accountant') {
            navigate('/accountant-dashboard');
          } else {
            navigate('/dashboard');
          }
        } else {
          // Move to the next step
          setStep(step + 1);
        }
      } catch (error) {
        console.error("Error in handleContinue:", error);
        // Handle error (e.g., show error message to user)
      } finally {
        setIsLoading(false);
      }
  };


  const handleOrganizationCreation = async () => {
    console.log("handleOrganizationCreation called");
    const isAccountant = userDetails.type === 'Accountant';
    console.log("User details:", userDetails);
    const collectionName = isAccountant ? "accountantOrganizations" : "organizations";
  
    // Create a new document reference without setting an ID
    const newOrgRef = doc(collection(db, collectionName));
    const newOrgId = newOrgRef.id;
  
    console.log("New organization ID:", newOrgId);

    const baseOrgData = {
      ...companyDetails,
      createdAt: serverTimestamp(),
      updatedAt: serverTimestamp(),
      members: {
        [user.uid]: {
          role: "Owner",
          type: isAccountant ? "Accountant" : "User",
          email: user.email,
          joinedAt: serverTimestamp()
        }
      }
    };
  
    if (!isAccountant) {
      const defaultPlan = getDefaultPlan();
      const currentDate = new Date();
      const currentMonthStart = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1);
      const currentMonthEnd = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0, 23, 59, 59);
      
      baseOrgData.plan = defaultPlan.name;
      baseOrgData.limits = {
        maxMonthlyAIInvoiceUploads: defaultPlan.maxMonthlyAIInvoiceUploads,
        maxSuppliersCustomers: defaultPlan.maxSuppliersCustomers,
        maxCategories: defaultPlan.maxCategories,
        maxUsers: defaultPlan.maxUsers,
        maxAssistantRequests: defaultPlan.maxAssistantRequests,
        csvDownloads: defaultPlan.csvDownloads,
        accountantAccess: defaultPlan.accountantAccess,
        automatedCurrencyConversion: defaultPlan.automatedCurrencyConversion,
        customAnalytics: defaultPlan.customAnalytics,
        notifications: defaultPlan.notifications
      };

      baseOrgData.usageMetrics = {
        currentPeriod: {
          startDate: currentMonthStart,
          endDate: currentMonthEnd,
          monthlyAIInvoiceUploads: 0,
          monthlyAssistantRequests: 0,
          monthlyCsvDownloads: 0
        },
        total: {
          totalSuppliersCustomers: 0,
          totalCategories: 0,
          totalUsers: 1
        },
        hasAccountant: false
      };
    }
      
    // Set the document data using the new reference
    await setDoc(newOrgRef, baseOrgData);
  
    // Update user document
    const userRef = doc(db, "users", user.uid);
    await updateDoc(userRef, {
      organizationId: newOrgId,
    });
  
    // Set organizationId in component state
    setOrganizationId(newOrgId);
  
    console.log(`${collectionName} created and user updated successfully!`);
    console.log("Organization ID:", newOrgId);
    console.log("User ID:", user.uid);
  
    // await addCategoriesToOrganization(newOrgId);
    if (!isAccountant) {
      await addCategoriesToOrganization(newOrgId);
    }
  };

  const addCategoriesToOrganization = async (orgId) => {
    console.log("addCategoriesToOrganization called");
    if (userDetails.type !== 'Accountant') {
      try {
        const batch = writeBatch(db);

        // Add supplier categories
        const supplierCategoriesRef = collection(db, `organizations/${orgId}/supplierCategories`);
        supplierCategories.forEach((category) => {
          const docRef = doc(supplierCategoriesRef);
          batch.set(docRef, {
            name: category.name,
            slug: category.slug,
            createdAt: serverTimestamp()
          });
        });

        // Add customer categories
        const customerCategoriesRef = collection(db, `organizations/${orgId}/customerCategories`);
        customerCategories.forEach((category) => {
          const docRef = doc(customerCategoriesRef);
          batch.set(docRef, {
            name: category.name,
            slug: category.slug,
            createdAt: serverTimestamp()
          });
        });

        // Commit the batch
        await batch.commit();
        console.log("Supplier and customer categories added successfully!");
      } catch (error) {
        console.error("Error adding categories:", error);
        throw error;
      }
    }
  };





  const handleTeamInvitations = async () => {
    try {
      const teamMembersCollection = collection(db, `organizations/${organizationId}/teamMembers`);
      const validTeamMembers = teamMembers.filter(member => member.name && member.email);
      console.log("Valid team members to invite:", validTeamMembers);
  
      const invitePromises = validTeamMembers.map(async (member) => {
        console.log("Inviting member:", member);
        try {
          const token = await getIdToken();
          const invitationType = userDetails.type === 'Accountant' ? 'client_to_accountant' : 'user_to_org';
          const inviteeRole = userDetails.type === 'Accountant' ? 'Viewer' : 'Admin'; // Default role
          
          console.log("Backend URL:", `${BACKEND_API_URL}/api/invite-user`);
          const response = await fetch(`${BACKEND_API_URL}/api/invite-user`, {
            method: 'POST',
            headers: { 
              'Authorization': `Bearer ${token}`,
              'Content-Type': 'application/json'
            },
            body: JSON.stringify({
              organizationId: organizationId,
              email: member.email,
              role: inviteeRole,
              type: userDetails.type === 'Accountant' ? 'User' : 'User',
              invitationType: invitationType
            })
          });
  
          if (!response.ok) {
            const errorData = await response.json();
            console.log("Error inviting user:", errorData);
            throw new Error(`Failed to invite ${member.email}: ${errorData.error}`);
          }
  
          console.log(`Invitation sent successfully to ${member.email}`);
          return response.json();
        } catch (error) {
          console.error(`Error inviting ${member.email}:`, error);
          throw error;
        }
      });
  
      const results = await Promise.all(invitePromises);
      console.log(`Successfully sent ${results.length} invitations.`);
  
      // Update UI or state as needed
      setSnackbar({
        open: true,
        message: `Successfully sent ${results.length} invitations.`,
        severity: 'success'
      });
  
    } catch (error) {
      console.error("Error inviting team members:", error);
      setSnackbar({
        open: true,
        message: 'Failed to send invitations. Please try again.',
        severity: 'error'
      });
    } finally {
      setIsLoading(false);
    }
  };

  

  const handleSkip = () => {
    // You might want to clear the teamMembers array or perform any other necessary actions
    setTeamMembers([]);
    // Move to the next step
    setStep(step + 1);
  };

  // Add this useEffect to fetch company details when revisiting step 2
  useEffect(() => {
    const fetchCompanyDetails = async () => {
      if (step === 2 && user && user.uid) {
        try {
          // First, check if the user has an organizationId
          const userDoc = await getDoc(doc(db, "users", user.uid));
          if (userDoc.exists() && userDoc.data().organizationId) {
            const orgId = userDoc.data().organizationId;
            const orgDoc = await getDoc(doc(db, "organizations", orgId));
            if (orgDoc.exists()) {
              const orgData = orgDoc.data();
              setCompanyDetails(prevDetails => ({
                ...prevDetails,
                ...orgData
              }));
            } else {
              console.log("Organization document does not exist yet. This is expected during onboarding.");
            }
          } else {
            console.log("User does not have an organization yet. This is expected during onboarding.");
          }
        } catch (error) {
          console.error("Error fetching company details:", error);
        }
      }
    };
  
    fetchCompanyDetails();
  }, [step, user]);

const handleFinishOnboarding = async () => {
  console.log("handleFinishOnboarding called");
  try {
    if (!user || !user.uid) {
      throw new Error("User not authenticated");
    }

    // Update user's onboarding status
    const userRef = doc(db, "users", user.uid);
    await updateDoc(userRef, {
      onboardingCompleted: true,
      onboardingCompletedAt: serverTimestamp()
    });

    console.log("User updated successfully");

    // Fetch the updated user data
    const updatedUserDoc = await getDoc(userRef);
    if (!updatedUserDoc.exists()) {
      throw new Error("User document not found");
    }
    const updatedUserData = updatedUserDoc.data();

    console.log("User data fetched successfully");

    // Fetch the organization data
    let organizationData = null;
    if (updatedUserData.organizationId) {
      const orgRef = doc(db, "organizations", updatedUserData.organizationId);
      const orgDoc = await getDoc(orgRef);
      if (orgDoc.exists()) {
        organizationData = { id: updatedUserData.organizationId, ...orgDoc.data() };
      } else {
        console.warn("Organization not found, but continuing...");
      }
    }

    console.log("Organization data fetched successfully");

    // Update the auth state
    setAuthState(prevState => ({
      ...prevState,
      user: { 
        ...user, 
        ...updatedUserData,
        name: userDetails.name,
        role: organizationData?.members[user.uid]?.role || "Owner" 
      },
      organization: organizationData,
    }));

    console.log("Onboarding completed successfully!");

    // Navigate to the appropriate dashboard
    if (updatedUserData.type === 'Accountant') {
      navigate('/accountant-dashboard');
    } else {
      navigate('/dashboard');
    }
    
    // Navigate to dashboard
    // navigate('/dashboard');
  } catch (error) {
    console.error("Error completing onboarding:", error);
    // Handle the error appropriately (e.g., show an error message to the user)
  }
};


  const handleInvoicesUpdated = (newInvoices, invoiceType) => {
    // Handle the newly uploaded invoices
    console.log(`New ${invoiceType} invoices:`, newInvoices);
    // You might want to store these in your component's state or pass them to a parent component
  };


  const [steps, setSteps] = useState([
    { name: 'Your details', description: 'Please provide your first and last name', icon: User },
    { name: 'Company details', description: 'Tell us a bit about your company', icon: Buildings },
    { name: 'Invite your team', description: 'Add team members to collaborate seamlessly', icon: UsersThree },
  ]);

  useEffect(() => {
    const fetchUserDetails = async () => {
      if (user) {
        try {
          const userDoc = await getDoc(doc(db, "users", user.uid));
          if (userDoc.exists()) {
            const userData = userDoc.data();
            const updatedUserDetails = {
              name: userData.name || '',
              email: userData.email || '',
              userType: userData?.userType || '',
              type: userData?.type || '',
            };
            setUserDetails(updatedUserDetails);
            
            // Update steps based on user type
            if (updatedUserDetails.type === 'Accountant') {
              setSteps(prevSteps => prevSteps.map((step, index) => 
                index === 2 ? 
                  { ...step, name: 'Invite your clients', description: 'Invite your clients to collaborate on their financial data' } : 
                  step
              ));
            } else {
              setSteps(prevSteps => [
                ...prevSteps,
                { name: 'Upload invoices', description: 'Leverage AI to simplify your invoice processing', icon: UploadSimple },
              ]);
            }
          }
        } catch (error) {
          console.error("Error fetching user data:", error);
        }
      } else {
        navigate("/login");
      }
    };
    
    fetchUserDetails();
  }, [user, navigate]);
  
  // useEffect(() => {
  //   console.log("Deciding if to add upload invoices step")
  //   console.log("User details:", userDetails);
  //   console.log("User type:", userDetails.type);
  //   if (userDetails.type !== 'Accountant') {
  //     console.log("Adding upload invoices step")  
  //     setSteps(prevSteps => [
  //       ...prevSteps,
  //       { name: 'Upload invoices', description: 'Leverage AI to simplify your invoice processing', icon: UploadSimple },
  //     ]);
  //   } else {
  //     console.log("Not adding upload invoices step")
  //   }

  // }, [userDetails]);



  const renderStep = () => {
    const currentStep = steps[step - 1];
  
    return (
      <div className="step-content">
        {step !== 4 && (
            <div>
            <h2>{currentStep.name}</h2>
            <p className="step-description">{currentStep.description}</p>
            </div>
        )}
        {step === 1 && (
          <div className="user-details-form">
            <div className="form-group">
              <label>Your name</label>
              <input
                type="text"
                placeholder="Name"
                value={userDetails.name}
                onChange={(e) => setUserDetails({ ...userDetails, name: e.target.value })}
              />
            </div>
            <div className="form-group">
              <label>Your email</label>
              <input
                type="email"
                placeholder="Email"
                value={userDetails.email}
                onChange={(e) => setUserDetails({ ...userDetails, email: e.target.value })}
                disabled
              />
            </div>
          </div>
        )}
  
        {step === 2 && (
        <div className="company-details-form">
          <div className="form-group">
            <label>Company Name</label>
            <input
              type="text"
              placeholder="Enter the company name"
              value={companyDetails.name}
              onChange={(e) => setCompanyDetails({...companyDetails, name: e.target.value})}
            />
          </div>

          <div className="form-group">
            <label>Company Address</label>
            <input
              type="text"
              placeholder="Street Address"
              value={companyDetails.address}
              onChange={(e) => setCompanyDetails({...companyDetails, address: e.target.value})}
            />
            <input
              type="text"
              placeholder="Street Address 2"
              value={companyDetails.address2}
              onChange={(e) => setCompanyDetails({...companyDetails, address2: e.target.value})}
            />
          </div>

          <div className="form-row">
            <div className="form-group">
              <label>Country</label>
              <select onChange={handleCountryChange}>
                {countries.map((country) => (
                  <option key={country.isoCode} value={country.isoCode}>
                    {country.name}
                  </option>
                ))}
              </select>
            </div>
            <div className="form-group">
              <label>State/Province</label>
              <select onChange={handleStateChange}>
                {states.map((state) => (
                  <option key={state.isoCode} value={state.isoCode}>
                    {state.name}
                  </option>
                ))}
              </select>
            </div>
          </div>

          <div className="form-row">
            <div className="form-group">
              <label>City</label>
              <input
                type="text"
                placeholder="City"
                value={companyDetails.city}
                onChange={(e) => setCompanyDetails({...companyDetails, city: e.target.value})}
              />
            </div>
            <div className="form-group">
              <label>Zip code</label>
              <input
                type="text"
                placeholder="Zip code"
                value={companyDetails.zipCode}
                onChange={(e) => setCompanyDetails({...companyDetails, zipCode: e.target.value})}
              />
            </div>
          </div>

          {userDetails.type === "User" && (
            <div className="form-row">
              <div className="form-group">
                <label>Industry</label>
                <select
                  value={companyDetails.industry}
                  onChange={(e) => setCompanyDetails({...companyDetails, industry: e.target.value})}
                >
                  <option value="">Select an industry</option>
                  {industryOptions.map((industry) => (
                    <option key={industry.value} value={industry.value}>
                      {industry.label}
                    </option>
                  ))}
                </select>
              </div>
              <div className="form-group">
                <label>Company Size</label>
                <select
                  value={companyDetails.size}
                  onChange={(e) => setCompanyDetails({...companyDetails, size: e.target.value})}
                >
                  <option value="">Select a company size</option>
                  {companySizeOptions.map((size) => (
                    <option key={size.value} value={size.value}>
                      {size.label}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          )}

          <div className="form-row">
            <div className="form-group">
              <label>Tax Number</label>
              <input
                type="text"
                placeholder="Enter the tax number"
                value={companyDetails.taxNumber}
                onChange={(e) => setCompanyDetails({...companyDetails, taxNumber: e.target.value})}
              />
            </div>
            <div className="form-group">
              <label>Primary Contact Email</label>
              <input
                type="email"
                placeholder="Enter the company email"
                value={companyDetails.email}
                onChange={(e) => setCompanyDetails({...companyDetails, email: e.target.value})}
              />
              {errors.email && <span className="error-message">{errors.email}</span>}
            </div>
          </div>

          <div className="form-row">
            <div className="form-group">
              <label>Base Currency</label>
              <select
                value={companyDetails.currency}
                onChange={(e) => setCompanyDetails({...companyDetails, currency: e.target.value})}
              >
                {currencyCodesSymbols.map((currency) => (
                  <option key={currency.code} value={currency.code}>
                    {currency.code} ({currency.symbol})
                  </option>
                ))}

                {/* Add more currency options here */}
              </select>
            </div>
            <div className="form-group">
              <label>Company URL</label>
              <input
                type="url"
                placeholder="Enter the company URL"
                value={companyDetails.url}
                onChange={(e) => setCompanyDetails({...companyDetails, url: e.target.value})}
              />
              {errors.url && <span className="error-message">{errors.url}</span>}
            </div>
          </div>
        </div>
      )}


        {step === 3 && (
          <div className="invite-team-form">
            {/* <h3>{userDetails.type === 'Accountant' ? 'Invite Clients' : 'Invite Team Members'}</h3> */}
            {/* <p>{userDetails.type === 'Accountant' 
              ? 'Invite your clients to collaborate on their financial data.' 
              : 'Invite team members to help manage your financial data.'}
            </p> */}
            {teamMembers.map((member, index) => (
              <div key={index} className="team-member-row">
                <div className="form-group">
                  <label>Name</label>
                  <input
                    type="text"
                    placeholder="Enter the name"
                    value={member.name}
                    onChange={(e) => {
                      const newTeamMembers = [...teamMembers];
                      newTeamMembers[index].name = e.target.value;
                      setTeamMembers(newTeamMembers);
                    }}
                  />
                </div>
                <div className="form-group">
                  <label>Email</label>
                  <input
                    type="email"
                    placeholder="Enter the email"
                    value={member.email}
                    onChange={(e) => {
                      const newTeamMembers = [...teamMembers];
                      newTeamMembers[index].email = e.target.value;
                      setTeamMembers(newTeamMembers);
                    }}
                  />
                  {errors[`email-${index}`] && <span className="error-message">{errors[`email-${index}`]}</span>}
                </div>
              </div>
            ))}

            <button
              className="add-member-button"
              onClick={() => setTeamMembers([...teamMembers, { name: '', email: '' }])}
            >
              Add {userDetails.type === 'Accountant' ? 'Client' : 'Team Member'}
            </button>
          </div>
        )}

        {step === 3 && userDetails.type !== 'Accountant' && (
            <button 
                className="skip-button" 
                onClick={handleSkip}
                disabled={isLoading}
            >
                Skip
            </button>
        )}

        {step === 4 && userDetails.type !== 'Accountant' && (

          <div className="upload-invoices-container">
            <UploadInvoicesModal
              show={true}
              onClose={() => {/* You can handle closing if needed */}}
              user={user}
              organization={{ id: organizationId }}
              storage={storage}
              db={db}
              onInvoicesUpdated={handleInvoicesUpdated}
              // onUploadComplete={handleUploadComplete}
              invoiceType="expense" // or "revenue", depending on your needs
            />
          </div>
        )}

        <button 
            className="continue-button" 
            onClick={handleContinue}
            disabled={isLoading}
          >
              {isLoading ? 'Processing...' : (step === steps.length ? 'Finish' : 'Continue')}
        </button>
        
    </div>
    );
  };

  return (
    <div className="onboarding-container">
        <img src={sumaLogo} alt="Suma Logo" className="suma-logo" />
        <div className="onboarding-sidebar">
          <div className="steps-container">
            {steps.map((item, index) => (
              <div key={index} className="step-wrapper">
                  <div 
                    key={index} 
                    className={`step-item ${step >= index + 1 ? 'active' : ''}`}
                    onClick={() => setStep(index + 1)}
                  >               
                      <div className="step-icon">
                          <item.icon size={24} weight="regular" />
                      </div>
                      <div className="step-info">
                          <h3>{item.name}</h3>
                          <p>{item.description}</p>
                      </div>
                  </div>
                  {index < steps.length - 1 && <div className="step-connector"></div>}
              </div>
              ))}
          </div>
          <div className="sidebar-footer">
            <a href="mailto:help@help.com">help@help.com</a>
            <p>© Copyright 2077</p>
          </div>
        </div>
      <div className="onboarding-content">
        {renderStep()}
        <div className="progress-dots">
            {steps.map((_, index) => (
                <div key={index} className={`dot ${step === index + 1 ? 'active' : ''}`}></div>
            ))}
        </div>

      </div>
    </div>
  );
};

export default Onboarding;
