import React, { useState, useEffect, useCallback } from 'react';
import './Dashboard.css'; // Create a CSS file for additional styles
import { storage, db } from "../../firebase";
import { useAuth } from "../../auth"; // Custom hook to get the current user
import { collection, getDocs } from "firebase/firestore"; // Import the functions you need

import HeaderComponent from '../HeaderComponent/HeaderComponent';
import DashboardMetricsContainer from '../DashboardMetricsContainer/DashboardMetricsContainer';
import UploadInvoicesModal from '../UploadInvoicesModal/UploadInvoicesModal';
import ChartComponent from '../ChartComponent/ChartComponent'; // Assume we create this new component

import config from '../../config';

const DashboardNew = () => {
    const { user, organization } = useAuth(); // Get the current user and organization
    const [loading, setLoading] = useState(true);
    const [allAnalytics, setAllAnalytics] = useState({ expense: {}, revenue: {} });
    const [dashboardMetrics, setDashboardMetrics] = useState(null);
    const [suppliers, setSuppliers] = useState([]);
    const [customers, setCustomers] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [isProcessingInvoices, setIsProcessingInvoices] = useState(false);
    const [expenseFilters, setExpenseFilters] = useState({ categories: [], suppliers: [], startDate: '2010-01-01', endDate: '2024-12-31' });
    const [revenueFilters, setRevenueFilters] = useState({ categories: [], customers: [], startDate: '2010-01-01', endDate: '2024-12-31' });
    const [customerCategories, setCustomerCategories] = useState([]);
    const [supplierCategories, setSupplierCategories] = useState([]);
    const BACKEND_API_URL = config.BACKEND_API_URL;
    const { getIdToken } = useAuth();

    const fetchCategories = useCallback(async () => {
        if (!organization) return;
        try {
            const token = await getIdToken();
            const customerResponse = await fetch(`${BACKEND_API_URL}/api/organizations/${organization.id}/minimal-categories?type=customer`, {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            });
            const supplierResponse = await fetch(`${BACKEND_API_URL}/api/organizations/${organization.id}/minimal-categories?type=supplier`, {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            });
            
            if (customerResponse.ok && supplierResponse.ok) {
                const customerCategoriesData = await customerResponse.json();
                const supplierCategoriesData = await supplierResponse.json();
                setCustomerCategories(customerCategoriesData);
                setSupplierCategories(supplierCategoriesData);
            } else {
                console.error("Error fetching categories");
            }
        } catch (error) {
            console.error("Error fetching categories:", error);
        }
    }, [organization, BACKEND_API_URL]);

    


    const fetchDashboardMetrics = useCallback(async () => {
        if (!organization) return null;

        try {
            const token = await getIdToken();
            const response = await fetch(`${BACKEND_API_URL}/get-dashboard-metrics?organizationId=${organization.id}`, {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            });
            const data = await response.json();
            
            if (response.ok) {
                console.log("Fetched dashboard metrics:", data);
                setDashboardMetrics(data); // Update the state here
                return data;
            } else {
                console.error("Error fetching dashboard metrics:", data);
                return null;
            }
        } catch (error) {
            console.error("Error in fetchDashboardMetrics:", error);
            return null;
        }
    }, [BACKEND_API_URL, organization]);

   
   
    

    const fetchFilteredAnalytics = useCallback(async (type, filters) => {
        if (!organization) return {};

        const { categories, entities, start, end } = filters;
        const entityParam = type === 'expense' ? 'suppliers' : 'customers';
        const token = await getIdToken();
        let url = `${BACKEND_API_URL}/get-analytics?organizationId=${organization.id}&analyticsType=${type}&startDate=${start}&endDate=${end}`;

        // Only add categories and entities to the URL if they're not all selected
        const allCategories = type === 'expense' ? supplierCategories.map(c => c.id) : customerCategories.map(c => c.id);
        const allEntities = type === 'expense' ? suppliers.map(s => s.id) : customers.map(c => c.id);

        if (categories.length > 0 && categories.length !== allCategories.length) {
            url += `&categories=${categories.join(',')}`;
        }
        if (entities.length > 0 && entities.length !== allEntities.length) {
            url += `&${entityParam}=${entities.join(',')}`;
        }

        try {
            const response = await fetch(url, {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            });
            const data = await response.json();
            return data;
        } catch (error) {
            console.error(`Error fetching filtered ${type} analytics:`, error);
            return {};
        }
    }, [BACKEND_API_URL, organization, supplierCategories, customerCategories, suppliers, customers]);

    const handleExpenseFilterChange = useCallback(async (newFilters) => {
        setExpenseFilters(newFilters);
        const filteredData = await fetchFilteredAnalytics('expense', newFilters);
        setAllAnalytics(prevState => ({ ...prevState, expense: filteredData }));
    }, [fetchFilteredAnalytics]);

    const handleRevenueFilterChange = useCallback(async (newFilters) => {
        setRevenueFilters(newFilters);
        const filteredData = await fetchFilteredAnalytics('revenue', newFilters);
        setAllAnalytics(prevState => ({ ...prevState, revenue: filteredData }));
    }, [fetchFilteredAnalytics]);



    const fetchAllAnalytics = useCallback(async () => {
        console.log('Fetching all analytics data...');
        if (!organization) return { expense: {}, revenue: {} };
    
        const startDate = '2010-01-01';
        const endDate = '2024-12-31';
    
        const analyticsTypes = ['expense', 'revenue'];
    
        const fetchedAnalytics = { expense: {}, revenue: {} };
    
        for (const type of analyticsTypes) {
            try {
                const token = await getIdToken();   
                const response = await fetch(`${BACKEND_API_URL}/get-analytics?organizationId=${organization.id}&analyticsType=${type}&startDate=${startDate}&endDate=${endDate}`, {
                    headers: {
                        'Authorization': `Bearer ${token}`
                    }
                });
                fetchedAnalytics[type] = await response.json();
    
                console.log(`Fetched ${type} analytics:`, fetchedAnalytics[type]);
    
            } catch (error) {
                console.error(`Error fetching ${type} analytics:`, error);
                fetchedAnalytics[type] = { error: error.message };
            }
        }
    
        return fetchedAnalytics;
    }, [organization, BACKEND_API_URL]);

    const fetchSuppliers = useCallback(async () => {
        if (!organization) return [];
        try {
            const suppliersRef = collection(db, `organizations/${organization.id}/suppliers`);
            const querySnapshot = await getDocs(suppliersRef);
            return querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
        } catch (error) {
            console.error("Error fetching suppliers:", error);
            return [];
        }
    }, [organization]);

    const fetchCustomers = useCallback(async () => {
        if (!organization) return [];
        try {
            const customersRef = collection(db, `organizations/${organization.id}/customers`);
            const querySnapshot = await getDocs(customersRef);
            return querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
        } catch (error) {
            console.error("Error fetching customers:", error);
            return [];
        }
    }, [organization]);


    const handleAddInvoice = () => {
        setShowModal(true);
    };

    


    const handleInvoicesUpdate = useCallback((newInvoices, invoiceType) => {
        console.log("New invoices uploaded:", newInvoices.length);
        console.log("Invoice type:", invoiceType);
        setIsProcessingInvoices(true);
    }, []);
    

    const fetchAllData = useCallback(async (showLoading = true) => {
        if (!organization) return;
        
        console.log('Fetching all data...');
        if (showLoading) setLoading(true);

        try {
            const [newSuppliers, newCustomers, newAnalytics] = await Promise.all([
                fetchSuppliers(),
                fetchCustomers(),
                fetchAllAnalytics(),
                fetchDashboardMetrics(),
            ]);

            setSuppliers(newSuppliers);
            setCustomers(newCustomers);
            setAllAnalytics(newAnalytics);
            // setDashboardMetrics(newMetrics);

            console.log('All data fetched successfully');
        } catch (error) {
            console.error("Error loading data:", error);
        } finally {
            if (showLoading) setLoading(false);
        }
    }, [organization, fetchDashboardMetrics, fetchAllAnalytics, fetchCustomers, fetchSuppliers])

    useEffect(() => {
        if (organization) {
            fetchAllData(true);
            fetchCategories();
        }
    }, [organization, fetchAllData, fetchCategories]);



    return (
        
        <div className="dashboard">
            {isProcessingInvoices && <div className="processing-indicator">Processing invoices...</div>}
            
        <div className="dashboard__main">
                <div className="dashboard__content">
                    <HeaderComponent 
                        className="header-component"
                        onAddClick={handleAddInvoice}
                        pageType="dashboard"
                        fetchAllData={fetchAllData}
                    />
                    {loading ? (
                        <div className="loading-container">
                            <div className="spinner"></div>
                        </div>
                    ) : (
                        <div className="dashboard__scrollable-content">
                            <DashboardMetricsContainer 
                                dashboardMetrics={dashboardMetrics || {}}
                                suppliers={suppliers}
                                customers={customers}
                            />
                            <div className="charts-container">
                                <ChartComponent 
                                    data={allAnalytics.expense || {}} 
                                    type="expense" 
                                    startDate={expenseFilters.startDate}
                                    endDate={expenseFilters.endDate}
                                    categories={supplierCategories}
                                    entities={suppliers}
                                    onFilterChange={handleExpenseFilterChange}
                                />
                                <ChartComponent 
                                    data={allAnalytics.revenue || {}}
                                    type="revenue"
                                    startDate={revenueFilters.startDate}
                                    endDate={revenueFilters.endDate}
                                    categories={customerCategories}
                                    entities={customers}
                                    onFilterChange={handleRevenueFilterChange}
                                />
                            </div>
                        </div>
                    )}
                </div>
            </div>
            {showModal && (
                <UploadInvoicesModal
                    show={showModal}
                    onClose={() => setShowModal(false)}
                    user={user}
                    organization={organization}
                    storage={storage}
                    db={db}
                    onInvoicesUpdated={handleInvoicesUpdate}
                    // onUploadComplete={handleUploadComplete}
                    invoiceType="expense"
                    fetchAllData={fetchAllData}  // Add this line

                />
            )}
        </div>
    );
};


  
  export default DashboardNew;