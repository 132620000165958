import React from 'react';
import PropTypes from 'prop-types';

import csvFileIcon from "../../assets/icons/FileCsv.svg"; // Adjust the path as needed
import './CSVDownloadButton.css'; // Create a CSS file for additional styles


const CSVDownloadButton = ({ onClick, isDownloading, showIcon, className, iconColor }) => (
    <button 
        className={`csv-download-button ${className}`} 
        onClick={onClick} 
        disabled={isDownloading}
    >
        {showIcon && (
            <img 
                src={csvFileIcon} 
                alt="CSV Icon" 
                className="csv-icon"
                style={{ filter: `brightness(0) saturate(100%) ${iconColor}` }}
            />
        )}
        {isDownloading ? "Downloading..." : "Download CSV"}
    </button>
);

CSVDownloadButton.propTypes = {
    onClick: PropTypes.func.isRequired,
    isDownloading: PropTypes.bool.isRequired,
    showIcon: PropTypes.bool,
    className: PropTypes.string,
    iconColor: PropTypes.string
};

export default CSVDownloadButton;

