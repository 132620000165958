import React, { useState, useEffect, useRef } from 'react';
import { Box, CssBaseline } from '@mui/material';
import { styled } from '@mui/material/styles';
import Navbar from '../../components/Navbar/Navbar';
import Hero from './components/Hero';
import FeaturesSection from './components/FeaturesSection';
import Features2Section from './components/Features2Section';
import TestimonialsSection from './components/TestimonialsSection';
import ContactUsSection from './components/ContactUsSection';
import Footer from '../../components/Footer/Footer';

const PageWrapper = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  minHeight: '100vh',
  overflowY: 'auto',
  overflowX: 'hidden',
});

const StyledMain = styled('main')(({ theme }) => ({
  flexGrow: 1,
  width: '100%',
  marginTop: { xs: '72px', md: '80px' },
}));

const LandingPage = () => {
  const [isScrolled, setIsScrolled] = useState(false);
  const pageWrapperRef = useRef(null);

  useEffect(() => {
    const handleScroll = () => {
      const scrollTop = pageWrapperRef.current.scrollTop;
      setIsScrolled(scrollTop > 20);
    };

    const wrapper = pageWrapperRef.current;
    wrapper.addEventListener('scroll', handleScroll);

    // Initial check
    handleScroll();

    // Cleanup
    return () => {
      wrapper.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <PageWrapper ref={pageWrapperRef}>
      <CssBaseline />
      <Navbar lightBackground={false} isScrolled={isScrolled} />
      <StyledMain>
        <Hero />
        <FeaturesSection />
        <Features2Section />
        <TestimonialsSection />
        <ContactUsSection />
      </StyledMain>
      <Footer />
    </PageWrapper>
  );
};

export default LandingPage;

