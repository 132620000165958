import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

const Snackbar = ({ open, message, severity, onClose }) => {
    useEffect(() => {
        let timer;
        if (open) {
            timer = setTimeout(() => {
                onClose();
            }, 3000);
        }
        return () => clearTimeout(timer);
    }, [open, message, onClose]);

    if (!open) return null;

    return (
        <div className={`snackbar ${severity}`}>
            {message}
            <button onClick={onClose}>×</button>
        </div>
    );
};

Snackbar.propTypes = {
    open: PropTypes.bool.isRequired,
    message: PropTypes.string.isRequired,
    severity: PropTypes.string.isRequired,
    onClose: PropTypes.func.isRequired,
};

export default Snackbar;
