import React, { useState } from 'react';
import { Box, Typography, Container, Button, styled, FormControl, Select, MenuItem, TextField } from '@mui/material';
import { Check } from '@phosphor-icons/react';
import dashboardImage from '../../../assets/images/dashboard-preview.png';

const SectionContainer = styled(Box)(({ theme }) => ({
    background: '#FFFFFF',
    padding: '96px 0',
    scrollMarginTop: '80px', // Add this to account for navbar height
    '@media (max-width: 768px)': {
      padding: '64px 0',
      scrollMarginTop: '72px', // Adjust for mobile navbar height
    },
  }));

const TopBanner = styled(Box)(({ theme }) => ({
  display: 'flex',
  padding: '64px',
  alignItems: 'center',
  justifyContent: 'space-between',
  gap: '32px',
  alignSelf: 'stretch',
  borderRadius: '16px',
  background: '#00513A',
  marginBottom: '96px',
  color: '#FFFFFF',
  '@media (max-width: 768px)': {
    flexDirection: 'column',
    padding: '32px',
    marginBottom: '64px',
    textAlign: 'center',
    gap: '24px',
  },
}));

const ContactHeader = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '16px',
  marginBottom: '48px',
  '@media (max-width: 768px)': {
    textAlign: 'center',
    marginBottom: '32px',
  },
}));

const ContactTitle = styled(Typography)({
  color: '#009A6B',
  fontSize: '16px',
  fontWeight: 600,
  lineHeight: '24px',
});

const MainHeading = styled(Typography)(({ theme }) => ({
  color: '#1C1C1C',
  fontSize: '36px',
  fontWeight: 600,
  lineHeight: '44px',
  letterSpacing: '-0.72px',
  '@media (max-width: 768px)': {
    fontSize: '28px',
    lineHeight: '36px',
  },
}));

const SubHeading = styled(Typography)(({ theme }) => ({
  color: 'rgba(28, 28, 28, 0.80)',
  fontSize: '18px',
  fontWeight: 400,
  lineHeight: '28px',
  '@media (max-width: 768px)': {
    fontSize: '16px',
    lineHeight: '24px',
  },
}));

const FormContainer = styled(Box)(({ theme }) => ({
  maxWidth: '600px',
  margin: '0 auto',
  display: 'flex',
  flexDirection: 'column',
  gap: '24px',
  '@media (max-width: 768px)': {
    gap: '20px',
  },
}));

const StyledSelect = styled(Select)(({ theme }) => ({
  '& .MuiOutlinedInput-notchedOutline': {
    borderColor: '#E4E7EC',
  },
  '&:hover .MuiOutlinedInput-notchedOutline': {
    borderColor: '#009A6B',
  },
  height: '56px',
  '@media (max-width: 768px)': {
    height: '48px',
  },
}));

const StyledTextField = styled(TextField)({
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: '#E4E7EC',
    },
    '&:hover fieldset': {
      borderColor: '#009A6B',
    },
  },
});

const SubmitButton = styled(Button)(({ theme }) => ({
  background: '#009A6B',
  color: '#FFFFFF',
  padding: '16px 28px',
  borderRadius: '8px',
  textTransform: 'none',
  fontSize: '16px',
  fontWeight: 500,
  '&:hover': {
    background: '#008559',
  },
  '@media (max-width: 768px)': {
    padding: '12px 24px',
  },
}));

const JoinSection = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  gap: '64px',
  marginTop: '96px',
  '@media (max-width: 960px)': {
    flexDirection: 'column',
    gap: '48px',
    marginTop: '64px',
    textAlign: 'center',
  },
}));

const JoinContent = styled(Box)(({ theme }) => ({
  flex: 1,
  '@media (max-width: 960px)': {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
  },
}));

const JoinTitle = styled(Typography)(({ theme }) => ({
    color: '#1C1C1C',
    fontSize: '36px',
    fontWeight: 600,
    lineHeight: '44px',
    marginBottom: '32px',
    '@media (max-width: 768px)': {
      fontSize: '28px',
      lineHeight: '36px',
      marginBottom: '24px',
      textAlign: 'left',
    },
  }));
  
  const FeatureList = styled(Box)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    gap: '16px',
    marginBottom: '32px',
    '@media (max-width: 768px)': {
      width: '100%',
      maxWidth: '400px',
      alignItems: 'flex-start',
      alignSelf: 'flex-start',
    },
  }));
  
  const CheckIconContainer = styled(Box)({
    display: 'flex',
    width: '28px',
    height: '28px',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '9999px',
    border: '1.5px solid #009A6B',
    background: '#FFFFFF',
    flexShrink: 0,
  });
  
  const FeatureItem = styled(Box)(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    gap: '12px',
    '@media (max-width: 768px)': {
      justifyContent: 'center',
      textAlign: 'left',
    },
  }));
  
  const FeatureText = styled(Typography)({
    color: 'rgba(28, 28, 28, 0.80)',
    fontSize: '16px',
    lineHeight: '24px',
  });
  
  const ButtonGroup = styled(Box)(({ theme }) => ({
    display: 'flex',
    gap: '12px',
    '@media (max-width: 768px)': {
      width: '100%',
      justifyContent: 'center',
    },
  }));
  
  const LearnMoreButton = styled(Button)(({ theme }) => ({
    padding: '10px 18px',
    borderRadius: '8px',
    border: '1px solid #E4E7EC',
    color: '#1C1C1C',
    textTransform: 'none',
    whiteSpace: 'nowrap',
    '&:hover': {
      background: '#F9FAFB',
    },
    '@media (max-width: 768px)': {
      flex: 1,
      maxWidth: '200px',
    },
  }));
  
  const GetStartedButton = styled(Button)(({ theme }) => ({
    padding: '10px 18px',
    borderRadius: '8px',
    background: '#009A6B',
    color: '#FFFFFF',
    textTransform: 'none',
    whiteSpace: 'nowrap',
    '&:hover': {
      background: '#008559',
    },
    '@media (max-width: 768px)': {
      flex: 1,
      maxWidth: '200px',
    },
  }));
  
  const DashboardImage = styled('img')(({ theme }) => ({
    maxWidth: '560px',
    width: '100%',
    height: 'auto',
    borderRadius: '12px',
    border: '6px solid #1C1C1C',
    '@media (max-width: 768px)': {
      maxWidth: '100%',
      border: '4px solid #1C1C1C',
    },
  }));
  
  const BannerButtons = styled(Box)(({ theme }) => ({
    display: 'flex',
    gap: '12px',
    '@media (max-width: 768px)': {
      width: '100%',
      justifyContent: 'center',
    },
  }));
  
  const ContactSection = () => {
    const [contactType, setContactType] = useState('');
    
    return (
        <SectionContainer id="contact-section"> {/* Add this id */}
        <Container maxWidth="lg">
          <TopBanner>
            <Box>
              <Typography variant="h4" sx={{ mb: 1 }}>Start your 30-day free trial</Typography>
              <Typography>Join over 1,000+ customers already growing with Suma.</Typography>
            </Box>
            <BannerButtons>
              <Button 
                variant="outlined" 
                sx={{ 
                  color: 'white', 
                  borderColor: 'white',
                  flex: { xs: 1, sm: 'initial' },
                  maxWidth: { xs: '160px', sm: 'initial' },
                }}
              >
                Learn more
              </Button>
              <Button 
                variant="contained" 
                sx={{ 
                  bgcolor: '#009A6B',
                  '&:hover': { bgcolor: '#008559' },
                  flex: { xs: 1, sm: 'initial' },
                  maxWidth: { xs: '160px', sm: 'initial' },
                }}
              >
                Get started
              </Button>
            </BannerButtons>
          </TopBanner>
  
          <ContactHeader>
            <ContactTitle>Contact us</ContactTitle>
            <MainHeading>We'd love to hear from you</MainHeading>
            <SubHeading>Choose how we can help you below</SubHeading>
          </ContactHeader>
  
          <FormContainer>
            <FormControl fullWidth>
              <StyledSelect
                value={contactType}
                onChange={(e) => setContactType(e.target.value)}
                displayEmpty
                placeholder="Select contact reason"
              >
                <MenuItem value="">Select contact reason</MenuItem>
                <MenuItem value="sales">Talk to Sales</MenuItem>
                <MenuItem value="support">Get Support</MenuItem>
                <MenuItem value="improvement">Suggest Improvement</MenuItem>
                <MenuItem value="bug">Report a Bug</MenuItem>
                <MenuItem value="partnership">Partnership Inquiry</MenuItem>
              </StyledSelect>
            </FormControl>
  
            <StyledTextField
              fullWidth
              label="Your Name"
              variant="outlined"
            />
  
            <StyledTextField
              fullWidth
              label="Email"
              type="email"
              variant="outlined"
            />
  
            <StyledTextField
              fullWidth
              label="Message"
              multiline
              rows={4}
              variant="outlined"
            />
  
            <SubmitButton fullWidth>
              Send message
            </SubmitButton>
          </FormContainer>
  
          <JoinSection>
            <JoinContent>
              <JoinTitle>
                Join 1,000+ customeers growing with Suma
              </JoinTitle>
              <FeatureList>
                <FeatureItem>
                  <CheckIconContainer>
                    <Check size={16} weight="bold" color="#009A6B" />
                  </CheckIconContainer>
                  <FeatureText>30-day free trial</FeatureText>
                </FeatureItem>
                <FeatureItem>
                  <CheckIconContainer>
                    <Check size={16} weight="bold" color="#009A6B" />
                  </CheckIconContainer>
                  <FeatureText>Personalized onboarding</FeatureText>
                </FeatureItem>
                <FeatureItem>
                  <CheckIconContainer>
                    <Check size={16} weight="bold" color="#009A6B" />
                  </CheckIconContainer>
                  <FeatureText>Access to all features</FeatureText>
                </FeatureItem>
              </FeatureList>
              <ButtonGroup>
                <LearnMoreButton variant="outlined">
                  Learn more
                </LearnMoreButton>
                <GetStartedButton variant="contained">
                  Get started
                </GetStartedButton>
              </ButtonGroup>
            </JoinContent>
            
            <DashboardImage 
              src={dashboardImage} 
              alt="Suma Dashboard Preview"
            />
          </JoinSection>
        </Container>
      </SectionContainer>
    );
  };
  
  export default ContactSection;
  

