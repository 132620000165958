
import React from 'react';
import EntitiesPage from '../../components/EntitiesPage/EntitiesPage';
import { useLocation } from 'react-router-dom';

const CustomersPage = () => {
  const location = useLocation();
  const isNewEntity = location.pathname.endsWith('/new');
  
  console.log('CustomersPage - isNewEntity:', isNewEntity);
  console.log('CustomersPage - Current path:', location.pathname);

  return <EntitiesPage entityType="customer" isNewEntity={isNewEntity} />;
};

export default CustomersPage;

