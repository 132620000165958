


export const handleDelete = async (db, organization, invoiceType, selectedItems, BACKEND_API_URL, setInvoices, triggerId, getIdToken) => {
    try {
        const token = await getIdToken();
        const response = await fetch(`${BACKEND_API_URL}/delete-invoices`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            body: JSON.stringify({
                organizationId: organization.id,
                invoiceType,
                invoiceIds: selectedItems,
                triggerId
            }),
        });

        if (!response.ok) {
            throw new Error('Failed to delete invoices');
        }

        // Update local state
        setInvoices(prevInvoices => prevInvoices.filter(invoice => !selectedItems.includes(invoice.id)));

        console.log('Invoices deleted successfully');
    } catch (error) {
        console.error('Error deleting invoices:', error);
        throw error;
    }
};
