import { collection, addDoc, serverTimestamp, doc, runTransaction } from 'firebase/firestore';
import config from '../config';
const BACKEND_API_URL = config.BACKEND_API_URL;



export const createAnalyticsTrigger = async (db, organization, changeType, totalExpectedItems, triggerType) => {
    console.log("Creating analytics trigger");
    console.log("organization:", organization);
    console.log("changeType:", changeType);
    console.log("totalExpectedItems:", totalExpectedItems);
    console.log("triggerType:", triggerType);
    
    const validChangeTypes = ['invoice', 'entity', 'category'];
    if (!validChangeTypes.includes(changeType)) {
        throw new Error(`Invalid change type: ${changeType}. Must be one of ${validChangeTypes.join(', ')}`);
    }

    try {
        const triggerRef = await addDoc(collection(db, 'analyticsTriggers'), {
            organizationId: organization.id,
            changeType,  // 'invoice', 'entity', or 'category'
            triggerType, // 'add', 'edit', 'delete'
            totalExpectedItems,
            affectedItems: {},
            status_action: 'pending',
            status_analytics_update: 'pending',
            createdAt: serverTimestamp(),
            updatedAt: serverTimestamp()
        });

        console.log('Analytics trigger created successfully:', triggerRef.id);
        return triggerRef.id;
    } catch (error) {
        console.error('Error creating analytics trigger:', error);
        throw error;
    }
};

export const addItemToAnalyticsTrigger = async (db, triggerId, item) => {
    try {
        const triggerRef = doc(db, 'analyticsTriggers', triggerId);
        
        await runTransaction(db, async (transaction) => {
            const triggerDoc = await transaction.get(triggerRef);
            if (!triggerDoc.exists()) {
                throw new Error("AnalyticsTrigger document does not exist!");
            }
            
            const triggerData = triggerDoc.data();
            
            triggerData.affectedItems[item.id] = { 
                status: 'pending',
                type: item.type,
                createdAt: serverTimestamp()
            };
            
            transaction.update(triggerRef, { 
                affectedItems: triggerData.affectedItems,
                updatedAt: serverTimestamp()
            });
        });
        
        console.log(`Successfully added item ${item.id} to analytics trigger ${triggerId}`);
    } catch (error) {
        console.error("Error adding item to analytics trigger:", error);
        throw error;
    }
};

export const pollAnalyticsTriggerStatus = async (
    triggerId,
    getIdToken,
    {
        onActionCompleted,
        onAnalyticsCompleted,
        onAnalyticsError,
        onAnalyticsUpdateStart,
        onPollingStart,
        onPollingEnd
    } = {}
) => {
    console.log("Polling analytics trigger status for trigger ID:", triggerId);
    if (!triggerId) {
        console.error("Invalid trigger ID");
        return Promise.reject(new Error("Invalid trigger ID"));
    }

    if (onPollingStart) {
        onPollingStart();
    }

    return new Promise((resolve, reject) => {
        let actionCompleted = false;
        const pollInterval = setInterval(async () => {
            try {
                console.log(`Checking status for trigger ${triggerId}`);
                const token = await getIdToken();
                const response = await fetch(`${BACKEND_API_URL}/check-analytics-trigger/${triggerId}`, {
                    headers: {
                        'Authorization': `Bearer ${token}`
                    }
                });
                if (!response.ok) {
                    throw new Error(`HTTP error! status: ${response.status}`);
                }
                const data = await response.json();
                console.log("Received trigger status:", data);

                if (data.status_action === 'completed' && !actionCompleted) {
                    console.log("Action completed, starting analytics update");
                    actionCompleted = true;
                    if (onActionCompleted) {
                        onActionCompleted(data);
                    }
                    if (onAnalyticsUpdateStart) {
                        onAnalyticsUpdateStart(data);
                    }
                }

                if (data.status_action === 'completed' && data.status_analytics_update === 'completed') {
                    console.log("Analytics update completed:", data.message);
                    clearInterval(pollInterval);
                    if (onAnalyticsCompleted) {
                        await onAnalyticsCompleted(data);
                    }
                    if (onPollingEnd) {
                        onPollingEnd('completed');
                    }
                    resolve(data);
                } else if (data.status_action === 'failed' || data.status_analytics_update === 'failed') {
                    console.log("Analytics update failed:", data.message);
                    clearInterval(pollInterval);
                    if (onAnalyticsError) {
                        onAnalyticsError(data.message);
                    }
                    if (onPollingEnd) {
                        onPollingEnd('failed');
                    }
                    reject(new Error(data.message));
                }
            } catch (error) {
                console.error("Error checking analytics trigger status:", error);
                clearInterval(pollInterval);
                if (onAnalyticsError) {
                    onAnalyticsError(error.message);
                }
                if (onPollingEnd) {
                    onPollingEnd('error');
                }
                reject(error);
            }
        }, 5000); // Poll every 5 seconds
    });
};


export const triggerAnalyticsGeneration = async (organizationId, analyticsType, token) => {
    try {
      const response = await fetch(`${BACKEND_API_URL}/generate-analytics`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify({ organizationId, analyticsType }),
      });
  
      if (!response.ok) {
        throw new Error('Failed to trigger analytics generation');
      }
  
      console.log("Analytics generation triggered successfully");
    } catch (error) {
      console.error("Error triggering analytics generation:", error);
      throw error;
    }
  };
  
  export const updateAnalyticsTrigger = async (db, triggerId, itemId, status) => {
    try {
        const triggerRef = doc(db, 'analyticsTriggers', triggerId);
        await runTransaction(db, async (transaction) => {
            const triggerDoc = await transaction.get(triggerRef);
            if (!triggerDoc.exists()) {
                throw new Error("AnalyticsTrigger document does not exist!");
            }
            
            const triggerData = triggerDoc.data();
            
            if (!triggerData.affectedItems) {
                throw new Error("AffectedItems field is missing in the trigger document!");
            }
            
            if (!triggerData.affectedItems[itemId]) {
                throw new Error(`Item ${itemId} is not present in the trigger's affected items!`);
            }
            
            triggerData.affectedItems[itemId].status = status;
            triggerData.affectedItems[itemId].updatedAt = serverTimestamp();
            
            transaction.update(triggerRef, { 
                affectedItems: triggerData.affectedItems,
                updatedAt: serverTimestamp()
            });
        });
        
        console.log(`Successfully updated analytics trigger ${triggerId} for item ${itemId}`);
    } catch (error) {
        console.error("Error updating analytics trigger:", error);
        throw error;
    }
};
