import React from 'react';
import { useNavigate } from 'react-router-dom';  // Add this import at the top
import { Box, Container, Typography, Button, styled } from '@mui/material';
import { Check } from '@phosphor-icons/react';

const SectionContainer = styled(Box)(({ theme }) => ({
  padding: '96px 0',
  background: '#FFFFFF',
  marginTop: '48px',
  [theme.breakpoints.down('md')]: {
    padding: '64px 0',
  },
  [theme.breakpoints.down('sm')]: {
    padding: '48px 0',
  }
}));

const PricingHeader = styled(Box)(({ theme }) => ({
  textAlign: 'center',
  marginBottom: '64px',
  [theme.breakpoints.down('md')]: {
    marginBottom: '48px',
  }
}));

const SmallTitle = styled(Typography)({
  color: '#009A6B',
  fontSize: '16px',
  fontWeight: 600,
  lineHeight: '24px',
  marginBottom: '12px',
});

const MainTitle = styled(Typography)(({ theme }) => ({
  color: '#1C1C1C',
  fontSize: '48px',
  fontWeight: 600,
  lineHeight: '60px',
  letterSpacing: '-0.96px',
  marginBottom: '16px',
  [theme.breakpoints.down('md')]: {
    fontSize: '36px',
    lineHeight: '44px',
  },
  [theme.breakpoints.down('sm')]: {
    fontSize: '28px',
    lineHeight: '36px',
  }
}));

const Subtitle = styled(Typography)(({ theme }) => ({
  color: 'rgba(28, 28, 28, 0.60)',
  fontSize: '18px',
  lineHeight: '28px',
  [theme.breakpoints.down('sm')]: {
    fontSize: '16px',
    lineHeight: '24px',
  }
}));

const PlansContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  gap: '24px',
  justifyContent: 'center',
  flexWrap: 'wrap',
  maxWidth: '1200px',
  margin: '0 auto',
  [theme.breakpoints.down('md')]: {
    gap: '16px',
  },
  [theme.breakpoints.down('sm')]: {
    flexDirection: 'column',
    alignItems: 'center',
  }
}));

const PlanCard = styled(Box)(({ isSelected, theme }) => ({
  flex: 1,
  minWidth: '280px',
  maxWidth: '320px',
  padding: '32px',
  borderRadius: '16px',
  border: `1px solid ${isSelected ? '#009A6B' : '#E4E7EC'}`,
  background: '#FFFFFF',
  position: 'relative',
  [theme.breakpoints.down('sm')]: {
    maxWidth: '100%',
    width: '100%',
    padding: '24px',
  }
}));

const PopularBadge = styled(Box)({
  display: 'inline-flex',
  padding: '4px 12px',
  borderRadius: '16px',
  background: '#E6F5E9',
  color: '#009A6B',
  fontSize: '14px',
  fontWeight: 500,
  marginLeft: '12px',
});

const CheckIconContainer = styled(Box)({
  display: 'flex',
  width: '28px',
  height: '28px',
  justifyContent: 'center',
  alignItems: 'center',
  borderRadius: '9999px',
  border: '1.5px solid #009A6B',
  background: '#FFFFFF',
});

const PlanHeader = styled(Box)(({ theme }) => ({
  marginBottom: '32px',
  [theme.breakpoints.down('sm')]: {
    marginBottom: '24px',
  }
}));

const FeaturesList = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  gap: '16px',
  marginTop: '32px',
});

const FeatureItem = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  gap: '12px',
});

const EnterpriseCTA = styled(Box)(({ theme }) => ({
  textAlign: 'center',
  marginTop: '64px',
  padding: '32px',
  borderRadius: '16px',
  background: '#F9FAFB',
  maxWidth: '768px',
  margin: '64px auto 0',
  [theme.breakpoints.down('md')]: {
    padding: '24px',
    marginTop: '48px',
  },
  [theme.breakpoints.down('sm')]: {
    padding: '20px',
    marginTop: '32px',
  }
}));

const PricingSection = () => {
    const navigate = useNavigate();  // Add this hook

    const handleContactSalesClick = () => {
        // If we're not on homepage, navigate first
        if (window.location.pathname !== '/') {
        navigate('/');
        // Wait for navigation to complete before scrolling
        setTimeout(() => {
            const contactSection = document.getElementById('contact-section');
            if (contactSection) {
            contactSection.scrollIntoView({ behavior: 'smooth' });
            }
        }, 100);
        } else {
        // If we're already on homepage, just scroll
        const contactSection = document.getElementById('contact-section');
        if (contactSection) {
            contactSection.scrollIntoView({ behavior: 'smooth' });
        }
        }
    };

  const plans = [
    {
      name: "Basic plan",
      price: "0",
      description: "Free forever",
      features: [
        "1 user",
        "Up to 20 AI processed invoices per month",
        "Up to 50 customers/suppliers",
      ],
    },
    {
      name: "Basic plan",
      price: "29.99",
      description: "Perfect for small businesses",
      features: [
        "Up to 2 users",
        "500 AI processed invoices per month",
        "Up to 500 customers/suppliers",
      ],
    },
    {
      name: "Business plan",
      price: "49.99",
      description: "Best for growing teams",
      isPopular: true,
      isSelected: true,
      features: [
        "Up to 10 users",
        "1000 AI processed invoices per month",
        "Up to 1000 customers/suppliers",
      ],
    },
  ];

  return (
    <SectionContainer>
      <Container maxWidth="lg">
        <PricingHeader>
          <SmallTitle>Pricing</SmallTitle>
          <MainTitle>Simple, transparent pricing</MainTitle>
          <Subtitle>
            We believe Suma should be accessible to all companies, no matter the size.
          </Subtitle>
        </PricingHeader>

        <PlansContainer>
          {plans.map((plan, index) => (
            <PlanCard key={index} isSelected={plan.isSelected}>
              <PlanHeader>
                <Box sx={{ display: 'flex', alignItems: 'center', mb: 3 }}>
                  <Typography variant="h5" fontWeight={600}>
                    {plan.name}
                    {plan.isPopular && <PopularBadge>Popular</PopularBadge>}
                  </Typography>
                </Box>
                <Box sx={{ display: 'flex', alignItems: 'baseline', mb: 1 }}>
                  <Typography variant="h3" fontWeight="600">${plan.price}</Typography>
                  <Typography sx={{ ml: 1, color: 'text.secondary' }}>per month</Typography>
                </Box>
                <Typography sx={{ color: 'text.secondary', mb: 3 }}>
                  {plan.description}
                </Typography>
              </PlanHeader>

              <Button
                fullWidth
                variant={plan.isSelected ? "contained" : "outlined"}
                sx={{
                  mb: 2,
                  py: 1.5,
                  bgcolor: plan.isSelected ? '#009A6B' : 'transparent',
                  '&:hover': {
                    bgcolor: plan.isSelected ? '#008559' : 'rgba(0, 154, 107, 0.04)',
                  },
                }}
              >
                {plan.isSelected ? 'Current plan' : 'Get started'}
              </Button>

              <Typography fontWeight={600} sx={{ mb: 2 }}>FEATURES</Typography>
              <FeaturesList>
                {plan.features.map((feature, idx) => (
                  <FeatureItem key={idx}>
                    <CheckIconContainer>
                      <Check size={16} weight="bold" color="#009A6B" />
                    </CheckIconContainer>
                    <Typography color="text.secondary">
                      {feature}
                    </Typography>
                  </FeatureItem>
                ))}
              </FeaturesList>
            </PlanCard>
          ))}
        </PlansContainer>

        <EnterpriseCTA>
          <Typography variant="h4" sx={{ mb: 2, fontWeight: 600 }}>
            Enterprise Plan
          </Typography>
          <Typography sx={{ mb: 3, color: 'text.secondary' }}>
            Need a custom plan for your enterprise? Let's chat about creating a tailored solution for your business.
          </Typography>
          <Button
            variant="contained"
            onClick={handleContactSalesClick}
            sx={{
              bgcolor: '#009A6B',
              '&:hover': {
                bgcolor: '#008559',
              },
              py: 1.5,
              px: 4,
            }}
          >
            Contact Sales
          </Button>
        </EnterpriseCTA>

      </Container>
    </SectionContainer>
  );
};

export default PricingSection;