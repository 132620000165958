import React from 'react';
import EntitiesPage from '../../components/EntitiesPage/EntitiesPage';
import { useLocation } from 'react-router-dom';

const SuppliersPage = () => {
  const location = useLocation();
  const isNewEntity = location.pathname.endsWith('/new');
  
  console.log('SuppliersPage - isNewEntity:', isNewEntity);
  console.log('SuppliersPage - Current path:', location.pathname);

  return <EntitiesPage entityType="supplier" isNewEntity={isNewEntity} />;
};

export default SuppliersPage;
