
import { doc, getDoc } from 'firebase/firestore';


export const currencyCodesSymbols = [
    { code: 'USD', symbol: '$' },
    { code: 'EUR', symbol: '€' },
    { code: 'GBP', symbol: '£' },
    { code: 'JPY', symbol: '¥' },
    { code: 'CAD', symbol: 'C$' },
    { code: 'AUD', symbol: 'A$' },
    { code: 'CHF', symbol: 'Fr' },
    { code: 'CNY', symbol: '¥' },
    { code: 'SEK', symbol: 'kr' },
    { code: 'NZD', symbol: 'NZ$' },
    { code: 'MXN', symbol: '$' },
    { code: 'SGD', symbol: 'S$' },
    { code: 'HKD', symbol: 'HK$' },
    { code: 'NOK', symbol: 'kr' },
    { code: 'KRW', symbol: '₩' },
    { code: 'TRY', symbol: '₺' },
    { code: 'RUB', symbol: '₽' },
    { code: 'INR', symbol: '₹' },
    { code: 'BRL', symbol: 'R$' },
    { code: 'ZAR', symbol: 'R' },
    { code: 'PLN', symbol: 'zł' },
    { code: 'THB', symbol: '฿' },
    { code: 'IDR', symbol: 'Rp' },
    { code: 'HUF', symbol: 'Ft' },
    { code: 'CZK', symbol: 'Kč' },
    { code: 'ILS', symbol: '₪' },
    { code: 'CLP', symbol: '$' },
    { code: 'PHP', symbol: '₱' },
    { code: 'AED', symbol: 'د.إ' },
    { code: 'COP', symbol: '$' },
    { code: 'SAR', symbol: '﷼' },
    { code: 'MYR', symbol: 'RM' },
    { code: 'RON', symbol: 'lei' },
];



export const fetchBaseCurrency = async (db, organizationId) => {
    try {
        const organizationRef = doc(db, 'organizations', organizationId);
        const organizationDoc = await getDoc(organizationRef);

        if (organizationDoc.exists()) {
            const organizationData = organizationDoc.data();
            return organizationData.currency || 'USD'; // Default to USD if not set
        } else {
            console.error('Organization document not found');
            return 'USD'; // Default to USD if organization not found
        }
    } catch (error) {
        console.error('Error fetching base currency:', error);
        return 'USD'; // Default to USD in case of error
    }
};
