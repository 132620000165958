import React, { useRef, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import './ViewEditEntity.css';

import CloseIcon from '../../assets/icons/Close.svg';
import ArrowLineLeft from '../../assets/icons/ArrowLineLeft.svg';
import ArrowLineRight from '../../assets/icons/ArrowLineRight.svg';
import Typography from '@mui/material/Typography';
import SimpleMetricsCard from '../SimpleMetricsCard/SimpleMetricsCard';

import { Country, State } from 'country-state-city';
import { useAuth } from "../../auth";

import { createAnalyticsTrigger, pollAnalyticsTriggerStatus } from '../../utils/analyticsUtils';
import config from '../../config';
import Snackbar from '../Snackbar/Snackbar';

const ViewEditEntity = ({ 
        entity, 
        updateEntity,
        organization,
        onClose, 
        onPrevious, 
        onNext, 
        entityType, 
        hasPrevious, 
        hasNext, 
        onEntityUpdated,
        onDataRefetch,
        categories,
        db,
        isEditing,
        setIsEditing
    }) => {
    const modalRef = useRef(null);
    const [editableEntity, setEditableEntity] = useState({ ...entity });
    // const [isEditing, setIsEditing] = useState(false);
    const [isSaving, setIsSaving] = useState(false);
    const [countries, setCountries] = useState([]);

    const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'success' });
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

    const BACKEND_API_URL = config.BACKEND_API_URL;
    const { getIdToken } = useAuth();

    console.log("I'm in view edit entity");
    console.log("categories:", categories);
    console.log("Is new entity:", entity.isNew);
    // console log the last invoice date
    console.log("lastInvoiceDate:", entity.lastInvoiceDate);

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 768);
        };

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);
    

    useEffect(() => {
        setEditableEntity({ ...entity });
        setIsEditing(entity.isNew || false);
    }, [entity]);

    useEffect(() => {
        const fetchedCountries = Country.getAllCountries();
        setCountries(fetchedCountries);
    }, []);
    
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (modalRef.current && !modalRef.current.contains(event.target)) {
                onClose();
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [onClose]);

    const handleInputChange = (field, value) => {
        setEditableEntity(prev => ({ ...prev, [field]: value }));
    };

    const renderField = (label, fieldName, value, type = 'text', customHandler = null, className = '') => {
        const displayValue = value === null || value === '' ? 'N/A' : value;
        const formattedValue = fieldName === 'lastInvoiceDate' && displayValue !== 'N/A' 
            ? new Date(displayValue).toLocaleDateString()
            : displayValue;
    
        if (isEditing || (value !== null && value !== '')) {
            return (
                <div className={`entity-info ${className}`}>
                    {isEditing && <label>{label}:</label>}
                    {isEditing ? (
                        fieldName === 'categoryId' ? (
                            <select
                                name={fieldName}
                                value={editableEntity[fieldName] || ''}
                                onChange={(e) => handleInputChange(fieldName, e.target.value)}
                            >
                                <option value="">Select a category</option>
                                {categories.map((category) => (
                                    <option key={category.id} value={category.id}>
                                        {category.name}
                                    </option>
                                ))}
                            </select>
                        ) : fieldName === 'country' ? (
                            <select
                                className="select-country"
                                name={fieldName}
                                value={editableEntity[fieldName] || ''}
                                onChange={(e) => handleInputChange(fieldName, e.target.value)}
                            >
                                <option value="">Select a country</option>
                                {countries.map((country) => (
                                    <option key={country.isoCode} value={country.name}>
                                        {country.name}
                                    </option>
                                ))}
                            </select>
                        ) : (
                            <input
                                type={type}
                                name={fieldName}
                                value={editableEntity[fieldName] || ''}
                                onChange={customHandler || ((e) => handleInputChange(fieldName, e.target.value))}
                                step={type === 'number' ? '0.01' : undefined}
                                className={className}
                                placeholder={label}
                            />
                        )
                    ) : (
                        <p className={className}>{formattedValue}</p>
                    )}
                </div>
            );
        }
        return null;
    };
  
    const renderEntityDetails = () => {
        return (
            <div className="supplier-details-section">
                <Typography variant="body1" className="entity-type-title">{entityType} Details</Typography>
                {renderField(`${entityType} Name`, 'name', editableEntity.name, 'text', null, 'entity-name-section')}
                {renderField('Tax Number', 'taxNumber', editableEntity.taxNumber, 'text', null, 'entity-details-section')}
                {renderField('Address Line 1', 'address_line_1', editableEntity.address_line_1, 'text', null, 'entity-details-section')}
                {renderField('Address Line 2', 'address_line_2', editableEntity.address_line_2, 'text', null, 'entity-details-section')}
                {renderField('City', 'city', editableEntity.city, 'text', null, 'entity-details-section')}
                {renderField('Postal Code', 'postalCode', editableEntity.postalCode, 'text', null, 'entity-details-section')}
                {renderField('Country', 'country', editableEntity.country, 'text', null, 'entity-details-section')}
                {renderField('Email', 'email', editableEntity.email, 'email', null, 'entity-details-section')}
                {renderField('Phone', 'phone', editableEntity.phone, 'tel', null, 'entity-details-section')}
                {renderField('Category', 'categoryId', categories.find(cat => cat.id === editableEntity.categoryId)?.name || 'Unknown', 'text', null, 'entity-category-section')}
            </div>
        );
    };

    const handleEditClick = () => {
        setIsEditing(true);
    };

    const handleCancelEdit = () => {
        if (entity.isNew) {
            onClose();
        } else {
            setIsEditing(false);
            setEditableEntity({ ...entity });
        }
    };

    const handleSaveEdit = async () => {
        setIsSaving(true);
        try {
            const triggerId = await createAnalyticsTrigger(
                db, 
                organization, 
                'entity', 
                1, 
                entity.isNew ? 'create' : 'edit'
            );
            
            let updatedEntity;
            if (entity.isNew) {
                // Create new entity
                const token = await getIdToken();
                const response = await fetch(`${BACKEND_API_URL}/add-entity`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${token}`,
                    },
                    body: JSON.stringify({
                        organizationId: organization.id,
                        entityType: entityType.toLowerCase(),
                        entityData: editableEntity,
                        triggerId: triggerId
                    }),
                });
    
                if (!response.ok) {
                    throw new Error('Failed to create entity');
                }
    
                const result = await response.json();
                updatedEntity = { ...editableEntity, id: result.entityId };
            } else {
                // Update existing entity
                updatedEntity = await updateEntity(
                    organization.id,
                    editableEntity.id,
                    entityType.toLowerCase(),
                    editableEntity,
                    triggerId
                );
            }
    
            // Start polling for analytics update completion
            await pollAnalyticsTriggerStatus(
                BACKEND_API_URL,
                triggerId,
                () => {
                    console.log("Entity update/create action completed");
                },
                () => {
                    console.log("Analytics update completed");
                    onEntityUpdated(updatedEntity);
                    onDataRefetch(); // Call the refetch function here
                }
            );
    
            setSnackbar({ open: true, message: `Entity ${entity.isNew ? 'created' : 'updated'} successfully`, severity: 'success' });
            setIsEditing(false);
            onClose();
        } catch (error) {
            console.error(`Failed to ${entity.isNew ? 'create' : 'update'} entity:`, error);
            setSnackbar({ open: true, message: `Failed to ${entity.isNew ? 'create' : 'update'} entity`, severity: 'error' });
        } finally {
            setIsSaving(false);
            setIsEditing(false);
            setIsSaving(false);
            onClose();
        }
    };
    


    const formatDate = (dateValue) => {
        console.log("Raw lastInvoiceDate:", dateValue);
        if (!dateValue) return 'N/A';
        
        try {
            if (dateValue.seconds) {
                // Firestore Timestamp
                return new Date(dateValue.seconds * 1000).toISOString();
            } else if (dateValue instanceof Date) {
                // JavaScript Date object
                return dateValue.toISOString();
            } else if (typeof dateValue === 'number') {
                // Timestamp in milliseconds
                return new Date(dateValue).toISOString();
            } else if (typeof dateValue === 'string') {
                // Already a string, assume it's properly formatted
                return dateValue;
            }
        } catch (error) {
            console.error("Error formatting date:", error);
            return 'Invalid Date';
        }
        
        return 'Invalid Date';
    };




return (
    <div className={`view-edit-entity-modal ${isEditing ? 'editing' : ''}`}>
        <div className="view-edit-entity-content" ref={modalRef}>
            <div className="entity-header">
                <div className="entity-header-left">
                    <div className="entity-header-info">
                        <h2>{entity.isNew ? `New ${entityType}` : `${entityType} Details`}</h2>
                        {!entity.isNew && <p className="entity-name">{editableEntity.name}</p>}
                    </div>                
                </div>
                
                <div className="entity-header-right">
                    {!isEditing && !entity.isNew && (
                        <>
                            <button className="prev-button" onClick={onPrevious} disabled={!hasPrevious}>
                                <img src={ArrowLineLeft} alt="Arrow left" className="icon" />
                                Previous
                            </button>
                            <button className="next-button" onClick={onNext} disabled={!hasNext}>
                                Next
                                <img src={ArrowLineRight} alt="Arrow right" className="icon" />  
                            </button>
                        </>
                    )}
                    <button className="close-button" onClick={onClose}>
                        <img src={CloseIcon} alt="Close icon" className="icon" />
                    </button>
                </div>                  
            </div>

            <div className="entity-body">
                <div className="entity-details-edit-section">
                    {renderEntityDetails()}
                    {!isEditing && !entity.isNew && (
                        <button className="edit-button" onClick={handleEditClick}>Edit {entityType.toLowerCase()}</button>
                    )}
                </div>

                {!entity.isNew && (
                    <div className="entity-metrics-section">
                        <SimpleMetricsCard 
                            title="Total Amount" 
                            type="monetaryValue" 
                            value={entity.totalAmount} 
                            currency="€" 
                        />
                        <SimpleMetricsCard 
                            title="Invoice Count" 
                            type="amount" 
                            value={entity.invoiceCount} 
                        />
                        <SimpleMetricsCard 
                            title="Last Invoice Date" 
                            type="date" 
                            value={formatDate(entity.lastInvoiceDate)} 
                        />                    
                    </div>
                )}
            </div>

            <div className="entity-footer">
                {isEditing && (
                    <div className="entity-footer">
                        <div className="edit-actions">
                            <button className="cancel-button" onClick={handleCancelEdit} disabled={isSaving}>
                                {entity.isNew ? 'Close' : 'Cancel'}
                            </button>
                            <button className="save-button" onClick={handleSaveEdit} disabled={isSaving}>
                                {isSaving ? 'Saving...' : (entity.isNew ? 'Create' : 'Save')}
                            </button>
                        </div>
                    </div>
                )}
            </div>
        </div>
        <Snackbar
            open={snackbar.open}
            message={snackbar.message}
            severity={snackbar.severity}
        />
    </div>
);
};

ViewEditEntity.propTypes = {
entity: PropTypes.object.isRequired,
updateEntity: PropTypes.func.isRequired,
organization: PropTypes.object.isRequired,
onClose: PropTypes.func.isRequired,
onPrevious: PropTypes.func.isRequired,
onNext: PropTypes.func.isRequired,
entityType: PropTypes.oneOf(['Supplier', 'Customer']).isRequired,
hasPrevious: PropTypes.bool.isRequired,
hasNext: PropTypes.bool.isRequired,
onEntityUpdated: PropTypes.func.isRequired,
onDataRefetch: PropTypes.func.isRequired,
};

export default ViewEditEntity;