import React from 'react';
import CategoryPage from '../../components/CategoryPage/CategoryPage';
import { useLocation } from 'react-router-dom';

const SupplierCategoriesPage = () => {
  const location = useLocation();
  const isNewCategory = location.pathname.endsWith('/new');
  
  console.log('SupplierCategoriesPage - isNewCategory:', isNewCategory);
  console.log('SupplierCategoriesPage - Current path:', location.pathname);

  return <CategoryPage categoryType="supplier" isNewCategory={isNewCategory} />;
};

export default SupplierCategoriesPage;
