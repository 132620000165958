import React from 'react';
import { Box, Typography, Button, Container, styled } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import dashboardImage from '../../../assets/images/dashboard-preview.png';
import macbookFrame from '../../../assets/images/MacBook Pro 16.png';
import cameraImage from '../../../assets/images/camera.png';

const MainHeading = styled(Typography)(({ theme }) => ({
  color: '#1C1C1C',
  textAlign: 'center',
  fontFamily: 'Inter',
  fontSize: '48px',
  fontWeight: 600,
  lineHeight: '60px',
  letterSpacing: '-1.2px',
  maxWidth: '1000px',
  margin: '0 auto',
  padding: '0 24px',
  marginTop: '32px',

  [theme.breakpoints.down('md')]: {
    fontSize: '32px',
    lineHeight: '40px',
    letterSpacing: '-0.72px',
    maxWidth: '100%',
    padding: '0 16px',
    marginTop: '48px', // Increased from 32px to 64px
  },
}));

const SubHeading = styled(Typography)(({ theme }) => ({
  color: 'rgba(28, 28, 28, 0.60)',
  textAlign: 'center',
  fontFamily: 'Inter',
  fontSize: '18px',
  fontWeight: 400,
  lineHeight: '28px',
  maxWidth: '600px',
  margin: '0 auto',
  padding: '0 24px',

  [theme.breakpoints.down('md')]: {
    fontSize: '16px',
    lineHeight: '24px',
    padding: '0 24px',
    color: 'rgba(28, 28, 28, 0.60)',
  },
}));


const SignUpButton = styled(Button)(({ theme }) => ({
  display: 'flex',
  padding: '16px 22px',
  justifyContent: 'center',
  alignItems: 'center',
  gap: '10px',
  borderRadius: '10px',
  backgroundColor: '#009A6B',
  color: 'white',
  textTransform: 'none',
  fontSize: '16px',
  fontWeight: 500,
  width: '200px',
  
  '&:hover': {
    backgroundColor: '#008559',
  },

  [theme.breakpoints.down('md')]: {
    width: 'calc(100% - 48px)', // Full width minus padding
    maxWidth: '400px',
    margin: '0 24px',
    padding: '16px',
  },
}));


const MacBookContainer = styled(Box)(({ theme }) => ({
  position: 'relative',
  width: '1193.32px',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  flexShrink: 0,

  [theme.breakpoints.down('lg')]: {
    width: '100%',
    maxWidth: '900px',
  },
  [theme.breakpoints.down('md')]: {
    width: '100%',
    margin: '0 24px',
    '& img': {
      width: '100%',
      borderRadius: '12px',
      border: '6px solid #1C1C1C',
    }
  },
}));




const MacBookFrame = styled(Box)(({ theme }) => ({
  position: 'absolute',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
  background: `url(${macbookFrame}) 0px 0px / 100% 100% no-repeat`,
  flexShrink: 0,

  [theme.breakpoints.down('sm')]: {
    // Instead of hiding, we'll keep it visible
    background: 'none', // Remove background image
    border: '6px solid #1C1C1C', // Add border similar to other images
    borderRadius: '12px',
    top: '15px', // Adjust position to account for the border
    left: '15px',
    right: '15px',
    width: 'auto',
    height: 'calc(100% - 30px)', // Adjust for top and bottom border
  },
}));

const Camera = styled(Box)(({ theme }) => ({
  position: 'absolute',
  top: '7px',
  left: '50%',
  transform: 'translateX(-50%)',
  width: '5.667px',
  height: '5.667px',
  flexShrink: 0,
  background: `url(${cameraImage}) 0px 0px / 100% 100% no-repeat`,
  zIndex: 2,

  [theme.breakpoints.down('sm')]: {
    display: 'none', // Hide camera on mobile
  },
}));

const DashboardContainer = styled(Box)(({ theme }) => ({
  position: 'relative',
  width: '82.5%',
  maxWidth: '1020px',
  marginTop: '15px',
  marginLeft: 'auto',
  marginRight: 'auto',
  zIndex: 1,

  [theme.breakpoints.down('sm')]: {
    width: '100%',
    marginTop: '15px', // Keep consistent spacing
    padding: '0 15px', // Add padding to account for frame border
  },
}));

const DashboardImage = styled('img')(({ theme }) => ({
  width: '100%',
  height: 'auto',
  display: 'block',
  borderRadius: '12px',
  objectFit: 'cover',

  [theme.breakpoints.down('md')]: {
    border: '6px solid #1C1C1C',
    borderRadius: '12px',
    margin: '0 auto',
  },
}));


const HeroContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  paddingTop: '96px',
  flexDirection: 'column',
  alignItems: 'center',
  gap: '48px',
  alignSelf: 'stretch',
  position: 'relative',
  overflow: 'hidden',

  [theme.breakpoints.down('md')]: {
    paddingTop: '40px',
    gap: '32px',
  },
}));


const BackgroundPattern = styled(Box)(({ theme }) => ({
  width: '1920px',
  height: '1440px',
  position: 'absolute',
  left: '50%',
  transform: 'translateX(-50%)',
  top: 0,
  zIndex: 0,

  [theme.breakpoints.down('md')]: {
    width: '960px', // Half the desktop width
    height: '720px', // Half the desktop height
    top: '-100px', // Adjust position from top
  },
  [theme.breakpoints.down('sm')]: {
    width: '480px', // Quarter of desktop width
    height: '360px', // Quarter of desktop height
  }
}));


const BlocksContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  width: '1920px',
  height: '1440px',
  alignItems: 'flex-start',
  alignContent: 'flex-start',
  flexShrink: 0,
  flexWrap: 'wrap',
  border: '1px solid rgba(28, 28, 28, 0.05)',

  [theme.breakpoints.down('md')]: {
    width: '960px',
    height: '720px',
  },
  [theme.breakpoints.down('sm')]: {
    width: '480px',
    height: '360px',
  }
}));


const Block = styled(Box)(({ theme }) => ({
  width: '96px',
  height: '96px',
  flexShrink: 0,
  border: '1px solid rgba(28, 28, 28, 0.05)',
  transform: 'translateX(0)',

  [theme.breakpoints.down('md')]: {
    width: '48px',
    height: '48px',
    transform: 'translateX(25%)', // Move blocks right to center them
  },
  [theme.breakpoints.down('sm')]: {
    width: '24px',
    height: '24px',
    transform: 'translateX(50%)', // Move blocks right more on mobile
  }
}));

const BackgroundMask = styled(Box)(({ theme }) => ({
  position: 'absolute',
  top: 0,
  left: '50%',
  transform: 'translateX(-50%)',
  width: '1440px',
  height: '1440px',
  background: 'radial-gradient(52.19% 100% at 50% 0%, #000 0%, rgba(0, 0, 0, 0.00) 95.31%)',
  opacity: 0.03,
  pointerEvents: 'none',
  zIndex: 1,

  [theme.breakpoints.down('md')]: {
    display: 'none', // Hide mask on mobile
  },
}));

const Divider = styled(Box)(({ theme }) => ({
  width: '1216px',
  height: '1px',
  backgroundColor: '#E4E7EC',
  marginTop: '-1px',

  [theme.breakpoints.down('lg')]: {
    width: '100%',
  },
  [theme.breakpoints.down('sm')]: {
    display: 'none', // Hide divider on mobile
  },
}));

const HeroSection = styled(Box)(({ theme }) => ({
  position: 'relative',
  width: '100%',
  overflow: 'visible',
  backgroundColor: '#FFFFFF',
  zIndex: 1,
  scrollMarginTop: '80px', // Add this to account for navbar height
  
  [theme.breakpoints.down('sm')]: {
    overflow: 'hidden',
    scrollMarginTop: '72px', // Adjust for mobile navbar height
  },
}));


const Hero = () => {
  const navigate = useNavigate();
  
  // Calculate the exact number of blocks needed
  const ROWS = 15;
  const COLS = 20;
  const totalBlocks = ROWS * COLS;

  // Function to calculate block visibility and style
  // / Function to calculate block visibility and style
  const getBlockStyle = (index) => {
    const row = Math.floor(index / COLS);
    const col = index % COLS;
    const centerCol = COLS / 2;
    const distanceFromCenter = Math.sqrt(
      Math.pow(col - centerCol, 2) + Math.pow(row, 2)
    );
    const opacity = Math.max(0.05, 1 - (distanceFromCenter * 0.1));
    
    return {
      borderRight: '1px solid rgba(28, 28, 28, 0.05)',
      borderBottom: '1px solid rgba(28, 28, 28, 0.05)',
      opacity,
    };
  };


  return (
    <HeroSection id="hero-section">
      <BackgroundPattern>
        <BlocksContainer>
          {Array.from({ length: totalBlocks }).map((_, index) => (
            <Block 
              key={index}
              style={getBlockStyle(index)}
            />
          ))}
        </BlocksContainer>
      </BackgroundPattern>

      <BackgroundMask />
      
      <Container maxWidth="lg" sx={{ position: 'relative', zIndex: 2 }}>
        <HeroContainer>
          <Box sx={{ 
            display: 'flex', 
            flexDirection: 'column', 
            gap: { xs: '16px', md: '24px' }, 
            alignItems: 'center',
            width: '100%',
            px: { xs: 0, md: 0 },
            mt: { xs: 4, md: 0 }
          }}>
            <MainHeading>
              A new way to manage your invoices
            </MainHeading>
            <SubHeading>
              Save time, reduce errors, and gain financial insights with our advanced platform
            </SubHeading>
            <SignUpButton 
              onClick={() => navigate('/signup')}
            >
              Sign up
            </SignUpButton>
          </Box>

          <Box
            sx={{
              width: '100%',
              display: 'flex',
              justifyContent: 'center',
              px: { xs: 2, md: 0 },
              position: 'relative',
            }}
          >
            <MacBookContainer>
              <MacBookFrame />
              <Camera />
              <DashboardContainer>
                <DashboardImage
                  src={dashboardImage}
                  alt="Suma Dashboard Preview"
                  loading="lazy"
                />
              </DashboardContainer>
              <Divider />
            </MacBookContainer>
          </Box>
        </HeroContainer>
      </Container>
    </HeroSection>
  );
};

export default Hero;