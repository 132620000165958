import React from "react";
import MetricsCard from "../MetricsCard/MetricsCard";
import PropTypes from "prop-types";

import "./MetricsCardsContainer.css";

const MetricsCardsContainer = ({ analyticsData, entities, categories, entityType, isEntitiesPage, updatingData, accountantAnalytics }) => {
    if (!analyticsData && !accountantAnalytics) {
        return <div className="metrics-cards-container">No data available</div>;
    }

    const capitalizeWords = (str) => {
        return str.replace(/\b\w+/g, word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase());
    };


    if (accountantAnalytics) {
        // Render accountant-specific metrics
        return (
            <div className="metrics-cards-container">
                <MetricsCard 
                    title="Total Clients"
                    value={String(accountantAnalytics.total_clients)}
                    updatingData={updatingData}
                />
                <MetricsCard 
                    title="Avg. Invoice Amount"
                    value={`€${accountantAnalytics.avg_inv_amount.toFixed(2)}`}
                    updatingData={updatingData}
                />
                <MetricsCard 
                    title="Top Client"
                    value={capitalizeWords(accountantAnalytics.top_client)}
                    updatingData={updatingData}
                />
                <MetricsCard 
                    title="Most Recent Update"
                    value={capitalizeWords(accountantAnalytics.most_recent_update)}
                    updatingData={updatingData}
                />
            </div>
        );
    }
    

    const isExpense = entityType === 'expense';
    const topEntityId = isExpense ? analyticsData.topSupplier?.supplier_id : analyticsData.topCustomer?.customer_id;
    const entitiesArray = Array.isArray(entities) ? entities : Object.values(entities);
    const topEntity = entitiesArray.find(entity => entity.id === topEntityId);
    const topEntityName = topEntity ? capitalizeWords(topEntity.name) : "N/A";
    const totalAmount = isExpense ? (analyticsData.totalExpenses ?? 0) : (analyticsData.totalRevenue ?? 0);
    const topEntityTotalAmount = isExpense
        ? (analyticsData.topSupplier?.totalExpenses ?? 0)
        : (analyticsData.topCustomer?.totalRevenue ?? 0);

    return (
        <div className="metrics-cards-container">
            
            {analyticsData && (
                <>
                    <MetricsCard 
                        title={isEntitiesPage ? `Total ${isExpense ? 'Expenses' : 'Revenue'}` : "Total Invoices"}
                        value={isEntitiesPage ? `€${totalAmount.toFixed(2)}` : String(analyticsData.totalInvoices ?? 0)}
                        updatingData={updatingData}
                    />
                    <MetricsCard 
                        title={`Total ${isExpense ? 'Suppliers' : 'Customers'}`} 
                        value={String(entities.length)}
                        updatingData={updatingData}
                    />
                    <MetricsCard 
                        title={`Top ${isExpense ? 'Supplier' : 'Customer'}`} 
                        value={topEntityName} 
                        percentage={`€${topEntityTotalAmount.toFixed(2)}`} 
                        updatingData={updatingData}
                    />
                    {isEntitiesPage ? (
                        <MetricsCard 
                            title="Total Categories" 
                            value={String(categories.length)}
                            updatingData={updatingData}
                        />
                    ) : (
                        <MetricsCard 
                            title="Top Category" 
                            value={capitalizeWords(categories.find(cat => cat.id === analyticsData.topCategory?.category_id)?.name ?? "N/A")}
                            percentage={`€${(analyticsData.topCategory?.totalSpent ?? 0).toFixed(2)}`} 
                            updatingData={updatingData}
                        />
                    )}
                </>
            )}
        </div>
    );
};

MetricsCardsContainer.propTypes = {
    analyticsData: PropTypes.shape({
        totalInvoices: PropTypes.number,
        totalExpenses: PropTypes.number,
        totalRevenue: PropTypes.number,
        topSupplier: PropTypes.shape({
            supplier_id: PropTypes.string,
            totalExpenses: PropTypes.number,
        }),
        topCustomer: PropTypes.shape({
            customer_id: PropTypes.string,
            totalRevenue: PropTypes.number,
        }),
        topCategory: PropTypes.shape({
            category_id: PropTypes.string,
            totalSpent: PropTypes.number,
        }),
    }),
    entities: PropTypes.oneOfType([
        PropTypes.arrayOf(
            PropTypes.shape({
                id: PropTypes.string.isRequired,
                name: PropTypes.string.isRequired,
            })
        ),
        PropTypes.object
    ]).isRequired,
    categories: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.string.isRequired,
            name: PropTypes.string.isRequired,
        })
    ).isRequired,
    entityType: PropTypes.oneOf(['expense', 'revenue']).isRequired,
    isEntitiesPage: PropTypes.bool.isRequired,
    updatingData: PropTypes.bool.isRequired,
};

export default MetricsCardsContainer;
