import React, { useEffect } from "react";
import MetricsCard from "../MetricsCard/MetricsCard";
import PropTypes from "prop-types";
import "./DashboardMetricsContainer.css";

const DashboardMetricsContainer = ({ dashboardMetrics, suppliers, customers }) => {
    useEffect(() => {
        console.log("DashboardMetricsContainer received metrics:", dashboardMetrics);
        console.log("Suppliers:", suppliers);
        console.log("Customers:", customers);
    }, [dashboardMetrics, suppliers, customers]);

    if (!dashboardMetrics) {
        console.log("No dashboard metrics available");
        return <div className="dashboard-metrics-container">No data available</div>;
    }

    const {
        totalRevenue = 0,
        totalExpenses = 0,
        profitAndLoss = 0,
        topCustomer = {},
        topSupplier = {}
    } = dashboardMetrics;

    // Ensure values are numbers
    const numTotalRevenue = Number(totalRevenue);
    const numTotalExpenses = Number(totalExpenses);
    const numProfitAndLoss = Number(profitAndLoss);

    const getEntityName = (entities, id) => {
        const entity = entities.find(e => e.id === id);
        return entity ? entity.name : id || "N/A";
    };

    const topSupplierName = getEntityName(suppliers, topSupplier?.supplier_id);
    const topCustomerName = getEntityName(customers, topCustomer?.customer_id);

    console.log("Calculated values:", {
        numTotalRevenue,
        numTotalExpenses,
        numProfitAndLoss,
        topSupplierName,
        topCustomerName
    });

    return (
        <div className="dashboard-metrics-container">
            <MetricsCard 
                title="Total Revenue" 
                value={`€${numTotalRevenue.toFixed(2)}`}
                trend={numTotalRevenue > 0 ? "up" : "down"}
                percentage={`${((numTotalRevenue / (numTotalRevenue + numTotalExpenses)) * 100 || 0).toFixed(1)}%`}
            />
            <MetricsCard 
                title="Total Expenses" 
                value={`€${numTotalExpenses.toFixed(2)}`}
                trend={numTotalExpenses > 0 ? "up" : "down"}
                percentage={`${((numTotalExpenses / (numTotalRevenue + numTotalExpenses)) * 100 || 0).toFixed(1)}%`}
            />
            <MetricsCard 
                title="Top Customer" 
                value={topCustomerName}
                subtitle={`€${(Number(topCustomer?.totalRevenue) || 0).toFixed(2)}`}
                percentage={`${((Number(topCustomer?.totalRevenue) / numTotalRevenue) * 100 || 0).toFixed(1)}%`}
                trend="up"
            />
            <MetricsCard 
                title="Top Supplier" 
                value={topSupplierName}
                subtitle={`€${(Number(topSupplier?.totalExpenses) || 0).toFixed(2)}`}
                percentage={`${((Number(topSupplier?.totalExpenses) / numTotalExpenses) * 100 || 0).toFixed(1)}%`}
                trend="up"
            />
        </div>
    );
};

DashboardMetricsContainer.propTypes = {
    dashboardMetrics: PropTypes.shape({
        totalRevenue: PropTypes.number,
        totalExpenses: PropTypes.number,
        profitAndLoss: PropTypes.number,
        topCustomer: PropTypes.shape({
            customer_id: PropTypes.string,
            totalRevenue: PropTypes.number,
        }),
        topSupplier: PropTypes.shape({
            supplier_id: PropTypes.string,
            totalExpenses: PropTypes.number,
        }),
    }),
    suppliers: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
    })).isRequired,
    customers: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
    })).isRequired,
};

export default DashboardMetricsContainer;
