export const plans = {
    free: {
      name: 'Free',
      maxMonthlyAIInvoiceUploads: 20,
      maxSuppliersCustomers: 20,
      maxCategories: 20,
      maxUsers: 1,
      maxAssistantRequests: 2,
      csvDownloads: 1,
      accountantAccess: false,
      automatedCurrencyConversion: true,
      customAnalytics: false,
      notifications: true
    },
    // Add other plans (e.g., 'basic', 'pro') as needed
  };
  
  export const getDefaultPlan = () => plans.free;
  
  export const isPlanActionAllowed = (planName, action, value) => {
    const plan = plans[planName];
    if (!plan) return false;
  
    switch (action) {
      case 'uploadInvoice':
        return value <= plan.maxMonthlyAIInvoiceUploads;
      case 'addSupplierCustomer':
        return value <= plan.maxSuppliersCustomers;
      // Add other checks as needed
      default:
        return false;
    }
  };


