import React from 'react';
import { Box, Typography, Container, Button, styled } from '@mui/material';
import { ArrowRight } from '@phosphor-icons/react';
import settingsImage from '../../../assets/images/settings_image.png';

const SectionContainer = styled(Box)({
  background: '#FFFFFF',
});

const TopBanner = styled(Box)({
  display: 'flex',
  padding: '64px',
  alignItems: 'center',
  justifyContent: 'space-between',
  gap: '32px',
  alignSelf: 'stretch',
  borderRadius: '16px',
  background: '#F9FAFC',
  marginBottom: '96px',
  '@media (max-width: 768px)': {
    padding: '32px 24px',
    marginBottom: '64px',
  },
  '@media (max-width: 600px)': {
    flexDirection: 'column',
    alignItems: 'flex-start',
    gap: '24px',
  },
});

const BannerText = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  gap: '4px',
  flex: '1',
});

const BannerTitle = styled(Typography)({
  color: '#1C1C1C',
  fontFamily: 'Inter',
  fontSize: '24px',
  fontWeight: 600,
  lineHeight: '32px',
  '@media (max-width: 768px)': {
    fontSize: '20px',
    lineHeight: '28px',
  },
});

const BannerSubtitle = styled(Typography)({
  color: 'rgba(28, 28, 28, 0.80)',
  fontFamily: 'Inter',
  fontSize: '16px',
  fontWeight: 400,
  lineHeight: '24px',
});

const BannerButtons = styled(Box)({
  display: 'flex',
  gap: '12px',
  '@media (max-width: 600px)': {
    width: '100%',
    flexDirection: 'column',
  },
});

const LearnMoreButton = styled(Button)({
  padding: '10px 18px',
  borderRadius: '8px',
  border: '1px solid #E4E7EC',
  background: '#FFFFFF',
  color: '#1C1C1C',
  textTransform: 'none',
  '&:hover': {
    background: '#F9FAFB',
  },
  '@media (max-width: 600px)': {
    width: '100%',
    padding: '12px 18px',
  },
});

const GetStartedButton = styled(Button)({
  padding: '10px 18px',
  borderRadius: '8px',
  background: '#009A6B',
  color: '#FFFFFF',
  textTransform: 'none',
  '&:hover': {
    background: '#008559',
  },
  '@media (max-width: 600px)': {
    width: '100%',
    padding: '12px 18px',
  },
});

const ContentContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  gap: '80px',
  alignItems: 'center',
  '@media (max-width: 960px)': {
    flexDirection: 'column',
    gap: '48px',
    textAlign: 'center',
  },
}));

const LeftContent = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '48px',
  flex: 1,
  '@media (max-width: 960px)': {
    alignItems: 'center',
    width: '100%',
    gap: '32px',
  },
}));

const HeaderContent = styled(Box)(({ theme }) => ({
  '@media (max-width: 960px)': {
    textAlign: 'center',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: '12px',
    padding: '0 16px',
  },
}));

const SectionTitle = styled(Typography)({
  color: '#009A6B',
  fontSize: '16px',
  fontWeight: 600,
  lineHeight: '24px',
});

const MainHeading = styled(Typography)({
  color: '#1C1C1C',
  fontSize: '36px',
  fontWeight: 600,
  lineHeight: '44px',
  letterSpacing: '-0.72px',
  '@media (max-width: 768px)': {
    fontSize: '28px',
    lineHeight: '36px',
  },
});

const SubHeading = styled(Typography)({
  color: 'rgba(28, 28, 28, 0.80)',
  fontSize: '18px',
  fontWeight: 400,
  lineHeight: '28px',
  '@media (max-width: 768px)': {
    fontSize: '16px',
    lineHeight: '24px',
  },
});

const BusinessTypeList = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '32px',
  '@media (max-width: 960px)': {
    width: '100%',
    alignItems: 'center',
    gap: '40px',
    padding: '0 16px',
  },
}));

const BusinessType = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '8px',
  '@media (max-width: 960px)': {
    alignItems: 'center',
    maxWidth: '400px',
    width: '100%',
    gap: '12px',
  },
}));

const BusinessTitle = styled(Typography)({
  color: '#1C1C1C',
  fontSize: '20px',
  fontWeight: 600,
  lineHeight: '30px',
});

const BusinessDescription = styled(Typography)({
  color: 'rgba(28, 28, 28, 0.80)',
  fontSize: '16px',
  fontWeight: 400,
  lineHeight: '24px',
});

const LearnMoreLink = styled(Button)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: '8px',
  padding: '8px 0',
  color: '#009A6B',
  textTransform: 'none',
  fontSize: '14px',
  fontWeight: 600,
  '&:hover': {
    background: 'transparent',
    opacity: 0.8,
  },
  '@media (max-width: 960px)': {
    justifyContent: 'center',
    padding: '12px 0',
    width: '100%',
  },
}));

const RightContent = styled(Box)(({ theme }) => ({
  flex: 1,
  '@media (max-width: 960px)': {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    padding: '0 16px',
  },
}));

const SettingsImage = styled('img')(({ theme }) => ({
  width: '100%',
  maxWidth: '560px',
  height: 'auto',
  borderRadius: '12px',
  border: '6px solid #1C1C1C',
  '@media (max-width: 960px)': {
    maxWidth: '100%',
    border: '4px solid #1C1C1C',
  },
}));

// Component remains exactly the same as in the original
const Features2Section = () => {
  return (
    <SectionContainer>
      <Container maxWidth="lg">
        <TopBanner>
          <BannerText>
            <BannerTitle>Start your 30-day free trial</BannerTitle>
            <BannerSubtitle>Join over 1,000+ customers already growing with Suma.</BannerSubtitle>
          </BannerText>
          <BannerButtons>
            <LearnMoreButton variant="outlined">Learn more</LearnMoreButton>
            <GetStartedButton variant="contained">Get started</GetStartedButton>
          </BannerButtons>
        </TopBanner>

        <ContentContainer>
          <LeftContent>
            <HeaderContent>
              <SectionTitle>Features</SectionTitle>
              <MainHeading>Tailored to Your Business</MainHeading>
              <SubHeading>No matter your industry or size, we adapt to your needs.</SubHeading>
            </HeaderContent>
            
            <BusinessTypeList>
              <BusinessType>
                <BusinessTitle>Small Businesses</BusinessTitle>
                <BusinessDescription>Improve client management and optimize daily operations.</BusinessDescription>
                <LearnMoreLink>
                  Learn more
                  <ArrowRight size={16} weight="bold" />
                </LearnMoreLink>
              </BusinessType>

              <BusinessType>
                <BusinessTitle>Freelancers</BusinessTitle>
                <BusinessDescription>Save time on invoicing and expense tracking.</BusinessDescription>
                <LearnMoreLink>
                  Learn more
                  <ArrowRight size={16} weight="bold" />
                </LearnMoreLink>
              </BusinessType>

              <BusinessType>
                <BusinessTitle>Accountants</BusinessTitle>
                <BusinessDescription>Manage multiple clients with ease.</BusinessDescription>
                <LearnMoreLink>
                  Learn more
                  <ArrowRight size={16} weight="bold" />
                </LearnMoreLink>
              </BusinessType>
            </BusinessTypeList>
          </LeftContent>

          <RightContent>
            <SettingsImage src={settingsImage} alt="Settings Interface" />
          </RightContent>
        </ContentContainer>
      </Container>
    </SectionContainer>
  );
};

export default Features2Section;
