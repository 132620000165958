import React, { useState, useEffect, useCallback, useRef } from 'react';
import PropTypes from 'prop-types';
import { useAuth } from "../../auth";
import { doc, getDoc } from "firebase/firestore";
import { db } from "../../firebase";
import { format } from 'date-fns'; // Make sure to import this
import { useNavigate, useParams, useLocation } from 'react-router-dom';

import { createAnalyticsTrigger, pollAnalyticsTriggerStatus } from '../../utils/analyticsUtils';

import HeaderComponent from '../HeaderComponent/HeaderComponent';
import MetricsCardsContainer from '../MetricsCardsContainer/MetricsCardsContainer';
import TableComponent from '../TableComponent/TableComponent';
import ViewEditEntity from '../ViewEditEntity/ViewEditEntity';

import './EntitiesPage.css';

const EntitiesPage = ({ entityType, isNewEntity }) => {
    const [entities, setEntities] = useState([]);
    const [loading, setLoading] = useState(true);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [perPage, setPerPage] = useState(20);

    const [analyticsData, setAnalyticsData] = useState({});
    const [categories, setCategories] = useState([]);
    const [selectedEntity, setSelectedEntity] = useState(null);
    const [sortedEntities, setSortedEntities] = useState([]);
    const [currentEntityIndex, setCurrentEntityIndex] = useState(-1);
    const [dataFetched, setDataFetched] = useState(false);
    const [minimalEntities, setMinimalEntities] = useState([]);
    const [isEditing, setIsEditing] = useState(false);

    const { organization, user, getIdToken } = useAuth();
    const BACKEND_API_URL = process.env.REACT_APP_BACKEND_API_URL;
    const navigate = useNavigate();
    const location = useLocation();

    const { entityId } = useParams();

    // console log entityType
    console.log("entityType:", entityType);

    useEffect(() => {
        if (location.pathname.endsWith('/new')) {
            setSelectedEntity({
                isNew: true,
                name: '',
                taxNumber: '',
                address_line_1: '',
                address_line_2: '',
                city: '',
                postalCode: '',
                country: '',
                email: '',
                phone: '',
                categoryId: ''
            });
        } else if (entityId) {
            const entity = entities.find(e => e.id === entityId);
            if (entity) {
                setSelectedEntity(entity);
            } else {
                navigate(`/${entityType}s`, { replace: true });
            }
        } else {
            setSelectedEntity(null);
        }
    }, [location.pathname, entityId, entities, navigate, entityType]);


    const handleAddEntity = () => {
        console.log(`Adding new ${entityType}`);
        navigate(`/${entityType}s/new`);
    };
    
    const handleSetSortedEntities = useCallback((sortedData) => {
        // Only update if the data has actually changed
        if (JSON.stringify(sortedData) !== JSON.stringify(sortedEntities)) {
            setSortedEntities(sortedData);
        }
    }, [sortedEntities]);

    const fetchEntities = useCallback(async (page = currentPage, itemsPerPage = perPage) => {
        if (!organization || !user) return;
        setLoading(true);

        try {
            const token = await getIdToken();
            const response = await fetch(`${BACKEND_API_URL}/api/organizations/${organization.id}/entities?type=${entityType}s&page=${page}&per_page=${itemsPerPage}`, {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            });
            if (!response.ok) throw new Error(`Failed to fetch ${entityType}s`);

            const data = await response.json();
            setEntities(data.entities);
            setTotalPages(data.total_pages);
            setCurrentPage(data.page);
            console.log(`Fetched ${entityType}s:`, data.entities);

        } catch (error) {
            console.error(`Error fetching ${entityType}s:`, error);
        } finally {
            setLoading(false);
        }
    }, [BACKEND_API_URL, organization, user, entityType, currentPage, perPage]);

    const handlePageChange = useCallback((newPage) => {
        setCurrentPage(newPage);
        fetchEntities(newPage, perPage);
    }, [fetchEntities, perPage]);

    const handlePerPageChange = useCallback((newPerPage) => {
        setPerPage(newPerPage);
        fetchEntities(1, newPerPage);
    }, [fetchEntities]);


    const fetchMinimalEntities = useCallback(async () => {
        if (!organization || !user) return;
        try {
            const token = await getIdToken();
            const response = await fetch(`${BACKEND_API_URL}/api/organizations/${organization.id}/minimal-entities?type=${entityType}s`, {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            });
            if (!response.ok) throw new Error(`Failed to fetch minimal ${entityType}s`);
            const data = await response.json();
            setMinimalEntities(data);
            console.log(`Fetched minimal ${entityType}s:`, data);
        } catch (error) {
            console.error(`Error fetching minimal ${entityType}s:`, error);
        }
    }, [BACKEND_API_URL, organization, user, entityType]);



    

    const fetchAnalyticsSummary = useCallback(async () => {
        if (!organization) return;
    
        try {
            const analyticsRef = doc(db, `organizations/${organization.id}/analytics/overall/combined/summary`);
            const analyticsDoc = await getDoc(analyticsRef);
    
            if (analyticsDoc.exists()) {
                const data = analyticsDoc.data();
                console.log("Fetched analytics data:", data);
                setAnalyticsData(data);
                console.log("Fetched analytics data:", data);
            } else {
                console.log("No analytics data found");
            }
        } catch (error) {
            console.error("Error fetching analytics summary:", error);
        }
    }, [organization]);

    const fetchCategories = useCallback(async () => {
        if (!organization || !user) {
            console.log("Organization or user not available");
            return [];
        }
        try {
            const token = await getIdToken();
            console.log(`Fetching categories for ${entityType}`);
            const response = await fetch(`${BACKEND_API_URL}/api/organizations/${organization.id}/minimal-categories?type=${entityType}`, {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            });
            if (!response.ok) {
                console.error(`Failed to fetch categories: ${response.status} ${response.statusText}`);
                throw new Error('Failed to fetch categories');
            }
            const categories = await response.json();
            console.log("Categories fetched:", categories);
            setCategories(categories);
            return categories;
        } catch (error) {
            console.error('Error fetching categories:', error);
            setCategories([]);
            return [];
        }
    }, [BACKEND_API_URL, organization, user, entityType]);

    const refetchData = useCallback(async () => {
        await Promise.all([
            fetchEntities(),
            fetchAnalyticsSummary(),
            fetchMinimalEntities()
        ]);
        console.log("Data refetched after entity creation/update");
    }, [fetchEntities, fetchAnalyticsSummary, fetchMinimalEntities]);


    const handleDelete = async (selectedEntities) => {
        try {
            const token = await getIdToken();
            const triggerId = await createAnalyticsTrigger(db, organization, entityType, selectedEntities.length, 'delete');
            
            const response = await fetch(`${BACKEND_API_URL}/delete-entities`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
                body: JSON.stringify({
                    organizationId: organization.id,
                    entityType: entityType,
                    entityIds: selectedEntities,
                    triggerId: triggerId
                }),
            });

            if (!response.ok) {
                throw new Error('Failed to delete entities');
            }
            const data = await response.json();
            console.log("Delete response:", data);

            // Remove deleted entities from the local state
            setEntities(prevEntities => prevEntities.filter(entity => !selectedEntities.includes(entity.id)));

            // Start polling for analytics update completion
            await pollAnalyticsTriggerStatus(triggerId, getIdToken, () => {}, () => {});

            // Fetch updated entities and analytics data
            await Promise.all([fetchEntities(), fetchAnalyticsSummary(), fetchMinimalEntities()]);

            console.log(`${selectedEntities.length} ${entityType}(s) deleted successfully`);
        } catch (error) {
            console.error(`Error deleting ${entityType}s:`, error);
            throw error;
        }
    };

    const handleCSVDownload = async (downloadOptions) => {
        // Implement CSV download logic
        console.log("Downloading CSV with options:", downloadOptions);
    };

    const columns = [
        { id: 'name', label: entityType === 'supplier' ? 'Supplier Name' : 'Customer Name', defaultVisible: true, sortable: true },
        { id: 'createdAt', label: 'Created At', defaultVisible: true, sortable: true },
        { id: 'taxNumber', label: 'Tax Number', defaultVisible: true, sortable: true },
        { id: 'address', label: 'Address', defaultVisible: false, sortable: true },
        { id: 'email', label: 'Email', defaultVisible: false, sortable: true },
        { id: 'phone', label: 'Phone', defaultVisible: false, sortable: true },
        { id: 'updatedAt', label: 'Updated At', defaultVisible: false, sortable: true },
        // We'll keep these columns, but they might need to be computed or fetched separately
        { id: 'totalAmount', label: entityType === 'supplier' ? 'Total Spent' : 'Total Revenue', defaultVisible: true, sortable: true },
        { id: 'invoiceCount', label: 'Number of Invoices', defaultVisible: true, sortable: true },
        { id: 'lastInvoiceDate', label: 'Last Invoice Date', defaultVisible: true, sortable: true,
            render: (value) => {
                if (value && value.seconds) {
                    const date = new Date(value.seconds * 1000);
                    return format(date, 'yyyy-MM-dd');
                }
                return 'N/A';
            }
        },
    ];

    const filters = {
        categoryFilters: categories,
        entityFilters: entities.map(entity => entity.name),
        apply: useCallback((entity) => {
            // Add any custom filter logic here
            return true;
        }, [])
    };

    const handleRowClick = (entity) => {
        const index = sortedEntities.findIndex(e => e.id === entity.id);
        setSelectedEntity(entity);
        setCurrentEntityIndex(index);
    };

    const handleCloseModal = useCallback(() => {
        setSelectedEntity(null);
        navigate(`/${entityType}s`, { replace: true });
    }, [navigate, entityType]);

    const tableRef = useRef(null);

    const handlePrevNext = useCallback((direction) => {
        if (selectedEntity.isNew || isEditing) return; // Add this line

        if (sortedEntities.length === 0) return;
    
        let newIndex;
        if (direction === 'prev') {
            newIndex = (currentEntityIndex - 1 + sortedEntities.length) % sortedEntities.length;
        } else {
            newIndex = (currentEntityIndex + 1) % sortedEntities.length;
        }
        
        const newEntity = sortedEntities[newIndex]; 
        setSelectedEntity(newEntity);
        setCurrentEntityIndex(newIndex);

        // Scroll to the top of the modal
        if (tableRef.current) {
            tableRef.current.scrollToRow(newEntity.id);
        }
    
    }, [sortedEntities, currentEntityIndex, selectedEntity, isEditing]); // Add selectedEntity and isEditing to dependencies

    const updateEntity = async (organizationId, entityId, entityType, updateData, triggerId) => {
        try {
            const token = await getIdToken();
            const response = await fetch(`${process.env.REACT_APP_BACKEND_API_URL}/api/organizations/${organizationId}/${entityType}/${entityId}`, {
                method: 'PUT',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ ...updateData, triggerId }),
            });
            if (!response.ok) {
                throw new Error('Failed to update entity');
            }
            return await response.json();
        } catch (error) {
            console.error('Error updating entity:', error);
            throw error;
        }
    };

    const handleEntityUpdated = (updatedEntity) => {
        setEntities((prevEntities) =>
            prevEntities.map((entity) =>
                entity.id === updatedEntity.id ? updatedEntity : entity
            )
        );
    };

    useEffect(() => {
        const handleKeyDown = (event) => {
            if (selectedEntity && !selectedEntity.isNew && !isEditing) {
                if (event.key === "ArrowLeft") {
                    event.preventDefault();
                    handlePrevNext('prev');
                } else if (event.key === "ArrowRight") {
                    event.preventDefault();
                    handlePrevNext('next');
                }
            }
        };
        window.addEventListener("keydown", handleKeyDown);
        return () => {
            window.removeEventListener("keydown", handleKeyDown);
        };
    }, [selectedEntity, handlePrevNext, isEditing]);

    useEffect(() => {
        let isMounted = true;
        console.log('useEffect triggered', { organization, user, dataFetched, entityType });
    
        if (organization && user && !dataFetched) {
            setLoading(true);
            Promise.all([fetchEntities(), fetchAnalyticsSummary(), fetchCategories(), fetchMinimalEntities()])
                .then(() => {
                    if (isMounted) {
                        setLoading(false);
                        setDataFetched(true);
                    }
                })
                .catch((error) => {
                    if (isMounted) {
                        console.error("Error loading data:", error);
                        setLoading(false);
                    }
                });
        }
        return () => {
            isMounted = false;
        };
    }, [organization, user, entityType, dataFetched, fetchEntities, fetchAnalyticsSummary, fetchCategories, fetchMinimalEntities]);


    return (
        <div className="entities-page">
            <HeaderComponent 
                pageType="entity"
                entityType={entityType}
                onAddClick={handleAddEntity}
            />
            {loading ? (
                <div className="loading-container">
                    <div className="spinner"></div>
                </div>
            ) : (
                <>
                    <MetricsCardsContainer 
                        analyticsData={analyticsData}
                        entities={minimalEntities}
                        categories={categories}
                        entityType={entityType === 'supplier' ? 'expense' : 'revenue'}
                        isEntitiesPage={true}
                    />
                    <TableComponent 
                        data={entities}
                        setData={setEntities}
                        db={db}
                        ref={tableRef}
                        onUpdateAnalytics={fetchAnalyticsSummary}
                        columns={columns}
                        stickyColumnId="name"
                        filters={filters}
                        onCSVDownload={handleCSVDownload}
                        onDelete={handleDelete}
                        categoryField="category"
                        entityField="name"
                        showEntityFilters={false}
                        showDateFilter={false} // Add this line to hide the Date Range selector
                        onRowClick={handleRowClick}
                        selectedItem={selectedEntity}
                        onSortedDataChange={handleSetSortedEntities}
                        isInvoicePage={false} // Add this line
                        currentPage={currentPage}
                        totalPages={totalPages}
                        perPage={perPage}
                        onPageChange={handlePageChange}
                        onPerPageChange={handlePerPageChange}
                        pageType="entity"
                        invoiceType={entityType === 'supplier' ? 'expense' : 'revenue'}
                    />
                    {selectedEntity && (
                        <ViewEditEntity 
                            entity={selectedEntity}
                            onClose={handleCloseModal}
                            onPrevious={() => handlePrevNext('prev')}
                            onNext={() => handlePrevNext('next')}
                            entityType={entityType === 'supplier' ? 'Supplier' : 'Customer'}
                            hasPrevious={sortedEntities.length > 1}
                            hasNext={sortedEntities.length > 1}
                            onEntityUpdated={handleEntityUpdated} // Pass the callback here
                            onDataRefetch={refetchData}
                            updateEntity={updateEntity}
                            organization={organization}
                            categories={categories}
                            db={db}
                            isNewEntity={isNewEntity}
                            isEditing={isEditing}
                            setIsEditing={setIsEditing}
                        />
                    )}
                </>
            )}
        </div>
    );
};

EntitiesPage.propTypes = {
    entityType: PropTypes.oneOf(['supplier', 'customer']).isRequired,
    isNewEntity: PropTypes.bool,
};

export default EntitiesPage;
