import React, { useState, useEffect, useCallback } from 'react';
import { useLocation } from 'react-router-dom';
import './SettingsPage.css'; // We'll create this CSS file for our styles
import { useAuth } from '../../auth';
import { updatePassword, EmailAuthProvider, reauthenticateWithCredential } from 'firebase/auth';
import { auth } from '../../firebase';

import { Country, State } from 'country-state-city';
import { currencyCodesSymbols } from '../../utils/currencyUtils';
import { companySizeOptions, industryOptions } from '../../utils/settings_utils';
import Snackbar from '../../components/Snackbar/Snackbar';
import MailIcon from '../../assets/icons/mail-01.svg';
import ConfirmationModal from '../../components/ConfirmationModal/ConfirmationModal';
import PlanPage from '../../components/PlanPage/PlanPage';


const FormItem = ({ label, description, children, required }) => (
    <div className="form-item">
      <div className="form-item-label">
        <label>{label}{required && <span className="required">*</span>}</label>
        {description && <p className="form-item-description">{description}</p>}
      </div>
      <div className="form-item-input">
        {children}
      </div>
    </div>
  );
  
  const SettingsSection = ({ title, subtitle, children }) => (
    <div className="settings-section">
      <h2>{title}</h2>
      {subtitle && <p className="section-subtitle">{subtitle}</p>}
      {children}
    </div>
  );


const SettingsPage = () => {
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const initialTab = queryParams.get('tab') || 'My details';
    const [activeTab, setActiveTab] = useState(initialTab);

    const [userDetails, setUserDetails] = useState({
      firstName: '',
      lastName: '',
      email: '',
      type: '',
      role: '',
    });

    console.log("User Details:", userDetails);
    const tabs = [
      'My details',
      'Organization',
      'Password',
      'Team',
      // ...(userDetails.type !== 'Accountant' ? ['Plan', 'Billing', 'Integrations'] : []),
      ...(userDetails.type !== 'Accountant' ? ['Plan'] : []),
      // 'Notifications',
    ];

    const [organizationDetails, setOrganizationDetails] = useState({
      id: '',
      name: '',
      url: '',
      industry: '',
      size: '',
      email: '',
      taxNumber: '',
      address: {
        street1: '',
        street2: '',
        country: '',
        state: '',
        city: '',
        zipCode: ''
      },
      currency: '',
      logo: ''
    });

    const [teamInvitees, setTeamInvitees] = useState([{ email: '', role: 'Viewer' }]);
    const [accountantInvitees, setAccountantInvitees] = useState([{ email: '', role: 'Viewer' }]);
    const [clientInvitees, setClientInvitees] = useState([{ email: '', role: 'Viewer' }]);

    const [teamMembers, setTeamMembers] = useState([]);
    const [accountants, setAccountants] = useState([]);
    const [clients, setClients] = useState([]);

    const [pendingInvitations, setPendingInvitations] = useState([]);

    const [modalOpen, setModalOpen] = useState(false);
    const [invitationToCancel, setInvitationToCancel] = useState(null);


    const [snackbar, setSnackbar] = useState({
      open: false,
      message: '',
      severity: 'success'
    });
  
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);
    const BACKEND_API_URL= process.env.REACT_APP_BACKEND_API_URL;

    const [currentPassword, setCurrentPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [confirmNewPassword, setConfirmNewPassword] = useState('');
    const { user } = useAuth();
    const userRole = user?.role;
    const userType = user?.type;

    console.log("User Type:", userType);
    console.log("User Role:", userRole);  
    const { getIdToken } = useAuth();
    const [isSending, setIsSending] = useState(false);
    const [validationError, setValidationError] = useState('');

    const [memberToDelete, setMemberToDelete] = useState(null);
    const [deleteModalOpen, setDeleteModalOpen] = useState(false);

    const [editingMember, setEditingMember] = useState(null);
    const [newRole, setNewRole] = useState('');
    const [savingRole, setSavingRole] = useState(false);

    const isPasswordProvider = user?.providerData.some(
      (provider) => provider.providerId === 'password'
    );


    const canInviteTeamMembers = useCallback(() => {
      return (userType === 'User' && (userRole === 'Admin' || userRole === 'Owner')) ||
             (userType === 'Accountant' && (userRole === 'Admin' || userRole === 'Owner'));
    }, [userRole, userType]);
    
    const canInviteClients = useCallback(() => {
      return userType === 'Accountant' && (userRole === 'Admin' || userRole === 'Owner');
    }, [userRole, userType]);
    
    const canInviteAccountants = useCallback(() => {
      return userType === 'User' && (userRole === 'Admin' || userRole === 'Owner');
    }, [userRole, userType]);
  
    const getAvailableRoles = useCallback((inviteeType) => {
      if (inviteeType === 'User') {
        if (userRole === 'Owner') {
          return ['Viewer', 'Admin', 'Owner'];
        } else if (userRole === 'Admin') {
          return ['Viewer', 'Admin'];
        }
      } else if (inviteeType === 'Accountant') {
        return ['Viewer', 'Admin'];
      } else if (inviteeType === 'Client') {
        return ['Viewer'];
      }
      return [];
    }, [userRole]);
  
    const handlePasswordChange = async (e) => {
      e.preventDefault();
      if (newPassword !== confirmNewPassword) {
        setSnackbar({
          open: true,
          message: 'New passwords do not match',
          severity: 'error',
        });
        return;
      }
  
      try {
        const credential = EmailAuthProvider.credential(
          auth.currentUser.email,
          currentPassword
        );
        await reauthenticateWithCredential(auth.currentUser, credential);
        await updatePassword(auth.currentUser, newPassword);
        setSnackbar({
          open: true,
          message: 'Password updated successfully',
          severity: 'success',
        });
        setCurrentPassword('');
        setNewPassword('');
        setConfirmNewPassword('');
      } catch (error) {
        console.error('Error updating password:', error);
        setSnackbar({
          open: true,
          message: 'Failed to update password. Please check your current password and try again.',
          severity: 'error',
        });
      }
    };
  
    const renderPasswordSection = () => {
      if (!isPasswordProvider) {
        return (
          <p>You're signed in with Google. Password management is not available.</p>
        );
      }
  
      return (
        <form onSubmit={handlePasswordChange}>
          <SettingsSection title="Password" subtitle="Update your password here.">

          </SettingsSection>
          <FormItem label="Current Password" required>
            <input
              type="password"
              value={currentPassword}
              onChange={(e) => setCurrentPassword(e.target.value)}
              required
            />
          </FormItem>
          <FormItem label="New Password" required>
            <input
              type="password"
              value={newPassword}
              onChange={(e) => setNewPassword(e.target.value)}
              required
            />
          </FormItem>
          <FormItem label="Confirm New Password" required>
            <input
              type="password"
              value={confirmNewPassword}
              onChange={(e) => setConfirmNewPassword(e.target.value)}
              required
            />
          </FormItem>
          <button type="submit" className="btn-save">
            Update Password
          </button>
        </form>
      );
    };



    const fetchUserDetails = useCallback(async () => {
      console.log('Fetching user details:', user);
      setIsLoading(true);
      setError(null);
      try {
        const token = await getIdToken();
        console.log('Token retrieved successfully');
    
        const response = await fetch(`${BACKEND_API_URL}/api/settings/my-details`, {
          headers: { 
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
          }
        });
    
        console.log('Response status:', response.status);
        console.log('Response headers:', response.headers);
    
        if (!response.ok) {
          const errorText = await response.text();
          console.error('Error response:', errorText);
          throw new Error(`HTTP error! status: ${response.status}`);
        }
    
        const data = await response.json();
        console.log('Received my-details data:', data);
    
        // setUserDetails(data);
        setUserDetails(prev => ({ ...prev, type: data.type, firstName: data.firstName, lastName: data.lastName, email: data.email, role: data.role }));
      } catch (err) {
        console.error('Error in fetchUserDetails:', err);
        setError('Failed to fetch user details. Please try again.');
      } finally {
        setIsLoading(false);
      }
    }, [user, BACKEND_API_URL, getIdToken]);
  
    const handleInputChange = (e) => {
      const { name, value } = e.target;
      if (name !== 'email') {
        setUserDetails(prev => ({ ...prev, [name]: value }));
      }
    };
  
    const handleSubmit = async (e) => {
      e.preventDefault();
      setIsLoading(true);
      setError(null);
      try {
        const token = await getIdToken();
        const response = await fetch(`${BACKEND_API_URL}/api/settings/my-details`, {
          method: 'PUT',
          headers: { 
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(userDetails)
        });
        if (!response.ok) {
          throw new Error('Failed to update user details');
        }
        const data = await response.json();
        setUserDetails(data);
        setSnackbar({
          open: true,
          message: 'Details updated successfully!',
          severity: 'success'
        });
      } catch (err) {
        setSnackbar({
          open: true,
          message: 'Failed to update user details. Please try again.',
          severity: 'error'
        });
        console.error('Error updating user details:', err);
      } finally {
        setIsLoading(false);
      }
    };
  
    const renderMyDetails = () => (
      <SettingsSection title="My details" subtitle="Update your details here.">
        {isLoading ? (
          <p>Loading...</p>
        ) : error ? (
          <p className="error">{error}</p>
        ) : (
          <form onSubmit={handleSubmit}>
            <FormItem label="First name" description="This will be displayed on your profile." required>
              <input
                type="text"
                name="firstName"
                value={userDetails.firstName}
                onChange={handleInputChange}
                required
              />
            </FormItem>
            <FormItem label="Last name" description="This will be displayed on your profile." required>
              <input
                type="text"
                name="lastName"
                value={userDetails.lastName}
                onChange={handleInputChange}
                required
              />
            </FormItem>
            <FormItem label="Personal email" description="This will be displayed on your profile." required>
              <input
                type="email"
                name="email"
                value={userDetails.email}
                onChange={handleInputChange}
                required
                disabled
                className='disabled-input'
              />
            </FormItem>
            <div className="button-group">
              <button type="button" className="btn-cancel" onClick={() => fetchUserDetails()}>Cancel</button>
              <button type="submit" className="btn-save" disabled={isLoading}>
                {isLoading ? 'Saving...' : 'Save'}
              </button>
            </div>
          </form>
        )}
      </SettingsSection>
    );
    

    const fetchOrganizationDetails = useCallback(async () => {
      setIsLoading(true);
      setError(null);
      try {
        const token = await getIdToken();
        const response = await fetch(`${BACKEND_API_URL}/api/settings/organization`, {
          headers: { 
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
          }
        });
        if (!response.ok) {
          throw new Error('Failed to fetch organization details');
        }
        const data = await response.json();
        console.log("Organization Details:", data);
        setOrganizationDetails({
          ...data,
          id: data.id, // Ensure the backend is sending the organization ID
          userType: data.userType // Store the user type

        });
      } catch (err) {
        setError('Failed to fetch organization details. Please try again.');
        console.error('Error fetching organization details:', err);
      } finally {
        setIsLoading(false);
      }
    }, [BACKEND_API_URL, getIdToken]);
  
    const handleOrganizationInputChange = useCallback((e) => {
      const { name, value } = e.target;
      if (name.includes('.')) {
        const [parent, child] = name.split('.');
        setOrganizationDetails(prev => {
          if (parent === 'address' && child === 'country') {
            // Reset state when country changes
            return {
              ...prev,
              address: { ...prev.address, [child]: value, state: '' }
            };
          }
          return {
            ...prev,
            [parent]: { ...prev[parent], [child]: value }
          };
        });
      } else {
        setOrganizationDetails(prev => ({ ...prev, [name]: value }));
      }
    }, []);

    useEffect(() => {
      if (user) {
        fetchUserDetails();
        fetchOrganizationDetails();
      }
    }, [user, fetchOrganizationDetails, fetchUserDetails]);


  
    const handleOrganizationSubmit = async (e) => {
      e.preventDefault();
      setIsLoading(true);
      setError(null);
      try {
        const token = await getIdToken();
        const response = await fetch(`${BACKEND_API_URL}/api/settings/organization`, {
          method: 'PUT',
          headers: { 
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(organizationDetails)
        });
        if (!response.ok) {
          throw new Error('Failed to update organization details');
        }
        const data = await response.json();
        // setOrganizationDetails(data);
        setOrganizationDetails(prev => ({ ...prev, ...data }));
        setSnackbar({
          open: true,
          message: 'Organization details updated successfully!',
          severity: 'success'
        });
      } catch (err) {
        setSnackbar({
          open: true,
          message: 'Failed to update organization details. Please try again.',
          severity: 'error'
        });
        console.error('Error updating organization details:', err);
      } finally {
        setIsLoading(false);
      }
    };

    const handleLogoUpload = async (e) => {
      const file = e.target.files[0];
      if (file) {
        setIsLoading(true);
        try {
          const formData = new FormData();
          formData.append('logo', file);
  
          const token = await getIdToken();
          const response = await fetch(`${BACKEND_API_URL}/api/settings/organization/logo`, {
            method: 'POST',
            headers: { 
              'Authorization': `Bearer ${token}`
            },
            body: formData
          });
  
          if (!response.ok) {
            throw new Error('Failed to upload logo');
          }
  
          const data = await response.json();
          setOrganizationDetails(prev => ({ ...prev, logo: data.logoUrl }));
          setSnackbar({
            open: true,
            message: 'Logo uploaded successfully!',
            severity: 'success'
          });
        } catch (err) {
          setSnackbar({
            open: true,
            message: 'Failed to upload logo. Please try again.',
            severity: 'error'
          });
          console.error('Error uploading logo:', err);
        } finally {
          setIsLoading(false);
        }
      }
    };
  
    const renderOrganization = () => (
      <SettingsSection title="Company profile" subtitle="Update your company profile and details here.">
        <form onSubmit={handleOrganizationSubmit}>
          <FormItem label="Company name" description="This will be displayed on your profile." required>
            <input
              type="text"
              name="name"
              value={organizationDetails.name}
              onChange={handleOrganizationInputChange}
              required
            />
          </FormItem>
          <FormItem label="Company Website" description="This will be displayed on your profile." required>
            <input
              type="url"
              name="url"
              value={organizationDetails.url}
              onChange={handleOrganizationInputChange}
              required
            />
          </FormItem>
          {organizationDetails.userType !== 'Accountant' && (
            <>
            <FormItem label="Industry" description="This will be displayed on your profile." required>
              <select
                name="industry"
                value={organizationDetails.industry}
                onChange={handleOrganizationInputChange}
                required
              >
                <option value="" disabled>Select an industry</option>
                {industryOptions.map((industry) => (
                  <option key={industry.value} value={industry.value}>
                    {industry.label}
                  </option>
                ))}
              </select>
            </FormItem>
            <FormItem label="Company Size" description="This will be displayed on your profile." required>
              <select
                name="size"
                value={organizationDetails.size}
                onChange={handleOrganizationInputChange}
                required
              >
                <option value="" disabled>Select company size</option>
                {companySizeOptions.map((size) => (
                  <option key={size.value} value={size.value}>
                    {size.label}
                  </option>
                ))}
              </select>
            </FormItem>
            </>
          )}
          <FormItem label="Primary Contact Email" description="This will be used for important communications." required>
            <input
              type="email"
              name="email"
              value={organizationDetails.email}
              onChange={handleOrganizationInputChange}
              required
            />
          </FormItem>
          <FormItem label="VAT Number" description="Your company's VAT identification number.">
            <input
              type="text"
              name="taxNumber"
              value={organizationDetails.taxNumber}
              onChange={handleOrganizationInputChange}
            />
          </FormItem>
          <FormItem label="Company Address" description="Your company's official address.">
            <div className="form-item-input">
              <input
                type="text"
                name="address.street1"
                value={organizationDetails.address.street1}
                onChange={handleOrganizationInputChange}
                placeholder="Street Address 1"
              />
              <input
                type="text"
                name="address.street2"
                value={organizationDetails.address.street2}
                onChange={handleOrganizationInputChange}
                placeholder="Street Address 2"
              />
              <select
                name="address.country"
                value={organizationDetails.address.country}
                onChange={handleOrganizationInputChange}
              >
                <option value="" disabled>Select Country</option>
                {Country.getAllCountries().map((country, index) => (
                  <option key={`${country.isoCode}-${index}`} value={country.isoCode}>
                    {country.name}
                  </option>
                ))}
              </select>
              <select
                name="address.state"
                value={organizationDetails.address.state}
                onChange={handleOrganizationInputChange}
              >
                <option value="" disabled>Select State/Province</option>
                {State.getStatesOfCountry(organizationDetails.address.country).map((state, index) => (
                  <option key={`${state.isoCode}-${index}`} value={state.isoCode}>
                    {state.name}
                  </option>
                ))}
              </select>
              <input
                type="text"
                name="address.city"
                value={organizationDetails.address.city}
                onChange={handleOrganizationInputChange}
                placeholder="City"
              />
              <input
                type="text"
                name="address.zipCode"
                value={organizationDetails.address.zipCode}
                onChange={handleOrganizationInputChange}
                placeholder="Zip Code"
              />
            </div>
          </FormItem>
          <FormItem label="Base Currency" description="Your company's primary operating currency." required>
            <select
              name="currency"
              value={organizationDetails.currency}
              onChange={handleOrganizationInputChange}
              required
            >
              <option value="" disabled>Select Currency</option>
              {currencyCodesSymbols.map((currency, index) => (
                <option key={`${currency.code}-${index}`} value={currency.code}>
                  {currency.code} ({currency.symbol})
                </option>
              ))}
            </select>
          </FormItem>
          <FormItem label="Company Logo" description="Upload your company logo.">
            {organizationDetails.logo && (
              <img src={organizationDetails.logo} alt="Company Logo" className="company-logo-preview" />
            )}
            <input 
              type="file" 
              name="logo" 
              onChange={handleLogoUpload} 
              accept="image/*"
            />
          </FormItem>
          <div className="button-group">
            <button type="button" className="btn-cancel" onClick={() => fetchOrganizationDetails()}>Cancel</button>
            <button type="submit" className="btn-save" disabled={isLoading}>
              {isLoading ? 'Saving...' : 'Save'}
            </button>
          </div>
        </form>
      </SettingsSection>
    );



    const fetchTeamMembers = useCallback(async () => {
      setIsLoading(true);
      setError(null);
      try {
        const token = await getIdToken();
        const response = await fetch(`${BACKEND_API_URL}/api/settings/team`, {
          headers: { 
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
          }
        });
        if (!response.ok) {
          throw new Error('Failed to fetch team members');
        }
        const data = await response.json();
        console.log('Received team data:', data); // Log the received data
        // setTeamMembers(Array.isArray(data.teamMembers) ? data.teamMembers : []);
        // const teamMembers = data.teamMembers.filter(member => member.type === 'User' || member.type === '');
        // const accountants = data.teamMembers.filter(member => member.type === 'Accountant');
        const teamMembers = data.teamMembers;
        const accountants = data.accountants;
        const clients = data.clients;
        const pendingInvitations = Array.isArray(data.pendingInvitations) ? data.pendingInvitations : [];

        setTeamMembers(teamMembers);
        setAccountants(accountants);
        setClients(clients);

        console.log('All team members:', data.teamMembers);
        console.log('Filtered team members:', teamMembers);
        console.log('Filtered accountants:', accountants);
        console.log('Filtered clients:', clients);
        console.log('Pending invitations:', pendingInvitations);
        setPendingInvitations(Array.isArray(data.pendingInvitations) ? data.pendingInvitations : []);
      } catch (err) {
        setError('Failed to fetch team members. Please try again.');
        console.error('Error fetching team members:', err);
        setTeamMembers([]);
        setPendingInvitations([]);
      } finally {
        setIsLoading(false);
      }
    }, [BACKEND_API_URL, getIdToken]);
  
    useEffect(() => {
      if (user) {
        fetchUserDetails();
        fetchOrganizationDetails();
        fetchTeamMembers();
      }
    }, [user, fetchOrganizationDetails, fetchUserDetails, fetchTeamMembers]);
      
  
    const handleTeamInviteeChange = (index, field, value) => {
      const newInvitees = [...teamInvitees];
      newInvitees[index][field] = value;
      setTeamInvitees(newInvitees);
    };
  
    const handleAccountantInviteeChange = (index, field, value) => {
      const newInvitees = [...accountantInvitees];
      newInvitees[index][field] = value;
      setAccountantInvitees(newInvitees);
    };

    const handleClientInviteeChange = (index, field, value) => {
      const newInvitees = [...clientInvitees];
      newInvitees[index][field] = value;
      setClientInvitees(newInvitees);
    };
  
    const addTeamInvitee = () => {
      setTeamInvitees([...teamInvitees, { email: '', role: 'Viewer' }]);
    };
  
    const addAccountantInvitee = () => {
      setAccountantInvitees([...accountantInvitees, { email: '', role: 'Viewer' }]);
    };

    const addClientInvitee = () => {
      setClientInvitees([...clientInvitees, { email: '', role: 'Viewer' }]);
    };

    const isEmailAlreadyInTeam = (email) => {
      const allMembers = [...teamMembers, ...accountants];
      return allMembers.some(member => member.email.toLowerCase() === email.toLowerCase());
    };
  
    const validateInvites = (invitees) => {
      const filledEmails = invitees.filter(invitee => invitee.email.trim() !== '');
      
      if (filledEmails.length === 0) {
        setValidationError('Please enter at least one email address.');
        return false;
      }
      
      const existingEmails = filledEmails.filter(invitee => isEmailAlreadyInTeam(invitee.email.trim()));
      if (existingEmails.length > 0) {
        setValidationError(`The following email(s) already exist in the team: ${existingEmails.map(i => i.email).join(', ')}`);
        return false;
      }
      
      setValidationError('');
      return true;
    };
  
  
    const sendInvites = async (invitees, invitationType) => {

      const validInvitees = invitees.filter(invitee => invitee.email.trim() !== '');

      if (!validateInvites(validInvitees)) {
        return;
      }
    
      setIsSending(true);
      try {
        const token = await getIdToken();
    
        if (!organizationDetails.id) {
          throw new Error("Organization ID is missing. Please refresh the page and try again.");
        }
    
        const validInvitees = invitees.filter(invitee => invitee.email.trim() !== '');
    
        if (validInvitees.length === 0) {
          setSnackbar({
            open: true,
            message: 'No valid email addresses to send invitations.',
            severity: 'warning'
          });
          setIsSending(false);
          return;
        }
    
        for (const invitee of validInvitees) {
          const inviteeType = determineInviteeType(invitationType);

          // console.log(`Sending invite to ${invitee.email} with role ${invitee.role}, type ${type}, organizationId: ${organizationDetails.id}`);
          console.log(`Sending invite to ${invitee.email} with role ${invitee.role}, type ${inviteeType}, invitationType ${invitationType}, organizationId: ${organizationDetails.id}`);



          const response = await fetch(`${BACKEND_API_URL}/api/invite-user`, {
            method: 'POST',
            headers: { 
              'Authorization': `Bearer ${token}`,
              'Content-Type': 'application/json'
            },
            body: JSON.stringify({
              organizationId: organizationDetails.id,
              email: invitee.email.trim(),
              role: invitee.role,
              type: inviteeType,
              invitationType: invitationType
            })
          });
    
          if (!response.ok) {
            const errorData = await response.json();
            throw new Error(`Failed to send invite to ${invitee.email}: ${errorData.error}`);
          }
        }
    
        setSnackbar({
          open: true,
          message: 'Invites sent successfully!',
          severity: 'success'
        });
        
        // Reset invitees state consistently for both types
        // Reset invitees state
        resetInvitees(invitationType);
        // if (type === 'User') {
        //   setTeamInvitees([{ email: '', role: 'Viewer' }]);
        // } else {
        //   setAccountantInvitees([{ email: '', role: 'Viewer' }]);
        // }
        
        fetchTeamMembers();
      } catch (err) {
        setSnackbar({
          open: true,
          message: `Failed to send invites: ${err.message}`,
          severity: 'error'
        });
        console.error('Error sending invites:', err);
      } finally {
        setIsSending(false);
      }
    };


    const determineInviteeType = (invitationType) => {
      switch (invitationType) {
        case 'user_to_org':
        case 'client_to_accountant':
          return 'User';
        case 'accountant_to_org':
        case 'user_to_accountant':
          return 'Accountant';
        default:
          throw new Error('Invalid invitation type');
      }
    };
    
    const resetInvitees = (invitationType) => {
      switch (invitationType) {
        case 'user_to_org':
        case 'user_to_accountant':
          setTeamInvitees([{ email: '', role: 'Viewer' }]);
          break;
        case 'accountant_to_org':
          setAccountantInvitees([{ email: '', role: 'Viewer' }]);
          break;
        case 'client_to_accountant':
          setClientInvitees([{ email: '', role: 'Viewer' }]);
          break;
        default:
          console.error('Invalid invitation type for resetting invitees');
      }
    };
  
    const handleCancelInvitation = (invitationId) => {
      setInvitationToCancel(invitationId);
      setModalOpen(true);
    };
  
    const confirmCancelInvitation = async () => {
      console.log('Confirming cancellation of invitation:', invitationToCancel);
      setIsLoading(true);
      try {
        const token = await getIdToken();
        const response = await fetch(`${BACKEND_API_URL}/api/cancel-invitation`, {
          method: 'POST',
          headers: { 
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({ invitationId: invitationToCancel })
        });
  
        if (!response.ok) {
          throw new Error('Failed to cancel invitation');
        }
  
        setSnackbar({
          open: true,
          message: 'Invitation cancelled successfully!',
          severity: 'success'
        });
        console.log("Fetching team members after cancelling invitation");
        fetchTeamMembers(); // Refresh the team members and pending invitations
      } catch (err) {
        setSnackbar({
          open: true,
          message: 'Failed to cancel invitation. Please try again.',
          severity: 'error'
        });
        console.error('Error cancelling invitation:', err);
      } finally {
        setIsLoading(false);
        setModalOpen(false);
        setInvitationToCancel(null);
      }
    };

    
  
    // const removeTeamMember = async (email) => {
    //   setIsLoading(true);
    //   try {
    //     const token = await getIdToken();
    //     const response = await fetch(`${BACKEND_API_URL}/api/settings/team/remove`, {
    //       method: 'DELETE',
    //       headers: { 
    //         'Authorization': `Bearer ${token}`,
    //         'Content-Type': 'application/json'
    //       },
    //       body: JSON.stringify({ email })
    //     });
    //     if (!response.ok) {
    //       throw new Error('Failed to remove team member');
    //     }
    //     setSnackbar({
    //       open: true,
    //       message: 'Team member removed successfully!',
    //       severity: 'success'
    //     });
    //     fetchTeamMembers();
    //   } catch (err) {
    //     setSnackbar({
    //       open: true,
    //       message: 'Failed to remove team member. Please try again.',
    //       severity: 'error'
    //     });
    //     console.error('Error removing team member:', err);
    //   } finally {
    //     setIsLoading(false);
    //   }
    // };

    const handleDeleteMember = (member) => {
      setMemberToDelete(member);
      setDeleteModalOpen(true);
    };
  
    const confirmDeleteMember = async () => {
      if (!memberToDelete) return;
  
      setIsLoading(true);
      try {
        const token = await getIdToken();
        const response = await fetch(`${BACKEND_API_URL}/api/settings/team/remove`, {
          method: 'DELETE',
          headers: { 
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({ 
            email: memberToDelete.email,
            type: memberToDelete.type
          })
        });
  
        if (!response.ok) {
          throw new Error('Failed to remove team member');
        }
  
        setSnackbar({
          open: true,
          message: 'Team member removed successfully!',
          severity: 'success'
        });
        fetchTeamMembers();
      } catch (err) {
        setSnackbar({
          open: true,
          message: 'Failed to remove team member. Please try again.',
          severity: 'error'
        });
        console.error('Error removing team member:', err);
      } finally {
        setIsLoading(false);
        setDeleteModalOpen(false);
        setMemberToDelete(null);
      }
    };

    const handleSaveRole = async (member) => {
      setIsLoading(true);
      setSavingRole(true);

      try {
        const token = await getIdToken();
        const response = await fetch(`${BACKEND_API_URL}/api/settings/team/update-role`, {
          method: 'PUT',
          headers: { 
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({ email: member.email, newRole })
        });
        if (!response.ok) {
          throw new Error('Failed to update role');
        }
        setSnackbar({
          open: true,
          message: 'Role updated successfully!',
          severity: 'success'
        });
        fetchTeamMembers(); // Refresh the team data
      } catch (err) {
        setSnackbar({
          open: true,
          message: 'Failed to update role. Please try again.',
          severity: 'error'
        });
        console.error('Error updating role:', err);
      } finally {
        setIsLoading(false);
        setEditingMember(null);
        setSavingRole(false);
      }
    };

  
  
    const renderTeam = () => {
      console.log('Rendering team members:', teamMembers);  
      console.log('Rendering accountants:', accountants);
      console.log('Rendering clients:', clients);
      console.log('Rendering pending invitations:', pendingInvitations);

      const currentUserEmail = user.email; // Assuming you have access to the current user's email
      const isAccountant = userDetails.type === 'Accountant';

      const canEditMember = (member) => {
        if (userRole === 'Owner') {
          return true;
        } else if (userRole === 'Admin') {
          return member.role !== 'Owner';
        }
        return false;
      };

      const handleEditMember = (member) => {
        console.log("Editing member:", member);
        setEditingMember(member);
        setNewRole(member.role);        
      };

      
     
      const canDeleteMember = (member) => {
        if (member.email === currentUserEmail) {
          return false; // Prevent self-deletion
        }
        if (userType === 'User') {
          if (userRole === 'Owner') {
            return true;
          } else if (userRole === 'Admin') {
            return member.role !== 'Owner';
          }
        } else if (userType === 'Accountant' && userRole === 'Admin') {
          return member.type === 'Accountant' && member.role !== 'Admin';
        }
        return false;
      };

      const renderMemberActions = (member) => {
        if (member.email === currentUserEmail) {
          return null; // No actions for current user
        }
        if (editingMember && editingMember.email === member.email) {
          return (
            <>
              <button 
                className="save" 
                onClick={() => handleSaveRole(member)}
                disabled={savingRole}
>
                {savingRole ? 'Saving...' : 'Save'}
              </button>
              <button 
                className="cancel" 
                onClick={() => setEditingMember(null)}
                disabled={savingRole}
              >
                Cancel
              </button>
            </>
          );
        }
        return (
          <>
            {canDeleteMember(member) && (
              <button className="delete" onClick={() => handleDeleteMember(member)}>Delete</button>
            )}
            {canEditMember(member) && (
              <button className="edit" onClick={() => handleEditMember(member)}>Edit</button>
            )}
          </>
        );
      };

      const renderPendingInvitations = (invitationType) => {
        const filteredInvitations = pendingInvitations.filter(inv => inv.invitationType === invitationType);
        return filteredInvitations.map((invitation, index) => (
          <tr key={`pending-${invitationType}-${index}`}>
            <td>
              <div>
                <div>{invitation.invitee.email}</div>
                <div className="email">Pending Invitation</div>
              </div>
            </td>
            <td>{invitation.invitee.role}</td>
            <td>
              <span className="status-badge pending">Pending</span>
            </td>
            <td>
              <button className="cancel" onClick={() => handleCancelInvitation(invitation.id)}>Cancel Invitation</button>
            </td>
          </tr>
        ));
      };

      const renderMembers = (members, type) => {
        return members.map((member, index) => (
          <tr key={`${type}-${index}`}>
            <td>
              <div>
                <div>{member.name} {member.email === currentUserEmail && " (You)"}</div>
                <div className="email">{member.email}</div>
              </div>
            </td>
            <td>
              {editingMember && editingMember.email === member.email ? (
                <select 
                  value={newRole} 
                  onChange={(e) => setNewRole(e.target.value)}
                  disabled={!canEditMember(member)}
                >
                  {getAvailableRoles(member.type).map(role => (
                    <option key={role} value={role}>{role}</option>
                  ))}
                </select>
              ) : (
                member.role
              )}
            </td>
            <td>
              <span className="status-badge active">Active</span>
            </td>
            <td>
              {renderMemberActions(member)}
            </td>
          </tr>
        ));
      };

      return (
        <SettingsSection title="Team" subtitle="Manage your teams and user permissions.">
          <div className="team-content">
            
            {canInviteTeamMembers() && (
                <div className="invite-team-members">
                  <div className="invite-column invite-header">
                  <h3>Invite team members</h3>
                  <p>Invite team members to help manage your financial data.</p>
                  </div>
                  <div className="invite-column invite-emails">
                    {teamInvitees.map((invitee, index) => (
                      <input
                        key={index}
                        type="email"
                        value={invitee.email}
                        onChange={(e) => handleTeamInviteeChange(index, 'email', e.target.value)}
                        placeholder={isAccountant ? "accountant@example.com" : "team@example.com"}
                        disabled={isSending}
                      />
                    ))}
                    <button className="add-another" onClick={addTeamInvitee}>+ Add another</button>
                  </div>
                  <div className="invite-column invite-roles">
                    {teamInvitees.map((invitee, index) => (
                      <select
                        key={index}
                        value={invitee.role}
                        onChange={(e) => handleTeamInviteeChange(index, 'role', e.target.value)}
                        disabled={isSending}
                      >
                        {getAvailableRoles('User').map(role => (
                          <option key={role} value={role}>{role}</option>
                        ))}
                      </select>
                    ))}
                    {validationError && <p className="error-message">{validationError}</p>}
                    <button 
                      className={`send-invites ${isSending ? 'sending' : ''}`} 
                      onClick={() => sendInvites(teamInvitees, userType === 'Accountant' ? 'user_to_accountant' : 'user_to_org')} 
                      disabled={isSending}
                    >
                      <img src={MailIcon} alt="Mail Icon" />
                      {isSending ? 'Sending...' : 'Send invites'}
                    </button>
                  </div>
                </div>
            )} 

            {canInviteClients() && (
              <div className="invite-team-members">
              <div className="invite-column invite-header">
              <h3>Invite clients</h3>
              <p>Invite clients to help manage your financial data.</p>
              </div>
              <div className="invite-column invite-emails">
                {clientInvitees.map((invitee, index) => (
                  <input
                    key={index}
                    type="email"
                    value={invitee.email}
                    onChange={(e) => handleClientInviteeChange(index, 'email', e.target.value)}
                    placeholder="client@example.com"
                    disabled={isSending}
                  />
                ))}
                <button className="add-another" onClick={addClientInvitee}>+ Add another</button>
              </div>
              <div className="invite-column invite-roles">
                {clientInvitees.map((invitee, index) => (
                  <select
                    key={index}
                    value={invitee.role}
                    onChange={(e) => handleClientInviteeChange(index, 'role', e.target.value)}
                    disabled={isSending}
                  >
                    {getAvailableRoles('Client').map(role => (
                      <option key={role} value={role}>{role}</option>
                    ))}
                  </select>
                ))}
                {validationError && <p className="error-message">{validationError}</p>}
                <button 
                  className={`send-invites ${isSending ? 'sending' : ''}`} 
                  onClick={() => sendInvites(clientInvitees, 'client_to_accountant')} 
                  disabled={isSending}
                >
                  <img src={MailIcon} alt="Mail Icon" />
                  {isSending ? 'Sending...' : 'Send invites'}
                </button>
              </div>
            </div>
          )} 

            {canInviteAccountants() && (
                  <div className="invite-team-members">
                    <div className="invite-column invite-header">
                      <h3>Invite accountants</h3>
                      <p>Invite accountants to help manage your financial data.</p>
                    </div>
                    <div className="invite-column invite-emails">
                      {accountantInvitees.map((invitee, index) => (
                        <input
                          key={index}
                          type="email"
                          value={invitee.email}
                          onChange={(e) => handleAccountantInviteeChange(index, 'email', e.target.value)}
                          placeholder="accountant@example.com"
                          disabled={isSending}
                        />
                      ))}
                      <button className="add-another" onClick={addAccountantInvitee}>+ Add another</button>
                    </div>
                    <div className="invite-column invite-roles">
                      {accountantInvitees.map((invitee, index) => (
                        <select
                          key={index}
                          value={invitee.role}
                          onChange={(e) => handleAccountantInviteeChange(index, 'role', e.target.value)}
                          disabled={isSending}
                        >
                          {getAvailableRoles('Accountant').map(role => (
                            <option key={role} value={role}>{role}</option>
                          ))}
                        </select>
                      ))}
                      {validationError && <p className="error-message">{validationError}</p>}
                      <button 
                        className={`send-invites ${isSending ? 'sending' : ''}`} 
                        onClick={() => sendInvites(accountantInvitees, 'accountant_to_org')} 
                        disabled={isSending}
                      >
                        <img src={MailIcon} alt="Mail Icon" />
                        {isSending ? 'Sending...' : 'Send invites'}
                      </button>
                    </div>

                  </div>
            )}

            <div className="team-members">
              <div  className="team-column team-header">
                <h3>Team members</h3>
                <p>Manage your team members and their permissions.</p>
              </div>
              <div className="team-column team-table">
                <table>
                  <thead>
                    <tr>
                      <th>Name</th>
                      <th>Role</th>
                      <th>Status</th>
                      <th>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                  {/* {(isAccountant ? [...teamMembers, ...accountants] : teamMembers).map((member, index) => (
                      <tr key={`teamMember-${index}`}>
                        <td>
                          <div>
                            <div>{member.name} {member.email === currentUserEmail && " (You)"}</div>
                            <div className="email">{member.email} </div>
                            
                          </div>
                        </td>
                        <td>{member.role}</td>
                        <td>
                          <span className="status-badge active">Active</span>
                        </td>
                        <td>
                          {member.email !== currentUserEmail && (
                            <>
                              {canDeleteMember(member) && (
                                <button className="delete" onClick={() => handleDeleteMember(member)}>Delete</button>
                              )}
                              <button className="edit">Edit</button>
                            </>
                          )}
                        </td>
                      </tr>
                    ))} */}
                    {renderMembers(isAccountant ? [...teamMembers, ...accountants] : teamMembers, 'team')}
                    {renderPendingInvitations('user_to_org')}
                    {renderPendingInvitations('user_to_accountant')}
                  </tbody>
                </table>
              </div>
            </div>



            {!isAccountant && (
              <div className="team-members">
                <div className="team-column team-header">
                  <h3>Accountants</h3>
                  <p>Manage your accountants and their permissions.</p>
                </div>
                <div className="team-column team-table">
                  <table>
                    <thead>
                      <tr>
                        <th>Name</th>
                        <th>Role</th>
                        <th>Status</th>
                        <th>Actions</th>
                      </tr>
                    </thead>
                    <tbody>
                      {renderMembers(accountants, 'accountant')}
                      {renderPendingInvitations('accountant_to_org')}
                    </tbody>
                  </table>
                </div>
              </div>
            )}

              {isAccountant && (
                  <div className="team-members">
                    <div className="team-column team-header">
                  <h3>Clients</h3>
                  <p>Manage your clients and their permissions.</p>
                </div>
                <div className="team-column team-table">
                  <table>
                    <thead>
                      <tr>
                        <th>Name</th>
                        <th>Role</th>
                        <th>Status</th>
                        <th>Actions</th>
                      </tr>
                    </thead>
                    <tbody>
                      {renderMembers(clients, 'client')}
                      {renderPendingInvitations('client_to_accountant')}
                    </tbody>
                  </table>
                </div>
              </div>
            )}
          </div>
        </SettingsSection>
      );
    };

    



    const renderTabContent = () => {
      switch (activeTab) {
        case 'My details':
          return renderMyDetails()
        case 'Organization':
          return renderOrganization()
        case 'Password':
          return renderPasswordSection();
        case 'Team':
          return renderTeam();
        case 'Plan':
          return <PlanPage />
        // Add cases for other tabs here
        default:
          return <div>Content for {activeTab}</div>;
      }
    };

    return (
      <div className="settings-page">
        <h1 className="settings-title">Settings</h1>
        <div className="tabs-container">
          {tabs.map((tab) => (
            <button
              key={tab}
              className={`tab ${activeTab === tab ? 'tab-active' : 'tab-inactive'}`}
              onClick={() => setActiveTab(tab)}
            >
              {tab}
            </button>
          ))}
        </div>
        {renderTabContent()}
        <ConfirmationModal
          isOpen={modalOpen}
          onClose={() => setModalOpen(false)}
          onConfirm={confirmCancelInvitation}
          message="Are you sure you want to cancel this invitation?"
        />
        <ConfirmationModal
          isOpen={deleteModalOpen}
          onClose={() => setDeleteModalOpen(false)}
          onConfirm={confirmDeleteMember}
          message={`Are you sure you want to remove ${memberToDelete?.firstName} ${memberToDelete?.lastName} from the team?`}
        />
        <Snackbar
            open={snackbar.open}
            message={snackbar.message}
            severity={snackbar.severity}
            onClose={() => setSnackbar({ ...snackbar, open: false })}
        />
      </div>
    );
  };

  export default SettingsPage;
