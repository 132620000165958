// src/pages/LandingPage/components/Navbar/Navbar.jsx
import React, { useState } from 'react';
import { 
  AppBar, 
  Box, 
  Button, 
  IconButton, 
  Menu, 
  MenuItem,
  Toolbar,
  useTheme,
  useMediaQuery,
  styled,
} from '@mui/material';
import { 
  List as ListIcon,
  SignOut
} from 'phosphor-react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../auth';
import sumaLogo from '../../assets/logo.svg';


// Styled components remain the same as before
const StyledAppBar = styled(AppBar, {
  shouldForwardProp: (prop) => !['scrollactive', 'elevation'].includes(prop),
})(({ theme, scrollactive }) => ({
  height: { xs: '72px', md: '80px' },
  backgroundColor: scrollactive === 'true' ? 'rgba(255, 255, 255, 0.8)' : 'transparent',
  backdropFilter: scrollactive === 'true' ? 'blur(3px)' : 'none',
  boxShadow: scrollactive === 'true' ? '0px 1px 3px rgba(16, 24, 40, 0.1)' : 'none',
  borderBottom: scrollactive === 'true' ? '1px solid #EAECF0' : 'none',
  transition: 'all 0.2s ease',
  position: 'fixed',
  top: 0,
  left: 0,
  right: 0,
  width: '100%',
  zIndex: 1100,
}));


  const StyledToolbar = styled(Toolbar)(({ theme }) => ({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: theme.spacing(0, 2),
    [theme.breakpoints.up('md')]: {
      maxWidth: '1440px',
      margin: '0 auto',
      width: '100%',
    },
  }));
  
  const LogoContainer = styled('div')({
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    '& img': {
      height: '98.41px',
      width: '98.41px',
    },
  });
  
  const NavLinks = styled(Box)(({ theme }) => ({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '32px', // Increased gap between nav items
    marginLeft: '64px', // Add space after logo
  }));
  
  const NavButton = styled(Button, {
    shouldForwardProp: (prop) => prop !== 'isScrolled',
  })(({ theme, isScrolled }) => ({
    color: '#344054',
    textTransform: 'none',
    fontSize: '16px',
    fontWeight: 500,
    padding: '8px 12px',
    '&:hover': {
      backgroundColor: 'transparent',
      color: '#009A6B',
    },
  }));
  const AuthButtons = styled(Box)(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    gap: '12px',
  }));
  
  const LoginButton = styled(Button)(({ theme }) => ({
    color: '#344054',
    textTransform: 'none',
    fontSize: '16px',
    fontWeight: 500,
    padding: '10px 16px',
    '&:hover': {
      backgroundColor: 'transparent',
      color: '#009A6B',
    },
  }));
  
  const SignUpButton = styled(Button)(({ theme }) => ({
    backgroundColor: '#009A6B',
    color: 'white',
    textTransform: 'none',
    fontSize: '16px',
    fontWeight: 500,
    padding: '10px 16px',
    borderRadius: '8px',
    '&:hover': {
      backgroundColor: '#008559',
    },
    boxShadow: `
      0px 1px 2px rgba(16, 24, 40, 0.05),
      0px 0px 0px 1px rgba(16, 24, 40, 0.1)
    `,
  }));

  const LogoutButton = styled(Button)(({ theme }) => ({
    color: '#344054',
    textTransform: 'none',
    fontSize: '16px',
    fontWeight: 500,
    padding: '10px 16px',
    display: 'flex',
    alignItems: 'center',
    gap: '8px',
    '&:hover': {
      backgroundColor: 'transparent',
      color: '#D92D20', // Red color on hover
    },
    '& .logout-icon': {
      color: '#667085',
    },
    '&:hover .logout-icon': {
      color: '#D92D20',
    },
  }));

  const MobileMenuButton = styled(IconButton)(({ theme }) => ({
    marginLeft: 'auto',
    color: '#344054',
    display: 'none',
    [theme.breakpoints.down('md')]: {
      display: 'flex',
    },
  }));
  
  
  const Navbar = ({ lightBackground = false, isScrolled }) => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));
    const navigate = useNavigate();
    const { user, logout } = useAuth();
    const [productsAnchorEl, setProductsAnchorEl] = useState(null);
    const [mobileMenuAnchorEl, setMobileMenuAnchorEl] = useState(null);
    const [scrolled, setScrolled] = useState(false);

  
    // Debug log
    console.log("isScrolled state:", isScrolled);

    // const handleProductsMenuOpen = (event) => {
    //     setProductsAnchorEl(event.currentTarget);
    // };

    const handleProductsMenuClose = () => {
        setProductsAnchorEl(null);
    };

    const handleMobileMenuOpen = (event) => {
        setMobileMenuAnchorEl(event.currentTarget);
    };

    const handleMobileMenuClose = () => {
        setMobileMenuAnchorEl(null);
    };

    const handleLogout = async () => {
        try {
        await logout();
        navigate('/');
        handleMobileMenuClose();
        } catch (error) {
        console.error('Logout failed:', error);
        }
    };

    const handleContactClick = () => {
      // If we're not on the homepage, navigate first
      if (window.location.pathname !== '/') {
        navigate('/');
        // We need to wait for navigation to complete before scrolling
        setTimeout(() => {
          const contactSection = document.getElementById('contact-section');
          if (contactSection) {
            contactSection.scrollIntoView({ behavior: 'smooth' });
          }
        }, 100); // Small delay to ensure navigation completes
      } else {
        // If we're already on homepage, just scroll
        const contactSection = document.getElementById('contact-section');
        if (contactSection) {
          contactSection.scrollIntoView({ behavior: 'smooth' });
        }
      }
    };

    const handleHomeClick = () => {
      if (window.location.pathname === '/') {
        // If we're already on homepage, scroll to hero section
        const heroSection = document.getElementById('hero-section');
        if (heroSection) {
          heroSection.scrollIntoView({ behavior: 'smooth' });
        }
      } else {
        // If we're on another page, navigate home then scroll to hero
        navigate('/');
        setTimeout(() => {
          const heroSection = document.getElementById('hero-section');
          if (heroSection) {
            heroSection.scrollIntoView({ behavior: 'smooth' });
          }
        }, 100);
      }
    };
    
    


    return (
      // <ElevationScroll lightBackground={lightBackground}>
        <StyledAppBar elevation={0} scrollactive={(lightBackground || isScrolled)?.toString()}>

          <StyledToolbar>
            {/* Logo */}
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <LogoContainer onClick={handleHomeClick}>
            <img src={sumaLogo} alt="Suma Logo" />
              </LogoContainer>
    
              {/* Desktop Navigation */}
              {!isMobile && (
                <NavLinks>
                  <NavButton 
                      onClick={handleHomeClick}
                      isScrolled={scrolled}
                    >
                      Home
                    </NavButton>
                  {/* <NavButton 
                    endIcon={<CaretDown weight="bold" size={16} />}
                    onClick={handleProductsMenuOpen}
                    isScrolled={scrolled}
                  >
                    Products
                  </NavButton> */}
                  <NavButton 
                    onClick={() => navigate('/pricing')}
                    isScrolled={scrolled}
                  >
                    Pricing
                  </NavButton>
                  <NavButton 
                    onClick={handleContactClick}
                    isScrolled={scrolled}
                  >
                    Contact us
                  </NavButton>
                </NavLinks>
              )}
            </Box>
    
            {/* Auth Buttons */}
            {/* Auth Buttons */}
          {!isMobile && (
            <AuthButtons>
              {user ? (
                <>
                  <SignUpButton
                    onClick={() => navigate('/home')}
                  >
                    Dashboard
                  </SignUpButton>
                  <LogoutButton
                    onClick={handleLogout}
                    startIcon={<SignOut size={20} weight="bold" className="logout-icon" />}
                  >
                    Logout
                  </LogoutButton>
                </>
              ) : (
                <>
                  <LoginButton
                    onClick={() => navigate('/login')}
                    scrolled={scrolled}
                  >
                    Log in
                  </LoginButton>
                  <SignUpButton
                    onClick={() => navigate('/signup')}
                  >
                    Sign up
                  </SignUpButton>
                </>
              )}
            </AuthButtons>
          )}

          {/* Mobile Menu Button */}
          <MobileMenuButton
            edge="end"
            aria-label="menu"
            onClick={handleMobileMenuOpen}
          >
            <ListIcon size={24} weight="bold" />
          </MobileMenuButton>

          {/* Mobile Menu */}
          <Menu
            anchorEl={mobileMenuAnchorEl}
            open={Boolean(mobileMenuAnchorEl)}
            onClose={handleMobileMenuClose}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            PaperProps={{
              sx: {
                mt: 2,
                minWidth: '200px',
              },
            }}
          >
            <div> {/* Added this wrapper */}
            <MenuItem onClick={() => { 
                handleHomeClick(); 
                handleMobileMenuClose(); 
              }}>
                Home
              </MenuItem>
              {/* <MenuItem onClick={() => { navigate('/products'); handleMobileMenuClose(); }}>
                Products
              </MenuItem> */}
              <MenuItem onClick={() => { navigate('/pricing'); handleMobileMenuClose(); }}>
                Pricing
              </MenuItem>
              <MenuItem onClick={handleContactClick}>
                Contact us
              </MenuItem>
              {user ? (
                <>
                  <MenuItem onClick={() => { navigate('/home'); handleMobileMenuClose(); }}>
                    Dashboard
                  </MenuItem>
                  <MenuItem 
                    onClick={handleLogout}
                    sx={{ 
                      color: '#344054',
                      '&:hover': { color: '#D92D20' },
                      gap: 1,
                    }}
                  >
                    <SignOut size={20} weight="bold" />
                    Logout
                  </MenuItem>
                </>
              ) : (
                <>
                  <MenuItem onClick={() => { navigate('/login'); handleMobileMenuClose(); }}>
                    Log in
                  </MenuItem>
                  <MenuItem 
                    onClick={() => { navigate('/signup'); handleMobileMenuClose(); }}
                    sx={{
                      color: '#009A6B',
                      fontWeight: 500,
                    }}
                  >
                    Sign up
                  </MenuItem>
                </>
              )}
            </div>
          </Menu>


    
            {/* Products Dropdown Menu */}
            <Menu
              anchorEl={productsAnchorEl}
              open={Boolean(productsAnchorEl)}
              onClose={handleProductsMenuClose}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
            >
              <div> {/* Added this wrapper */}
                <MenuItem onClick={handleProductsMenuClose}>Product 1</MenuItem>
                <MenuItem onClick={handleProductsMenuClose}>Product 2</MenuItem>
                <MenuItem onClick={handleProductsMenuClose}>Product 3</MenuItem>
              </div>
            </Menu>
          </StyledToolbar>
        </StyledAppBar>
      // </ElevationScroll>
    );
  };
  
  export default Navbar;
