import React from 'react';
import InvoicesPage from '../../components/InvoicesPage/InvoicesPage';
import { useLocation } from 'react-router-dom';

const ExpenseInvoicesPage = () => {
  const location = useLocation();
  const isNewInvoice = location.pathname.endsWith('/new');
  
  console.log('ExpenseInvoicesPage - isNewInvoice:', isNewInvoice);
  console.log('ExpenseInvoicesPage - Current path:', location.pathname);

  return <InvoicesPage invoiceType="expense" isNewInvoice={isNewInvoice} />;
};

export default ExpenseInvoicesPage;

