import { auth, googleProvider, db } from "./firebase";
import { signInWithPopup , onAuthStateChanged} from "firebase/auth";
import { doc, getDoc, setDoc, serverTimestamp } from "firebase/firestore";
import { getAuth } from 'firebase/auth';


export const handleGoogleSignIn = async (userType = null) => {
  try {
    const result = await signInWithPopup(auth, googleProvider);
    const user = result.user;
    console.log("Google sign-in result:", result);

    const userRef = doc(db, "users", user.uid);
    const userDoc = await getDoc(userRef);

    let isNewUser = false;
    let hasOrganization = false;

    if (!userDoc.exists()) {
      console.log("User document doesn't exist - new user");
      isNewUser = true;
      // Create minimal user data
      const userData = {
        createdAt: serverTimestamp(),
        updatedAt: serverTimestamp(),
        email: user.email,
        name: user.displayName || '',
        role: 'Owner'
      };
      
      await setDoc(userRef, userData);
    } else {
      const userData = userDoc.data();
      // Check if user has type set - if not, treat as new user
      if (!userData.type && !userData.userType) {
        console.log("User exists but no type set - treating as new user");
        isNewUser = true;
      }
      hasOrganization = !!userData.organizationId;
    }

    console.log("Returning:", { isNewUser, hasOrganization });
    return { user, hasOrganization, isNewUser };
  } catch (error) {
    console.error("Error with Google sign-in:", error);
    throw error;
  }
};


// export const handleGoogleSignIn = async (userType) => {
//   try {
//     const result = await signInWithPopup(auth, googleProvider);
//     const user = result.user;

//     console.log("Signed in user:", user);

//     // Check if user document exists in Firestore
//     const userRef = doc(db, "users", user.uid);
//     const userDoc = await getDoc(userRef);

//     let isNewUser = false;
//     let hasOrganization = false;
//     let userTypeFromDB = userType;

//     if (!userDoc.exists()) {
//       console.log("User document does not exist, creating new document");
//       const userData = {
//         createdAt: serverTimestamp(),
//         updatedAt: serverTimestamp(),
//         email: user.email,
//         name: user.displayName || '',
//         type: userType === 'accountant' ? 'Accountant' : 'User',
//         role: 'Owner',
//         userType: userType,
//       };
//       await setDoc(userRef, userData);
//       isNewUser = true;

//       // Send welcome email for new users
//       await sendWelcomeEmail({
//         ...userData,
//         uid: user.uid
//       });
//     } else {
//       const userData = userDoc.data();
//       hasOrganization = !!userData.organizationId;
//       userTypeFromDB = userData.type;
//     }

//     console.log("User sign-in result:", { isNewUser, hasOrganization, userType: userTypeFromDB });
//     return { user, hasOrganization, isNewUser, userType: userTypeFromDB };
//   } catch (error) {
//     console.error("Error with Google sign-in:", error);
//     throw error;
//   }
// };


export const listenAuthState = (setUser, setOrganization) => {
  onAuthStateChanged(auth, async (user) => {
    if (user) {
      const userRef = doc(db, "users", user.uid);
      const userDoc = await getDoc(userRef);
      if (userDoc.exists()) {
        const userData = userDoc.data();
        setUser({ ...user, ...userData });

        if (userData.organizationId) {
          const orgDoc = await getDoc(doc(db, "organizations", userData.organizationId));
          if (orgDoc.exists()) {
            const orgData = orgDoc.data();
            const userRole = orgData.members[user.uid].role;
            setOrganization({ 
              id: orgDoc.id, 
              ...orgData, 
              userRole // Include the user's role in the organization
            });
          }
        } else {
          setOrganization(null);
        }
      } else {
        setUser(user);
        setOrganization(null);
      }
    } else {
      setUser(null);
      setOrganization(null);
    }
  });
};


export const getIdToken = async () => {
  const auth = getAuth();
  const firebaseUser = auth.currentUser;
  if (firebaseUser) {
    return await firebaseUser.getIdToken();
  } else {
    throw new Error('No authenticated user found');
  }
};
