import React from "react";
import { createRoot } from "react-dom/client"; // Import createRoot from react-dom/client
import "./index.css";
import App from "./App";
import { AuthProvider } from "./auth";

const container = document.getElementById("root");
const root = createRoot(container); // Create root

root.render(
  <AuthProvider>
    <App />
  </AuthProvider>
);

