import React from 'react';
import { Box, Container, Typography, TextField, Button, styled } from '@mui/material';
import sumaLogo from '../../assets/logo.svg';

import xIcon from '../../assets/icons/x-icon.svg';
import facebookIcon from '../../assets/icons/facebook-icon.svg';
import linkedinIcon from '../../assets/icons/linkedin-icon.svg';

const FooterWrapper = styled(Box)({
  background: '#FFFFFF',
});

const NewsletterSection = styled(Box)({
  background: '#F9FAFB',
  padding: '48px 0',
  '@media (max-width: 768px)': {
    padding: '32px 0',
  },
});

const NewsletterContainer = styled(Box)({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  gap: '24px',
  '@media (max-width: 768px)': {
    flexDirection: 'column',
    alignItems: 'stretch',
    gap: '20px',
  },
});

const NewsletterContent = styled(Box)({
  flex: 1,
});

const NewsletterTitle = styled(Typography)({
  color: '#1C1C1C',
  fontSize: '24px',
  fontWeight: 600,
  lineHeight: '32px',
  marginBottom: '4px',
  '@media (max-width: 768px)': {
    fontSize: '20px',
    lineHeight: '28px',
  },
});

const NewsletterSubtitle = styled(Typography)({
  color: 'rgba(28, 28, 28, 0.60)',
  fontSize: '16px',
  lineHeight: '24px',
});

const NewsletterForm = styled(Box)({
  display: 'flex',
  gap: '12px',
  maxWidth: '600px',
  '@media (max-width: 768px)': {
    width: '100%',
    flexDirection: 'column',
  },
  '@media (min-width: 480px) and (max-width: 768px)': {
    flexDirection: 'row',
  },
});

const EmailInput = styled(TextField)({
  flex: 1,
  minWidth: '320px',
  '& .MuiOutlinedInput-root': {
    backgroundColor: '#FFFFFF',
    '& fieldset': {
      borderColor: '#E4E7EC',
    },
    '&:hover fieldset': {
      borderColor: '#009A6B',
    },
  },
  '@media (max-width: 768px)': {
    minWidth: 'unset',
    '& .MuiOutlinedInput-root': {
      height: '44px',
    },
  },
});

const SubscribeButton = styled(Button)({
  padding: '10px 18px',
  minWidth: '120px',
  background: '#009A6B',
  color: '#FFFFFF',
  borderRadius: '8px',
  textTransform: 'none',
  '&:hover': {
    background: '#008559',
  },
  '@media (max-width: 480px)': {
    width: '100%',
    padding: '12px 18px',
  },
});

const MainFooter = styled(Box)({
  padding: '64px 0 32px',
  '@media (max-width: 768px)': {
    padding: '40px 0 24px',
  },
});

const FooterContent = styled(Box)({
  display: 'flex',
  justifyContent: 'space-between',
  gap: '64px',
  marginBottom: '48px',
  '@media (max-width: 968px)': {
    flexDirection: 'column',
    gap: '40px',
  },
});

const FooterBrand = styled(Box)({
  maxWidth: '280px',
  '@media (max-width: 968px)': {
    maxWidth: '100%',
  },
});

const FooterDescription = styled(Typography)({
  color: 'rgba(28, 28, 28, 0.60)',
  fontSize: '14px',
  lineHeight: '20px',
  marginTop: '24px',
});

const FooterNavigation = styled(Box)({
  display: 'flex',
  gap: '48px',
  flexWrap: 'wrap',
  '@media (max-width: 768px)': {
    gap: '32px 48px',
  },
  '@media (max-width: 480px)': {
    gap: '32px 24px',
  },
});

const FooterColumn = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  gap: '16px',
  minWidth: '160px',
  '@media (max-width: 480px)': {
    minWidth: 'calc(50% - 12px)', // Account for gap
    gap: '12px',
  },
});

const FooterColumnTitle = styled(Typography)({
  color: '#1C1C1C',
  fontSize: '14px',
  fontWeight: 600,
  lineHeight: '20px',
});

const FooterLink = styled(Typography)({
  color: 'rgba(28, 28, 28, 0.60)',
  fontSize: '14px',
  lineHeight: '20px',
  cursor: 'pointer',
  '&:hover': {
    color: '#009A6B',
  },
  '@media (max-width: 768px)': {
    paddingBlock: '4px', // Increase touch target
  },
});

const FooterBottom = styled(Box)({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  paddingTop: '32px',
  borderTop: '1px solid #E4E7EC',
  '@media (max-width: 768px)': {
    flexDirection: 'column-reverse',
    gap: '24px',
    textAlign: 'center',
  },
});

const Copyright = styled(Typography)({
  color: 'rgba(28, 28, 28, 0.60)',
  fontSize: '14px',
  lineHeight: '20px',
});

const SocialLinks = styled(Box)({
  display: 'flex',
  gap: '24px',
  '@media (max-width: 768px)': {
    justifyContent: 'center',
  },
});

const SocialIcon = styled(Box)({
  color: 'rgba(28, 28, 28, 0.60)',
  cursor: 'pointer',
  padding: '8px',
  margin: '-8px',
  '&:hover': {
    color: '#009A6B',
  },
  '& img': {
    width: '20px',
    height: '20px',
  },
});

// Component remains the same as in the original
const Footer = () => {
  return (
    <FooterWrapper>
      <NewsletterSection>
        <Container maxWidth="lg">
          <NewsletterContainer>
            <NewsletterContent>
              <NewsletterTitle>Join our newsletter</NewsletterTitle>
              <NewsletterSubtitle>Banking technology that has your back.</NewsletterSubtitle>
            </NewsletterContent>
            <NewsletterForm>
              <EmailInput placeholder="Enter your email" />
              <SubscribeButton>Subscribe</SubscribeButton>
            </NewsletterForm>
          </NewsletterContainer>
        </Container>
      </NewsletterSection>

      <MainFooter>
        <Container maxWidth="lg">
          <FooterContent>
            <FooterBrand>
              <img src={sumaLogo} alt="Suma Logo" />
              <FooterDescription>
                A new way to manage your business. Manage everything in one place and save time
              </FooterDescription>
            </FooterBrand>

            <FooterNavigation>
              <FooterColumn>
                <FooterColumnTitle>Product</FooterColumnTitle>
                <FooterLink>Features</FooterLink>
                <FooterLink>Pricing</FooterLink>
              </FooterColumn>

              <FooterColumn>
                <FooterColumnTitle>Company</FooterColumnTitle>
                <FooterLink>About us</FooterLink>
                <FooterLink>Contact</FooterLink>
              </FooterColumn>

              <FooterColumn>
                <FooterColumnTitle>Resources</FooterColumnTitle>
                <FooterLink>Help centre</FooterLink>
                <FooterLink>Support</FooterLink>
              </FooterColumn>

              <FooterColumn>
                <FooterColumnTitle>Legal</FooterColumnTitle>
                <FooterLink>Terms</FooterLink>
                <FooterLink>Privacy</FooterLink>
                <FooterLink>Cookies</FooterLink>
              </FooterColumn>
            </FooterNavigation>
          </FooterContent>

          <FooterBottom>
            <Copyright>© 2024 Suma. All rights reserved.</Copyright>
            <SocialLinks>
              <SocialIcon>
                <img src={xIcon} alt="X" />
              </SocialIcon>
              <SocialIcon>
                <img src={linkedinIcon} alt="LinkedIn" />
              </SocialIcon>
              <SocialIcon>
                <img src={facebookIcon} alt="Facebook" />
              </SocialIcon>
            </SocialLinks>
          </FooterBottom>
        </Container>
      </MainFooter>
    </FooterWrapper>
  );
};

export default Footer;
