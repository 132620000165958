import React, { useState, useEffect, useCallback, useRef } from 'react';
import PropTypes from 'prop-types';
import { useAuth } from "../../auth";
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import { db } from "../../firebase";

import HeaderComponent from '../HeaderComponent/HeaderComponent';
import TableComponent from '../TableComponent/TableComponent';
import ViewEditCategory from '../ViewEditCategory/ViewEditCategory';

import { createAnalyticsTrigger, pollAnalyticsTriggerStatus } from '../../utils/analyticsUtils';

import './CategoryPage.css';

const CategoryPage = ({ categoryType, isNewCategory }) => {
    const [categories, setCategories] = useState([]);
    const [loading, setLoading] = useState(true);
    const [selectedCategory, setSelectedCategory] = useState(null);
    const [currentCategoryIndex, setCurrentCategoryIndex] = useState(-1);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [perPage, setPerPage] = useState(20);
    const [dataFetched, setDataFetched] = useState(false);
    const { organization, user } = useAuth();
    const BACKEND_API_URL = process.env.REACT_APP_BACKEND_API_URL;
    const { getIdToken } = useAuth();
    const tableRef = useRef(null);
    const sortedCategoriesRef = useRef([]);
    const navigate = useNavigate();
    const location = useLocation();
    const { categoryId } = useParams();
    
    useEffect(() => {
        if (location.pathname.endsWith('/new')) {
            setSelectedCategory({
                isNew: true,
                name: '',
                description: '',
                // Add other fields as necessary
            });
        } else if (categoryId) {
            const category = categories.find(c => c.id === categoryId);
            if (category) {
                setSelectedCategory(category);
            } else {
                navigate(`/${categoryType}-categories`, { replace: true });
            }
        } else {
            setSelectedCategory(null);
        }
    }, [location.pathname, categoryId, categories, navigate, categoryType]);

    const handleCategoryUpdated = useCallback((updatedCategory) => {
        setCategories((prevCategories) =>
            prevCategories.map((category) =>
                category.id === updatedCategory.id ? updatedCategory : category
            )
        );
    }, []);

    const updateCategory = useCallback(async (organizationId, categoryId, categoryType, updateData, triggerId) => {
        try {
            const token = await getIdToken();
            const response = await fetch(`${BACKEND_API_URL}/api/organizations/${organizationId}/${categoryType}-categories/${categoryId}`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
                body: JSON.stringify({ ...updateData, triggerId }),
            });
            if (!response.ok) {
                throw new Error('Failed to update category');
            }
            return await response.json();
        } catch (error) {
            console.error('Error updating category:', error);
            throw error;
        }
    }, [BACKEND_API_URL, getIdToken]);
    
    const fetchCategories = useCallback(async (page = currentPage, itemsPerPage = perPage) => {
        if (!organization || !user) return;
        setLoading(true);
    
        try {
            const token = await getIdToken();
            const response = await fetch(`${BACKEND_API_URL}/api/organizations/${organization.id}/categories?type=${categoryType}&page=${page}&per_page=${itemsPerPage}`, {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            });
            if (!response.ok) throw new Error(`Failed to fetch ${categoryType} categories`);
    
            const data = await response.json();
            console.log("Data:", data);
            setCategories(data.categories);
            setTotalPages(data.total_pages);
            setCurrentPage(data.page);
            console.log(`Fetched ${categoryType} categories:`, data.categories);
    
        } catch (error) {
            console.error(`Error fetching ${categoryType} categories:`, error);
        } finally {
            setLoading(false);
        }
    }, [BACKEND_API_URL, organization, categoryType, currentPage, perPage, getIdToken]);


    const handleDelete = async (selectedCategories) => {
        try {
            const triggerId = await createAnalyticsTrigger(db, organization, 'category', selectedCategories.length, 'delete');
            
            const token = await getIdToken();
            const response = await fetch(`${BACKEND_API_URL}/delete-categories`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
                body: JSON.stringify({
                    organizationId: organization.id,
                    categoryType: categoryType,
                    categoryIds: selectedCategories,
                    triggerId: triggerId
                }),
            });
    
            if (!response.ok) {
                throw new Error('Failed to delete categories');
            }
            const data = await response.json();
            console.log("Delete response:", data);
    
            // Remove deleted categories from the local state
            setCategories(prevCategories => prevCategories.filter(category => !selectedCategories.includes(category.id)));
    
            // Start polling for analytics update completion
            await pollAnalyticsTriggerStatus(triggerId, getIdToken, () => {}, () => {});
    
            // Fetch updated categories and analytics data
            await Promise.all([fetchCategories()]);
    
            console.log(`${selectedCategories.length} ${categoryType} category(ies) deleted successfully`);
        } catch (error) {
            console.error(`Error deleting ${categoryType} categories:`, error);
            throw error;
        }
    };

    const handleCSVDownload = async (downloadOptions) => {
        console.log("Downloading CSV with options:", downloadOptions);
        // Implement CSV download logic here
    };

    const columns = [
        { id: 'name', label: 'Category Name', defaultVisible: true, sortable: true, type: 'string' },
        { id: 'createdAt', label: 'Created At', defaultVisible: true, sortable: true, type: 'date' },
        { id: 'updatedAt', label: 'Updated At', defaultVisible: true, sortable: true, type: 'date' },
        { id: 'totalInvoices', label: 'Total Invoices', defaultVisible: true, sortable: true, type: 'number' },
        { 
            id: 'totalAmount', 
            label: categoryType === 'supplier' ? 'Total Spent' : 'Total Revenue', 
            defaultVisible: true, 
            sortable: true, 
            type: 'number',
            format: 'currency' // Add this line
        },
    ];

    const handleAddCategory = () => {
        console.log(`Adding new ${categoryType} category`);
        navigate(`/${categoryType}-categories/new`);
    };

    const handleSortedDataChange = useCallback((newSortedData) => {
        console.log("Updating sorted categories:", newSortedData);
        sortedCategoriesRef.current = newSortedData;
    }, []);

    const handleRowClick = useCallback((category) => {
        const index = sortedCategoriesRef.current.findIndex(c => c.id === category.id);
        setSelectedCategory(category);
        setCurrentCategoryIndex(index);
    }, []);

    const handleCloseModal = useCallback(() => {
        setSelectedCategory(null);
        navigate(`/${categoryType}-categories`, { replace: true });
    }, [navigate, categoryType]);

    const handlePrevNext = useCallback((direction) => {
        if (isNewCategory || sortedCategoriesRef.current.length === 0) return;
    
        let newIndex;
        if (direction === 'prev') {
            newIndex = (currentCategoryIndex - 1 + sortedCategoriesRef.current.length) % sortedCategoriesRef.current.length;
        } else {
            newIndex = (currentCategoryIndex + 1) % sortedCategoriesRef.current.length;
        }
        
        const newCategory = sortedCategoriesRef.current[newIndex]; 
        setSelectedCategory(newCategory);
        setCurrentCategoryIndex(newIndex);

        if (tableRef.current) {
            tableRef.current.scrollToRow(newCategory.id);
        }
    }, [currentCategoryIndex]);

    useEffect(() => {
        const handleKeyDown = (event) => {
            if (selectedCategory && !isNewCategory) {
                if (event.key === "ArrowLeft") {
                    event.preventDefault();
                    handlePrevNext('prev');
                } else if (event.key === "ArrowRight") {
                    event.preventDefault();
                    handlePrevNext('next');
                }
            }
        };
    
        window.addEventListener("keydown", handleKeyDown);
        return () => {
            window.removeEventListener("keydown", handleKeyDown);
        };
    }, [selectedCategory, handlePrevNext, isNewCategory]);

    const handlePageChange = useCallback((newPage) => {
        setCurrentPage(newPage);
        fetchCategories(newPage, perPage);
    }, [fetchCategories, perPage]);

    const handlePerPageChange = useCallback((newPerPage) => {
        setPerPage(newPerPage);
        fetchCategories(1, newPerPage);
    }, [fetchCategories]);

    useEffect(() => {
        if (organization && user && !dataFetched) {
            setLoading(true);
            Promise.all([fetchCategories()])
                .then(() => {
                    setLoading(false);
                    setDataFetched(true);
                })
                .catch((error) => {
                    console.error("Error loading data:", error);
                    setLoading(false);
                });
        }
    }, [organization, user, categoryType, dataFetched, fetchCategories]);



    return (
        <div className="category-page">
            <HeaderComponent 
                pageType="category"
                entityType={categoryType}
                onAddClick={handleAddCategory}
            />
            {loading ? (
                <div className="loading-container">
                    <div className="spinner"></div>
                </div>
            ) : (
                <>
                    <TableComponent 
                        data={categories}
                        setData={setCategories}
                        db={db}
                        columns={columns}
                        stickyColumnId="name"
                        onCSVDownload={handleCSVDownload}
                        onDelete={handleDelete}
                        categoryField="name"
                        entityField="name"
                        filters={null}
                        showEntityFilters={false}
                        onRowClick={handleRowClick}
                        selectedItem={selectedCategory} // Change this line
                        isInvoicePage={false}
                        ref={tableRef}
                        onSortedDataChange={handleSortedDataChange}
                        currentPage={currentPage}
                        totalPages={totalPages}
                        perPage={perPage}
                        onPageChange={handlePageChange}
                        onPerPageChange={handlePerPageChange}
                        isEntitiesPage={true}
                        showDateFilter={false}
                        pageType="category"
                        invoiceType={categoryType === 'supplier' ? 'expense' : 'revenue'}
                        createAnalyticsTrigger={createAnalyticsTrigger}
                        pollAnalyticsTriggerStatus={pollAnalyticsTriggerStatus}
                        organization={organization}
                    />
                    {selectedCategory && (
                        <ViewEditCategory 
                            category={selectedCategory}
                            onClose={handleCloseModal}
                            onPrevious={() => handlePrevNext('prev')}
                            onNext={() => handlePrevNext('next')}
                            categoryType={categoryType}
                            hasPrevious={currentCategoryIndex > 0}
                            hasNext={currentCategoryIndex < sortedCategoriesRef.current.length - 1}
                            onCategoryUpdated={handleCategoryUpdated}
                            updateCategory={updateCategory}
                            organization={organization}
                            db={db}
                            isNewCategory={isNewCategory}
                        />
                    )}
                </>
            )}
        </div>
    );
};

CategoryPage.propTypes = {
    categoryType: PropTypes.oneOf(['supplier', 'customer']).isRequired,
    isNewCategory: PropTypes.bool,

};

export default CategoryPage;