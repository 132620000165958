// src/components/RevenueInvoicesPage/RevenueInvoicesPage.js
import React from 'react';
import InvoicesPage from '../../components/InvoicesPage/InvoicesPage';
import { useLocation } from 'react-router-dom';

const RevenueInvoicesPage = () => {
  const location = useLocation();
  const isNewInvoice = location.pathname.endsWith('/new');

  return <InvoicesPage invoiceType="revenue" isNewInvoice={isNewInvoice} />;
};

export default RevenueInvoicesPage;
