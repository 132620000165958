export const industryOptions = [
    { value: 'agriculture', label: 'Agriculture, Forestry & Fishing' },
    { value: 'mining', label: 'Mining & Quarrying' },
    { value: 'manufacturing', label: 'Manufacturing' },
    { value: 'energy', label: 'Electricity, Gas, Steam & Air Conditioning Supply' },
    { value: 'water', label: 'Water Supply, Sewerage & Waste Management' },
    { value: 'construction', label: 'Construction' },
    { value: 'retail', label: 'Wholesale & Retail Trade' },
    { value: 'transportation', label: 'Transportation & Storage' },
    { value: 'hospitality', label: 'Accommodation & Food Service Activities' },
    { value: 'information', label: 'Information & Communication' },
    { value: 'finance', label: 'Financial & Insurance Activities' },
    { value: 'realestate', label: 'Real Estate Activities' },
    { value: 'professional', label: 'Professional, Scientific & Technical Activities' },
    { value: 'administrative', label: 'Administrative & Support Service Activities' },
    { value: 'publicadmin', label: 'Public Administration & Defence' },
    { value: 'education', label: 'Education' },
    { value: 'health', label: 'Human Health & Social Work Activities' },
    { value: 'arts', label: 'Arts, Entertainment & Recreation' },
    { value: 'other', label: 'Other Service Activities' },
    { value: 'households', label: 'Activities of Households as Employers' },
    { value: 'extraterritorial', label: 'Activities of Extraterritorial Organizations & Bodies' },
    { value: 'technology', label: 'Information Technology & Services' },
    { value: 'biotechnology', label: 'Biotechnology' },
    { value: 'telecom', label: 'Telecommunications' },
    { value: 'ecommerce', label: 'E-commerce & Online Retail' },
    { value: 'media', label: 'Media & Entertainment' },
    { value: 'pharmaceutical', label: 'Pharmaceuticals' },
    { value: 'automotive', label: 'Automotive' },
    { value: 'aerospace', label: 'Aerospace & Defense' },
    { value: 'nonprofit', label: 'Non-Profit & Charitable Organizations' },
];

export const companySizeOptions = [
    { value: '1-10', label: '1 - 10 employees' },
    { value: '11-50', label: '11 - 50 employees' },
    { value: '51-200', label: '51 - 200 employees' },
    { value: '201-500', label: '201 - 500 employees' },
    { value: '500+', label: '500+ employees' },
];

