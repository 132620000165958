import React from 'react';
import { Link } from 'react-router-dom';

import './Header.css';
import logoIcon from '../../assets/logo.svg'; // Adjust the path as needed
import searchIcon from '../../assets/icons/Search.svg'; // Adjust the path as needed
import notificationIcon from '../../assets/icons/Bell.svg'; // Adjust the path as needed
import profileIcon from '../../assets/icons/Question.svg'; // Adjust the path as needed
import burgerIcon from '../../assets/icons/List.svg'; // Add this import
import exitIcon from '../../assets/icons/SignOut.svg'; // Make sure to add this icon to your assets


const Header = ({ toggleSidebar, user, isImpersonating, exitAccountantOrg, navigate, organization }) => {

  const handleExitOrganization = () => {
    exitAccountantOrg();
    navigate('/accountant-dashboard');
  };

  // console.log("Organization: ", organization)
  const companyName = organization?.clientName || 'Unknown Company';


  return (
    <div className="header">
      <div className="header__left">
        <Link to="/dashboard">
          <img src={logoIcon} alt="Logo" className="header__logo" />
        </Link>
      </div>
      <div className="header__middle">
        {isImpersonating && (
          <div className="header__managing-company">
            You are managing company: {companyName}
          </div>
        )}
      </div>
      <div className="header__right">
        <img src={notificationIcon} alt="Notifications" className="header__icon" />
        <img src={profileIcon} alt="Profile" className="header__icon" />
        <img 
          src={burgerIcon} 
          alt="Menu" 
          className="header__icon header__burger-icon" 
          onClick={toggleSidebar}
        />
        {user.type === 'Accountant' && isImpersonating && (
          <button onClick={handleExitOrganization} className="exit-org-button">
            <img src={exitIcon} alt="Exit" className="exit-icon" />
            Exit {companyName}
          </button>
        )}
      </div>
    </div>
  );
};

export default Header;