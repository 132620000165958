import React from 'react';
import CategoryPage from '../../components/CategoryPage/CategoryPage';
import { useLocation } from 'react-router-dom';

const CustomerCategoriesPage = () => {
  const location = useLocation();
  const isNewCategory = location.pathname.endsWith('/new');
  
  console.log('CustomerCategoriesPage - isNewCategory:', isNewCategory);
  console.log('CustomerCategoriesPage - Current path:', location.pathname);

  return <CategoryPage categoryType="customer" isNewCategory={isNewCategory} />;
};

export default CustomerCategoriesPage;
