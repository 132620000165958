import React from 'react';
import { Box, Container, Typography, Accordion, AccordionSummary, AccordionDetails, styled, Button } from '@mui/material';
import { CaretDown } from '@phosphor-icons/react';

const SectionContainer = styled(Box)(({ theme }) => ({
  padding: '96px 0',
  background: '#FFFFFF',
  [theme.breakpoints.down('md')]: {
    padding: '64px 0',
  },
  [theme.breakpoints.down('sm')]: {
    padding: '48px 0',
  }
}));

const StyledAccordion = styled(Accordion)(({ theme }) => ({
  border: 'none',
  boxShadow: 'none',
  '&:before': {
    display: 'none',
  },
  '& .MuiAccordionSummary-root': {
    borderBottom: '1px solid #E4E7EC',
    padding: '16px 0',
    [theme.breakpoints.down('sm')]: {
      padding: '12px 0',
    }
  },
  '& .MuiAccordionDetails-root': {
    padding: '16px 0',
    [theme.breakpoints.down('sm')]: {
      padding: '12px 0',
    }
  }
}));

const FAQTitle = styled(Typography)(({ theme }) => ({
  fontSize: '48px',
  fontWeight: 600,
  lineHeight: '60px',
  letterSpacing: '-0.96px',
  marginBottom: '16px',
  [theme.breakpoints.down('md')]: {
    fontSize: '36px',
    lineHeight: '44px',
  },
  [theme.breakpoints.down('sm')]: {
    fontSize: '28px',
    lineHeight: '36px',
  }
}));

const FAQSection = () => {
    const faqs = [
      {
        question: 'Is there a free trial available?',
        answer: 'Yes, you can try us for free for 30 days. If you want, we\'ll provide you with a free, personalized 30-minute onboarding call to get you up and running as soon as possible.'
      },
      {
        question: 'Can I change my plan later?',
        answer: 'Yes, you can change your plan at any time. Upgrading or downgrading your plan is simple and takes effect on your next billing cycle. There are no penalties or fees for changing plans.'
      },
      {
        question: 'How secure is my financial data?',
        answer: 'We take security seriously. Your data is protected with bank-level 256-bit encryption, and we use multi-factor authentication. We\'re SOC 2 Type II certified and regularly undergo security audits. We never store sensitive card details on our servers.'
      },
      {
        question: 'Can I integrate with my existing accounting software?',
        answer: 'Yes, we integrate seamlessly with popular accounting software including QuickBooks, Xero, and Sage. Our API also allows for custom integrations with other business tools you might be using.'
      },
      {
        question: 'What kind of support do you offer?',
        answer: 'We provide 24/7 customer support via email, live chat, and phone. Premium plans include a dedicated account manager. Our average response time is under 2 minutes for live chat and under 2 hours for email inquiries.'
      },
      {
        question: 'Is there a minimum business size requirement?',
        answer: 'No, we cater to businesses of all sizes - from freelancers to large enterprises. Our flexible plans are designed to scale with your business needs, and you only pay for what you use.'
      },
      {
        question: 'How do international transactions work?',
        answer: 'We support international transactions in over 130 currencies with competitive exchange rates. Transfers typically clear within 1-2 business days, and you can track your transactions in real-time. We\'re transparent about our fees, with no hidden charges.'
      },
      {
        question: 'What happens to my data if I decide to cancel?',
        answer: 'You can export all your data at any time in standard formats (CSV, Excel, PDF). After cancellation, we retain your data for 30 days, during which you can still access it. After 30 days, all data is permanently deleted from our servers.'
      },
      {
        question: 'Do you offer multiple user accounts?',
        answer: 'Yes, you can add multiple users with different permission levels. This allows you to give team members, accountants, or contractors specific access while maintaining control over sensitive operations. Each user\'s actions are logged for accountability.'
      },
      {
        question: 'How do you handle regulatory compliance?',
        answer: 'We stay up-to-date with all relevant financial regulations and automatically implement required changes. We\'re fully compliant with GDPR, PSD2, and other major financial regulations. We provide regular compliance reports and necessary documentation for audits.'
      }
    ];


  return (
    <SectionContainer>
      <Container maxWidth="lg">
        <Box sx={{ 
          textAlign: 'center', 
          mb: { xs: 4, sm: 5, md: 6 }
        }}>
          <FAQTitle>
            Frequently asked questions
          </FAQTitle>
          <Typography 
            color="text.secondary"
            sx={{
              fontSize: { xs: '16px', sm: '18px' },
              lineHeight: { xs: '24px', sm: '28px' }
            }}
          >
            Everything you need to know about the product and billing.
          </Typography>
        </Box>

        <Box sx={{ 
          maxWidth: '768px', 
          margin: '0 auto',
          px: { xs: 2, sm: 3, md: 0 }
        }}>
          {faqs.map((faq, index) => (
            <StyledAccordion key={index}>
              <AccordionSummary 
                expandIcon={<CaretDown />}
                sx={{
                  '& .MuiAccordionSummary-content': {
                    margin: { xs: '12px 0', sm: '16px 0' }
                  }
                }}
              >
                <Typography 
                  fontWeight={500}
                  sx={{
                    fontSize: { xs: '16px', sm: '18px' },
                    lineHeight: { xs: '24px', sm: '28px' }
                  }}
                >
                  {faq.question}
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography 
                  color="text.secondary"
                  sx={{
                    fontSize: { xs: '14px', sm: '16px' },
                    lineHeight: { xs: '20px', sm: '24px' }
                  }}
                >
                  {faq.answer}
                </Typography>
              </AccordionDetails>
            </StyledAccordion>
          ))}
        </Box>

        <Box sx={{ 
          textAlign: 'center', 
          mt: { xs: 4, sm: 6, md: 8 }, 
          p: { xs: 3, sm: 4 }, 
          background: '#F9FAFB',
          borderRadius: '16px',
          mx: { xs: 2, sm: 3, md: 0 }
        }}>
          <Typography 
            variant="h4" 
            sx={{ 
              mb: 2,
              fontSize: { xs: '24px', sm: '28px', md: '32px' },
              lineHeight: { xs: '32px', sm: '36px', md: '40px' },
              fontWeight: 600
            }}
          >
            Still have questions?
          </Typography>
          <Typography 
            sx={{ 
              mb: 3,
              fontSize: { xs: '14px', sm: '16px' },
              lineHeight: { xs: '20px', sm: '24px' },
              color: 'text.secondary'
            }}
          >
            Can't find the answer you're looking for? Please chat to our friendly team.
          </Typography>
          <Button 
            variant="contained" 
            sx={{ 
              background: '#009A6B',
              '&:hover': {
                background: '#008559',
              },
              py: { xs: 1.5, sm: 2 },
              px: { xs: 4, sm: 5 },
              fontSize: { xs: '14px', sm: '16px' }
            }}
          >
            Get in touch
          </Button>
        </Box>
      </Container>
    </SectionContainer>
  );
};

export default FAQSection;
